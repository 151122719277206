import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Avatar,
  Chip,
  Grid2,
  FormControl,
  MenuItem, 
  InputLabel, 
  Select,
  IconButton,
  Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { formatDate } from '../../../utils/functions';
import { VisibilityTwoTone } from '@mui/icons-material';


const CreatorTasksSummaryTable = ({ tasks }) => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [expandedRows, setExpandedRows] = useState({});

  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleRowClick = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Filter the campaign data based on the selected status
  const filteredCampaigns = statusFilter === 'All' 
    ? tasks 
    : tasks.filter((campaign) => campaign.status === statusFilter);

  const handleEditClick = (row) => {
    navigate(`/campaigns/edit/${row._id}`);
  };

  const handleViewJob = (jobId) => {
    navigate(`/creators/tasks/detail/${jobId}`, { state: { tasks } });
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedtasks = React.useMemo(() => {
    if (!Array.isArray(tasks)) return [];
    if (!sortConfig.key) return tasks;

    const sorted = [...tasks].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [tasks, sortConfig]);

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />;
    }
    return null;
  };

  return (
    <Grid2 container width='100%' alignItems="center" justifyContent="space-between">
      <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSort('campaignName')} style={{ cursor: 'pointer' }}>
                <strong>Campaign Name</strong> {getSortIcon('campaignName')}
              </TableCell>
              <TableCell onClick={() => handleSort('taskName')} style={{ cursor: 'pointer' }}>
                <strong>Task Name</strong> {getSortIcon('taskName')}
              </TableCell>
              <TableCell onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                <strong>Status</strong> {getSortIcon('status')}
              </TableCell>
              <TableCell onClick={() => handleSort('assignedTo')} style={{ cursor: 'pointer' }}>
                <strong>Assigned To</strong> {getSortIcon('assignedTo')}
              </TableCell>
              <TableCell onClick={() => handleSort('updatedDate')} style={{ cursor: 'pointer' }}>
                <strong>Updated Date</strong> {getSortIcon('updatedDate')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedtasks.map((task) => (
              <TableRow key={task._id.$oid}>
                <TableCell>{task.campaignName}</TableCell>
                <TableCell>{task.taskName}</TableCell>
                <TableCell>{task.status}</TableCell>
                <TableCell>{task.assignedTo}</TableCell>
                <TableCell>{formatDate(task.updatedDate.$date)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid2>
  );
};

export default CreatorTasksSummaryTable;
