import React from 'react';
import { Box, Paper, 
    Typography,  
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Avatar,
    Button
     } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const AcceptedCreatorTable = ({ influencers, toggleViewMediaPlan }) => {




  return (
<Box>
<Paper sx={{ p:2, mb: 2 }}>
 <Typography variant="h5">Creators Accepted</Typography>
 <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
 <Button
                        size="small"
                        variant="outlined"
                        onClick={toggleViewMediaPlan}
                      >
                        View Media Plan
                      </Button>
                      </Box>
 <TableContainer component={Paper}>
 <Table>
     <TableHead>
       <TableRow>
       <TableCell><strong></strong></TableCell>
         <TableCell><strong>Name</strong></TableCell>
         <TableCell><strong>Followers</strong></TableCell>
         <TableCell><strong>eCPV</strong></TableCell>
         <TableCell><strong>eCPE</strong></TableCell>
         <TableCell><strong>Total Cost</strong></TableCell>
         <TableCell><strong>Status</strong></TableCell>
       </TableRow>
     </TableHead>
     <TableBody>
      {influencers
    .filter((influencer) => influencer.status === 'Accepted')
    .map((influencer) => (
         <TableRow key={influencer._id}>
            <TableCell><Avatar /></TableCell>
           <TableCell>{influencer.name}</TableCell>
           <TableCell>{influencer.followers}</TableCell>
           <TableCell>${influencer.eCPV}</TableCell>
           <TableCell>${influencer.eCPE}</TableCell>
           <TableCell>${influencer.totalCost}</TableCell>
           <TableCell>{influencer.status}</TableCell>
         </TableRow>
       ))}
     </TableBody>
   </Table>
</TableContainer>
 </Paper>
</Box>

)};

export default AcceptedCreatorTable;