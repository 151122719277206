import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Grid2,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { formatDate } from '../../../utils/functions';
import { VisibilityTwoTone } from '@mui/icons-material';


const JobSummaryTable = ({ jobs }) => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [expandedRows, setExpandedRows] = useState({});

  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleRowClick = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Filter the campaign data based on the selected status
  const filteredCampaigns = statusFilter === 'All' 
    ? jobs 
    : jobs.filter((campaign) => campaign.status === statusFilter);

  const handleEditClick = (row) => {
    navigate(`/campaigns/edit/${row._id}`);
  };

  const handleViewJob = (jobId) => {
    navigate(`/jobs/detail/${jobId}`, { state: { jobs } });
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedjobs = React.useMemo(() => {
    if (!Array.isArray(jobs)) return [];
    if (!sortConfig.key) return jobs;

    const sorted = [...jobs].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [jobs, sortConfig]);

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />;
    }
    return null;
  };

  return (
    <Grid2 container width='100%' alignItems="center" justifyContent="space-between">
      <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
             
              <TableCell onClick={() => handleSort('campaignName')} style={{ cursor: 'pointer' }}>
                <strong>Campaign Name</strong> {getSortIcon('campaignName')}
              </TableCell>
              <TableCell onClick={() => handleSort('campaignName')} style={{ cursor: 'pointer' }}>
                <strong>Amount</strong> {getSortIcon('campaignName')}
              </TableCell>
              <TableCell onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                <strong>Status</strong> {getSortIcon('status')}
              </TableCell>
              <TableCell>
                <strong>Placements</strong>
              </TableCell>
              <TableCell>
                <strong>Date Applied</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
              <TableCell>
                <strong></strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedjobs.map((job, index) => (
              <React.Fragment key={job._id}>
              <TableRow >
               
                <TableCell>{job.campaignName}</TableCell>
                <TableCell>${job.totalAgreedRate}</TableCell>
                <TableCell>{job.contractStatus}</TableCell>
                <TableCell>{job.placementsAppliedTo.length}</TableCell>
                <TableCell>{formatDate(job.applicationDate)}</TableCell>
                <TableCell>
                  <IconButton size="small" >
                    <VisibilityTwoTone onClick={() => handleViewJob(job._id)} style={{ cursor: 'pointer' }}/>
                  </IconButton>
                  <IconButton size="small" onClick={() => handleRowClick(index)} style={{ cursor: 'pointer' }}>
                    {expandedRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </TableCell>
              
              
              </TableRow>
              {expandedRows[index] && (
                <TableRow>
                  <TableCell colSpan={5}>
                    {Array.isArray(job.placementsAppliedTo) && job.placementsAppliedTo.length > 0 ? (
                      <Box>
                        {job.placementsAppliedTo.map((placement, idx) => (
                          <Box key={idx} sx={{ mb: 1 }}>
                            <Typography variant="body2">
                              <strong>Placement ID:</strong> {placement.placementId || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Proposed Fee:</strong> {placement.proposedFee || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Status:</strong> {placement.status || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Comment:</strong> {placement.comments || ''}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      'No Placements'
                    )}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid2>
  );
};

export default JobSummaryTable;
