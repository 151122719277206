import React, { useState, useEffect} from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar, Chip, Stack, Skeleton } from '@mui/material';
import Sidebar from '../../../components/navigation/MainNav';
import SearchBar from '../../../components/widgets/dashboard/SearchBar';
import CampaignsOverview from '../../../components/widgets/campaigns/CampaignsOverview';
import SettingsIcon from '@mui/icons-material/Settings';
import CardGrid from '../../../components/widgets/campaigns/campaignsCards';
import CreateCampaign from '../../../components/widgets/buttons/createCampaign';
import { useParams, useLocation } from 'react-router-dom';
import { getCampaignId } from '../../../api/campaignsApi';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { updateCampaign } from '../../../api/campaignsApi';
import { useNavigate } from 'react-router-dom';
import { statusColors } from '../../../config/status';
import PlacementSummaryTable from '../../../components/widgets/campaigns/PlacementSummaryTable';
import ProfileAvatar from '../../../components/navigation/profileAvatar';


const OfferedCampaignDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {application} = location.state || {};
  const [isLoading, setIsLoading] = useState(true);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [placementData, setPlacementData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [applicationNumber, setApplicationNumber] = useState(0);
  const user_type = localStorage.getItem('user_type');
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [isAcceptanceLoading, setIsAcceptanceLoading] = useState(false);
  const { jobId } = useParams();
  console.log(jobId);
  console.log(application)

  useEffect(() => {
    fetchCampaignDetails();

  }, []);

  const fetchCampaignDetails = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/campaign/list?jobId=${jobId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log(response.data.jobs[0]);
        setCampaignDetails(response.data.jobs[0] || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleAcceptOffer = async (application) => {
    setIsLoading(true);
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user.getIdToken();

    const acceptResponse = await axios.put(
      `https://ostendo.nucleotto.com/api/application/status?applicationId=${application._id}&status=Accepted&contractStatus=Pending`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    console.log('Placement status updated successfully');

    if (acceptResponse) {
      const createNewJob = await axios.post(
        `https://ostendo.nucleotto.com/api/job/create`,
        { application },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      console.log('Job created successfully');

      if (createNewJob) {
        const newJobId = createNewJob.data.jobId;
        console.log(newJobId);
        navigate(`/creators/jobs/detail/${newJobId}`);
      }
    }
  } catch (error) {
    console.error('Error updating placement status:', error);
  }
};

const handleDeclineOffer = async () => {
  setIsLoading(true);
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user.getIdToken();

    await axios.put(
      `https://ostendo.nucleotto.com/api/application/status?applicationId=${application._id}&status=Declined`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    console.log('Placement status updated successfully');
    navigate('/creators/offered');
  } catch (error) {
    console.error('Error updating placement status:', error);
  }
};


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      {isLoading ? (
         <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '90%', ml: 10, mt: 20 }}>
          <Skeleton variant="text" width="60%" height={40} sx={{mb: 2 }}/>
          <Skeleton variant="rounded" width="60%" height={300} />
          <Skeleton variant="rounded" width="40%" height={300} sx={{ }} />
          <Skeleton variant="rounded" width="90%" height={600} sx={{ mt: 4  }} />
       </Grid2> 
      ) : (
        <>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Container maxWidth="xxl">
              <Box>
                <ProfileAvatar />

                {/* Title and Buttons */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}></Typography>
                  <Grid2 item>
                    <Grid2 container justifyContent="flex-end">
                 
                      <Button variant="contained" onClick={() => handleAcceptOffer(application)} sx={{ borderRadius: '10px', mr: 2, bgcolor: 'green' }}>
                        Accept
                      </Button>
                      <Button variant="contained" onClick={handleDeclineOffer} sx={{ borderRadius: '10px', mr: 2, bgcolor: 'red' }}>
                        Decline
                      </Button>
                      
                    </Grid2>
                  </Grid2>
                </Grid2>

                {/* Campaign Name and Advertiser Name */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4 }}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {campaignDetails.campaignName}
                    <Chip
                      label={campaignDetails.status}
                      sx={{
                        ml: 3,
                        backgroundColor:
                          statusColors[campaignDetails.status]?.backgroundColor || statusColors.default.backgroundColor,
                        color: statusColors[campaignDetails.status]?.color || statusColors.default.color,
                      }}
                    />
                  </Typography>
                </Grid2>

                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
                  <Typography variant="h6">{campaignDetails.advertiserName}</Typography>
                </Grid2>
              </Box>

              {/* Main Content */}
              <Box maxWidth="100%" sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Grid2 container direction="column" xs={2} spacing={3} marginRight={14} minWidth={200}>
                  <Grid2 item height={22}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Campaign Type
                    </Typography>
                  </Grid2>
                  <Grid2 item height={22}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Start Date
                    </Typography>
                  </Grid2>
                  <Grid2 item height={22}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      End Date
                    </Typography>
                  </Grid2>
                  <Grid2 item height={40}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Campaign Summary
                    </Typography>
                  </Grid2>
                  <Grid2 item height={56}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Campaign Description
                    </Typography>
                  </Grid2>
                  <Grid2 item height={40}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Product Information
                    </Typography>
                  </Grid2>
                  <Grid2 item height={22}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Product Delivery
                    </Typography>
                  </Grid2>
                </Grid2>

                <Grid2 container direction="column" xs={4} spacing={3} marginRight={10} minWidth={500}>
                  <Grid2 item height={22}>
                    <Typography variant="subtitle1">{campaignDetails.campaignSummary}</Typography>
                  </Grid2>
                  <Grid2 item height={22}>
                    <Typography variant="subtitle1">{campaignDetails.startDate}</Typography>
                  </Grid2>
                  <Grid2 item height={22}>
                    <Typography variant="subtitle1">{campaignDetails.endDate}</Typography>
                  </Grid2>
                  <Grid2 item height={40}>
                    <Typography variant="subtitle1">{campaignDetails.campaignSummary}</Typography>
                  </Grid2>
                  <Grid2 item height={56}>
                    <Typography variant="subtitle1">{campaignDetails.campaignDescription}</Typography>
                  </Grid2>
                  <Grid2 item height={40}>
                    <Typography variant="subtitle1">{campaignDetails.productInformation}</Typography>
                  </Grid2>
                  <Grid2 item height={22}>
                    <Typography variant="subtitle1">{campaignDetails.productDelivery}</Typography>
                  </Grid2>
                </Grid2>

                <Grid2 container direction="column" xs={12} spacing={3}>
                  <Grid2 item>
                    <img src={campaignDetails.image1} alt="hero" style={{ maxHeight: '600px', width: 'auto' }} />
                  </Grid2>
                </Grid2>
              </Box>
                      
              {/* Placement Summary */}
         
            </Container>
          </Box>
        </>
      )}
    </Box>
  );
};

export default OfferedCampaignDetail;
