import { Box } from "@mui/material";

export const statusColors = {
    Live: { backgroundColor: '#10b981', color: 'white' },
    Paused: { backgroundColor: 'orange', color: 'white' },
    Cancelled: { backgroundColor: 'red', color: 'white' },
    Declined: { backgroundColor: 'red', color: 'white' },
    Approved: { backgroundColor: 'green', color: 'white' },
    Complete: { backgroundColor: 'blue', color: 'white' },
    Draft: { backgroundColor: 'grey', color: 'white' },
    default: { backgroundColor: 'grey', color: 'white' },
    live: { backgroundColor: '#10b981', color: 'white' },
    paused: { backgroundColor: 'orange', color: 'white' },
    pending: { backgroundColor: 'orange', color: 'white' },
    Pending: { backgroundColor: 'orange', color: 'white' },
    cancelled: { backgroundColor: 'red', color: 'white' },
    complete: { backgroundColor: 'blue', color: 'white' },
    draft: { backgroundColor: 'grey', color: 'white' },
    default: { backgroundColor: 'grey', color: 'white' }
  };


  export const usageRights = {
    
  }

  export const taskStatusColors = {
    Live: { backgroundColor: '#10b981', color: 'white' },
    Paused: { backgroundColor: 'orange', color: 'white' },
    Cancelled: { backgroundColor: 'red', color: 'white' },
    Declined: { backgroundColor: 'red', color: 'white' },
    Approved: { backgroundColor: 'green', color: 'white' },
    Complete: { backgroundColor: 'blue', color: 'white' },
    Draft: { backgroundColor: 'grey', color: 'white' },
    default: { backgroundColor: 'grey', color: 'white' },
    live: { backgroundColor: '#10b981', color: 'white' },
    paused: { backgroundColor: 'orange', color: 'white' },
    pending: { backgroundColor: 'orange', color: 'white' },
    Pending: { backgroundColor: 'orange', color: 'white' },
    cancelled: { backgroundColor: 'red', color: 'white' },
    complete: { backgroundColor: 'blue', color: 'white' },
    draft: { backgroundColor: 'grey', color: 'white' },
    default: { backgroundColor: 'grey', color: 'white' }
  };



  export const StatusDot = ({ status }) => {
    // Determine dot color based on the status or another influencer property
    let color = 'grey';
    if (status === 'good') {
      color = 'green';
    } else if (status === 'warning') {
      color = 'orange'; // amber can be represented by orange
    } else if (status === 'bad') {
      color = 'red';
    }
    return (
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: color,
        }}
      />
    );
  };
