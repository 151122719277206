// PlacementSummaryTable.jsx
import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Checkbox
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

const PlacementSummaryTable = ({ placements }) => {
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Deliverables
      </Typography>
      {placements.length === 0 ? (
        <Typography variant="body1">No placements available.</Typography>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Select</strong></TableCell>
                <TableCell><strong>Platform</strong></TableCell>
                <TableCell><strong>Placement Type</strong></TableCell>
                <TableCell><strong>Fee</strong></TableCell>
                <TableCell><strong>Product Value</strong></TableCell>
                <TableCell><strong>Comments</strong></TableCell>
                <TableCell><strong>Objective</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {placements.map((placement, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={() => {
                        // Handle checkbox state change for this placement
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {placement.platform === 'Instagram' ? (
                        <InstagramIcon />
                      ) : placement.platform === 'Facebook' ? (
                        <FacebookIcon />
                      ) : placement.platform === 'YouTube' ? (
                        <YouTubeIcon />
                      ) : (
                        '-'
                      )}
                      <Typography variant="body1">
                        {placement.platform || '-'}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{placement.placementType || '-'}</TableCell>
                  <TableCell>
                    {placement.fee ? `$${placement.fee}` : '-'}
                  </TableCell>
                  <TableCell>
                    {placement.productValue ? `$${placement.productValue}` : '-'}
                  </TableCell>
                  <TableCell>{placement.comments || '-'}</TableCell>
                  <TableCell>
                    {Array.isArray(placement.placementObjective)
                      ? placement.placementObjective.join(', ')
                      : placement.placementObjective || '-'
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
  );
};

export default PlacementSummaryTable;
