// PaidInfluencersDashboard.jsx
import React, {useState} from 'react';
import { Box, Container, Typography } from '@mui/material';
import CampaignSummaryCards from './CampaignSummaryCards';
import InfluencerList from '../InfluencerList';
import PlacementSummaryTable from './PlacementSummaryTable';
import BudgetOverview from '../BudgetOverview';
import AcceptedCreatorTable from '../AcceptedCreatorTable';
import InvitedCreatorTable from '../InvitedCreatorTable';
import RecommendedCreators from '../RecommendedCreators';
import CreatorApplicationsPage from '../../../../pages/creators/applications';
import CreatorApplicationsTable from '../CreatorApplicationsTable';
import { useNavigate } from 'react-router-dom';


const PaidInfluencerCampaignDetail = ({
  campaignData,
  influencers,
  placements,
  applications,
  spentBudget // number: total spent budget
}) => {

    const [viewAcceptedOpen, setViewAcceptedOpen] = useState(false);
    const [viewInvitedOpen, setViewInvitedOpen] = React.useState(false);
    const [viewApplicationsOpen, setViewApplicationsOpen] = React.useState(true);
    const [viewMediaPlanOpen, setViewMediaPlanOpen] = React.useState(false);
    const navigate = useNavigate();
    
    const toggleViewInvited = () => {
        setViewInvitedOpen((prev) => !prev);
      };

      const toggleViewApplications = () => {
        setViewApplicationsOpen((prev) => !prev);
      };

      const toggleViewMediaPlan = () => {
      navigate(`media-plan`);
      };

     const toggleViewAccepted = () => {
        setViewAcceptedOpen((prev) => !prev);
      };

  return (
    <Container maxWidth="xl">
    <Typography variant="h6">{campaignData.campaignName}</Typography>



        <Box sx={{ mb: 3, mt: 3 }}>
        <BudgetOverview 
        totalBudget={campaignData.totalBudget} 
        influencers={influencers}
        />
      </Box>
      <CampaignSummaryCards 
      campaignData={campaignData}
      influencers={influencers}
      toggleViewInvited={toggleViewInvited}
      toggleViewAccepted={toggleViewAccepted}
      toggleViewApplications={toggleViewApplications}
      toggleViewMediaPlan={toggleViewMediaPlan}
       />

       <>
       {viewMediaPlanOpen && (
        <Typography variant="h6">Media Plan</Typography>
       )}


       
       {viewAcceptedOpen && (

        <AcceptedCreatorTable
        influencers={influencers}
        toggleViewMediaPlan={toggleViewMediaPlan}

        />
       )}
        {viewInvitedOpen && (

        <InvitedCreatorTable
        influencers={influencers}
        />
        )}

        {viewApplicationsOpen && (

        <CreatorApplicationsTable
        influencers={influencers}
        applicationData={applications}
        />
        )}
            
       </>
 
      <PlacementSummaryTable 
      placements={placements} />

<RecommendedCreators />  
    </Container>
  );
};

export default PaidInfluencerCampaignDetail;
