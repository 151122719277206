import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import PrivateRoute from './auth/privateRoute';
import Dashboard from './pages/Dashboard';
import VideoPlayer from './pages/VideoPlayer';
import LoginPage from '../src/auth/login';
import CampaignsPage from './pages/campaigns';
import SocialMediaPage from './pages/SocialMedia';
import TasksPage from './pages/tasks';
import CreateCampaign from './pages/campaigns/create';

import LogoutPage from './auth/logout';
import CampaignDetail from './pages/campaigns/detail';
import RegisterPage from './auth/register';
import PrimarySocialSelect from './auth/register/primarySocial';
import PrimaryDetailsConfirm from './auth/register/primaryDetails';
import PrimaryContact from './auth/register/primaryContact';
import PrimaryBio from './auth/register/primaryBio';
import PrimaryNiche from './auth/register/primaryNiche';
import PrimaryStage from './auth/register/primaryStage';
import PrimaryAssets from './auth/register/primaryAssets';
import MediaPlan from './pages/campaigns//mediaPlan';
import EditCampaign from './pages/campaigns/edit';
import CampaignApplications from './pages/campaigns/applications';
import MessagingComponent from './pages/messaging';
import UserProfilePage from './pages/profile';
import { ThemeProvider } from '@mui/material/styles';
import theme from './config/theme';
import AdminProfilePage from './pages/adminProfile';
import UsersListPage from './pages/users';
import UserDetailPage from './pages/users/detail';
import CreateBrandPage from './pages/brands/create';
import BrandDetailPage from './pages/brands/detail';
import BrandsListPage from './pages/brands';
import CreateUserPage from './pages/users/create';
import UserEditPage from './pages/users/edit';
import BrandEditPage from './pages/brands/edit';
import EditProfilePage from './pages/profile/edit';
import CreatorsCampaignsPage from './pages/creators';
import CreatorCampaignDetail from './pages/creators/detail';
import CreatorsListPage from './pages/users/creators';
import CreatorProfilePage from './pages/creators/profile';
import EditCreatorProfilePage from './pages/creators/profile/edit';
import CreatorApplicationsPage from './pages/creators/applications';
import OfferedCampaignDetail from './pages/creators/offered/detail';
import CreatorsJobsPage from './pages/creators/jobs';
import CreatorsJobDetailPage from './pages/creators/jobs/detail';
import CreatorsTasksPage from './pages/creators/tasks';
import ApplicationsListPage from './pages/applications';
import AdminJobsPage from './pages/jobs';
import AdminJobDetailPage from './pages/jobs/detail';
import AdminTasksPage from './pages/tasks';
import CreateCampaignStepper from './pages/campaigns/CreateCampaignStepper';
import CampaignSettingsView from './components/widgets/campaigns/PaidInfluencers/campaignSettings';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />


          <Route path="/register" element={<RegisterPage />} />
          <Route path="/register/primary-social" element={<PrimarySocialSelect />} />
          <Route path="/register/primary-details" element={<PrimaryDetailsConfirm />} />
          <Route path="/register/primary-niche" element={<PrimaryNiche />} />
          <Route path="/register/primary-contact" element={<PrimaryContact />} />
          <Route path="/register/primary-stage" element={<PrimaryStage />} />
          <Route path="/register/primary-bio" element={<PrimaryBio />} />
          <Route path="/register/primary-assets" element={<PrimaryAssets />} />

 
          <Route path="/campaigns" element={<PrivateRoute><CampaignsPage /></PrivateRoute>} />
          <Route path="/campaigns/detail/:jobId" element={<PrivateRoute><CampaignDetail /></PrivateRoute>} />
          <Route path="/campaigns/create" element={<PrivateRoute><CreateCampaign /></PrivateRoute>} />
          <Route path="/campaigns/edit/:jobId" element={<PrivateRoute><EditCampaign /></PrivateRoute>} />
          <Route path="/campaigns/detail/:jobId/settings" element={<PrivateRoute><CampaignSettingsView /></PrivateRoute>} />
          <Route path="/campaigns/detail/:jobId/media-plan" element={<PrivateRoute><MediaPlan /></PrivateRoute>} />


          <Route path="/campaigns/placements" element={<PrivateRoute><MediaPlan /></PrivateRoute>} />
          <Route path="/mediaplan/:campaignId" element={<PrivateRoute><MediaPlan /></PrivateRoute>} />
          <Route path="/campaigns/applications" element={<PrivateRoute><CampaignApplications /></PrivateRoute>} />
          <Route path="/applications" element={<PrivateRoute><ApplicationsListPage /></PrivateRoute>} />

          <Route path="/campaigns/stepper" element={<PrivateRoute><CreateCampaignStepper /></PrivateRoute>} />


         
          <Route path="/creators/dashboard" element={<PrivateRoute><CreatorsCampaignsPage /></PrivateRoute>} />
          <Route path="/creators/detail/:jobId" element={<PrivateRoute><CreatorCampaignDetail /></PrivateRoute>} />
          <Route path="/creators/profile" element={<PrivateRoute><CreatorProfilePage /></PrivateRoute>} />
          <Route path="/creators/profile/edit" element={<PrivateRoute><EditCreatorProfilePage /></PrivateRoute>} />
          <Route path="/creators/applications" element={<PrivateRoute><CreatorApplicationsPage /></PrivateRoute>} />
          <Route path="/creators/offered/detail/:jobId" element={<PrivateRoute><OfferedCampaignDetail /></PrivateRoute>} />
          <Route path="/creators/jobs" element={<PrivateRoute><CreatorsJobsPage /></PrivateRoute>} />
          <Route path="/creators/jobs/detail/:jobId" element={<PrivateRoute><CreatorsJobDetailPage /></PrivateRoute>} />
          <Route path="/creators/tasks" element={<PrivateRoute><CreatorsTasksPage /></PrivateRoute>} />

                    

          <Route path="/jobs" element={<PrivateRoute><AdminJobsPage /></PrivateRoute>} />
          <Route path="/jobs/detail/:jobId" element={<PrivateRoute><AdminJobDetailPage /></PrivateRoute>} />


          
          
          <Route path="/messaging" element={<PrivateRoute><MessagingComponent /></PrivateRoute>} />
         
         
          <Route path="/profile" element={<PrivateRoute><UserProfilePage /></PrivateRoute>} />
          <Route path="/profile/edit" element={<PrivateRoute><EditProfilePage /></PrivateRoute>} />

          <Route path="/adminProfile" element={<PrivateRoute><AdminProfilePage /></PrivateRoute>} />
         
                  
          <Route path="/users" element={<PrivateRoute><UsersListPage /></PrivateRoute>} />
          <Route path="/users/create" element={<PrivateRoute><CreateUserPage /></PrivateRoute>} />
          <Route path="/users/detail" element={<PrivateRoute><UserDetailPage /></PrivateRoute>} />
          <Route path="/users/edit" element={<PrivateRoute><UserEditPage /></PrivateRoute>} />
          <Route path="/users/creators" element={<PrivateRoute><CreatorsListPage /></PrivateRoute>} />

          
          <Route path="/brands" element={<PrivateRoute><BrandsListPage /></PrivateRoute>} />
          <Route path="/brands/create" element={<PrivateRoute><CreateBrandPage /></PrivateRoute>} />
          <Route path="/brands/detail" element={<PrivateRoute><BrandDetailPage /></PrivateRoute>} />
          <Route path="/brands/edit" element={<PrivateRoute><BrandEditPage /></PrivateRoute>} />
         

         
          <Route path="/social-media" element={<PrivateRoute><SocialMediaPage /></PrivateRoute>} />
         
         
          <Route path="/tasks" element={<PrivateRoute><AdminTasksPage /></PrivateRoute>} />
        
        
          <Route path="/videoplayer" element={<PrivateRoute><VideoPlayer /></PrivateRoute>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
