import React from 'react';
import { Button, Stack, CircularProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
// Import other icons as needed
import SendIcon from '@mui/icons-material/Send';
import DrawTwoToneIcon from '@mui/icons-material/DrawTwoTone';
import DownloadDoneTwoToneIcon from '@mui/icons-material/DownloadDoneTwoTone';

const TaskActions = ({ task, customActionHandler, taskActionLoading, currentTaskId }) => {


    if (task.status === 'Completed') {
        return null;
      }


  const actionsConfig = {


    
    'Accept Contract': [
        { 
          label: 'View and Sign', 
          icon: <DrawTwoToneIcon />, 
          onClick: () => {
        
            if (customActionHandler) customActionHandler(task, 'signContract');
          }
        }
      ],


    'Upload Creative Brief': [
      { 
        label: 'Upload', 
        icon: <UploadFileIcon />, 
        onClick: () => {
      
          if (customActionHandler) customActionHandler(task, 'uploadCreativeBrief');
        }
      }
    ],
    'Upload Content Concepts': [
        { 
          label: 'Upload', 
          icon: <UploadFileIcon />, 
          onClick: () => {
        
            if (customActionHandler) customActionHandler(task, 'uploadContentConcepts');
          }
        }
      ],

    'Confirm Posting Dates': [
        { 
          label: 'Set Date', 
          icon: <UploadFileIcon />, 
          onClick: () => {
               // Optionally call a custom handler if provided.
            if (customActionHandler) customActionHandler(task, 'confirmPostingDates');
          }
        }
      ],

      'Read Creative Brief': [
        { 
          label: 'Done', 
          icon: <DownloadDoneTwoToneIcon />, 
          onClick: () => {
      
            // Optionally call a custom handler if provided.
            if (customActionHandler) customActionHandler(task, 'markDone');
          }
        }
      ],
      'Receive Product': [
        { 
          label: 'Done', 
          icon: <DownloadDoneTwoToneIcon />, 
          onClick: () => {
      
            // Optionally call a custom handler if provided.
            if (customActionHandler) customActionHandler(task, 'markDone');
          }
        }
      ],

      'Approve Concepts': [
        { 
          label: 'Approve', 
          icon: <DownloadDoneTwoToneIcon />, 
          onClick: () => {
      
            // Optionally call a custom handler if provided.
            if (customActionHandler) customActionHandler(task, 'markDone');
          }
        }
      ],

      'Receive Creative': [
        { 
          label: 'Received', 
          icon: <DownloadDoneTwoToneIcon />, 
          onClick: () => {
     
            // Optionally call a custom handler if provided.
            if (customActionHandler) customActionHandler(task, 'markDone');
          }
        }
      ],

      'Upload Creative for Review': [
        { 
          label: 'Upload', 
          icon: <UploadFileIcon />, 
          onClick: () => {
     
            // Optionally call a custom handler if provided.
            if (customActionHandler) customActionHandler(task, 'uploadCreative');
          }
        }
      ],

      'Give Creative Feedback': [
        { 
          label: 'Upload', 
          icon: <UploadFileIcon />, 
          onClick: () => {
        
            // Optionally call a custom handler if provided.
            if (customActionHandler) customActionHandler(task, 'upload');
          }
        }
      ],

      'Approve Creative': [
        { 
          label: 'Upload', 
          icon: <UploadFileIcon />, 
          onClick: () => {
         
            // Optionally call a custom handler if provided.
            if (customActionHandler) customActionHandler(task, 'upload');
          }
        }
      ],



    'Send Product': [
      {
        label: 'Mark Sent', 
        icon: <SendIcon />, 
        onClick: () => {
            
          if (customActionHandler) customActionHandler(task, 'send');
        }
      }
    ]
  };
  const actions = actionsConfig[task.taskName] || [];

  if (actions.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      {actions.map((action, index) => {
        // For the 'send' action, show a spinner if this task is loading.
        if (
          currentTaskId === task._id &&
          taskActionLoading
        ) {
          return <CircularProgress key={index} size={24} />;
        }
        return (
          <Button
            key={index}
            variant="outlined"
            startIcon={action.icon}
            size="small"
            onClick={action.onClick}
          >
            {action.label}
          </Button>
        );
      })}
    </Stack>
  );
};

export default TaskActions;