import React, { useState, useRef, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid2,
  TextField,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  FormControl,
  RadioGroup,
  Radio,
  InputLabel, 
  MenuItem, 
  Select, 
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  Chip
  } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import { createCampaign } from '../../api/campaignsApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { getBrand } from '../../api/brandApi';
import { getAllBrands } from '../../api/brandApi'; // Import getAllBrands
import { campaignTypes } from '../../config/campaignType';
import { influencerProfile } from '../../config/influencerProfile';
import CreateCampaignStepper from './CreateCampaignStepper';
import theme from '../../config/theme';
import PaidInfluencersStepper from '../../components/widgets/campaigns/PaidInfluencerStepper';
import ProductSeedingStepper from '../../components/widgets/campaigns/ProductSeedingStepper';
import EventAttendanceStepper from '../../components/widgets/campaigns/EventAttendanceStepper';
import UGCAdsStepper from '../../components/widgets/campaigns/UGCAdsStepper';

const CreateCampaign = () => {
const user = sessionStorage.getItem('userId');
const [blobUrl, setBlobUrl] = useState('');
const [isSubmitting, setIsSubmitting] = useState(false);
const user_type = localStorage.getItem('user_type');
const [imageUploadLoding, setImageUploadLoading] = useState(false);
const now = new Date();
const navigate = useNavigate();
const [openBudget, setOpenBudget] = useState(false);
const [startDateError, setStartDateError] = useState('');
const [campaignData, setCampaignData] = useState({
    brandId: '', 
    advertiserName: '',
    campaignName: '',
    jobType: '',
    remuneration: '',
    campaignSummary: '',
    campaignDescription: '',
    websiteLink: '',
    startDate: '',
    endDate: '',
    contentSubmittedBy: '',
    contentExpectations: {  
      influencerChannels: false,
      brandedCreativeAssets: false,
      repurposedByBrand: false,
      brandChannels: false,
      boostedOnBrandChannels: false,
      boostedOnInfluencerChannels: false
    },
    productInformation: '',
    productDelivery: '',
    useagePeriodPerAsset: '',
    boostingPeriodPerAsset:'',
    usageDetails: '',
    contentPreApproval: '',
    influencerType: '',
    targetAudiences: '',
    targetAge: '',
    ugcContentPackages: '',
    ugcCreatorExperience: '',
    influencerSize: '',
    influencerGender: '',
    influencerCategoy: '',
    influencerLocation: '',
    influencerAge: '',
    audienceLocation: '',
    audienceGender: '',
    audienceAge: '',
    tvcCtvContentPackages: '',
    tvcCtvCrew: '',
    applicationCloseDate: '',
    createdBy: user,
    totalBudget: '',
    createdDate: now,
    contentSubmittedDate: '',
    image1: '',
    status: 'Draft',
    placements: []
  });
  const [brands, setBrands] = useState([]); // Add state for brands
  const [file, setFile] = useState(null); // State to store the selected file
  const [errorUploadingFile, setErrorUploadingFile] = useState('');
  const [canNotSubmitMessage, setCanNotSubmitMessage] = useState('');
  const [uploadingFileSuccessMessage, setUploadingFileSuccessMessage] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [dateError, setDateError] = useState('');
  const [applicationDateError, setApplicationDateError] = useState('');

  


  useEffect(() => {
    const fetchAdvertiserData = async () => {
    const user_type = localStorage.getItem('user_type');
    console.log("user type:", user_type);
    if (user_type === 'admin') {
    const brandsList = await getAllBrands();
    setBrands(brandsList);
    }
    if (user_type === 'brandAdmin' || user_type === 'brandUser') {
      const profileId = localStorage.getItem('brand_id');
      try {
      const advertiserRecord = await getBrand(profileId);
      const advertiserName = advertiserRecord[0].company_name;
      const brandId = advertiserRecord[0]._id;
      console.log('brandId:', brandId);
      console.log('advertiserRecord:', advertiserName);
      setCampaignData((prevData) => ({
        ...prevData,
        advertiserName: advertiserName,
        brandId:  brandId,
      }));
    } catch (error) {
      console.error('Error fetching advertiser data:', error);
    }
  }
    }
    fetchAdvertiserData();
}, []);

const refreshBrands = async () => {
  const brandsList = await getAllBrands();
  setBrands(brandsList);
};


useEffect(() => {
  const {duplicateCampaignData} = location.state || {};
  if (duplicateCampaignData) {
    console.log("DUPLICATING CAMPAIGN DATA")
    setCampaignData((prevData) => ({
      ...prevData,
      ...duplicateCampaignData,
      placements: duplicateCampaignData.placements || [], // Ensure placements are initialized
    }));
  }
  }, []); 
  

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCampaignData({ ...campaignData, [name]: value });

    if (name === 'startDate' || name === 'endDate') {
      validateDates(name === 'startDate' ? value : campaignData.startDate, name === 'endDate' ? value : campaignData.endDate);
    }

    if (name === 'applicationCloseDate') {
      validateApplicationCloseDates(name === 'applicationCloseDate' ? value : campaignData.applicationCloseDate, name === 'endDate' ? value : campaignData.endDate);
    }

  
  };



  const validateDates = (startDate, endDate) => {
   if (new Date(startDate) < new Date(now)) {
    setStartDateError('Start date cannot be earlier than today.');
   } else if (new Date(startDate) > new Date(endDate)) {
      setDateError('End date cannot be earlier than start date.');
    } else {
      setDateError('');
    }
  };

  const validateApplicationCloseDates = (applicationCloseDate, startDate) => {
    if (new Date(applicationCloseDate) > new Date(startDate)) {
      setApplicationDateError('Application close date cannot be later than start date.');
    } else {
      setApplicationDateError('');
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    setImageUploadLoading(true);
  
    try {
console.log('uploading');
const response =  await axios.post('https://crowdswell.nucleotto.com/api/blob/uploadFile', file, {
  headers: {
    'Content-Type': file.type || 'application/octet-stream',
    'X-Filename': file.name,
  },
});
if (response) {
  setImageUploadLoading(false);
  setUploadingFileSuccessMessage('File successfully uploaded');
}
if (!response.data.url) {
  setErrorUploadingFile('OOPS! we encountered an error, please press upload again.');
} else {
const imageURL = response.data.url;
setBlobUrl(response.data.url);
setUploadingFileSuccessMessage('File successfully uploaded');
setCampaignData((prevData) => ({
  ...prevData,
  image1: imageURL,
  
}));

}} catch (error) {
console.error('Error uploading file:', error);
} 
};



  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData({ ...campaignData, [name]: checked });
  };

  const handleCheckboxChangeJobType = (event) => {
    const { value } = event.target;
    setCampaignData((prevData) => ({
      ...prevData,
      jobType: value, // Set the selected job type directly
    }));
  };

  const handleCheckboxContentExpectations = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      contentExpectations: {
        ...prevData.contentExpectations,
        [name]: checked
      }
    }));
  };

  const handleOpenBudgetChange = (event) => {
    const { checked } = event.target;
    setOpenBudget(checked);
    setCampaignData((prevData) => ({
      ...prevData,
      totalBudget: checked ? 0 : prevData.totalBudget,
    }));
  };



  useEffect(() => { 
    console.log('campaignData Job Type:', campaignData.jobType);
  }, [campaignData.jobType]);

  const addPlacement = () => {
    setCampaignData((prevData) => ({
      ...prevData,
      placements: [
        ...prevData.placements,
        {
          campaignType: campaignData.jobType,
          platform: '',
          format: '',
          resharing: '',
          boosting: '',
          usagePeriod: '',
          exclusivityPeriod: '',
          remuneration: '',
          fee: 0,
          status: 'Draft',
          contentPreApproval: false,
          comments: {
            campaignTypeComments: '',
            platformComments: '',
            formatComments: '',
            resharingComments: '',
            boostingComments: '',
            usagePeriodComments: '',
            exclusivityPeriodComments: '',
            remunerationComments: '',
            feeComments: ''
          }
        }
      ]
    }));
  };

  const handlePlacementChange = (index, event) => {
    const { name, value } = event.target;
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements[index] = {
        ...updatedPlacements[index],
        [name]: value
      };
      return { ...prevData, placements: updatedPlacements };
    });
  };

  const removePlacement = (index) => {
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements.splice(index, 1);
      return { ...prevData, placements: updatedPlacements };
    });
  };
  


  const handlePlacementCommentChange = (index, event) => {
    const { name, value } = event.target;
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements[index].comments = {
        ...updatedPlacements[index].comments,
        [name]: value
      };
      return { ...prevData, placements: updatedPlacements };
    });
  };

  const handleSubmit = async (e) => {
    if (!blobUrl){
      setCanNotSubmitMessage('Missing fields: you must upload at least one image');
      setIsSubmitting(false);
      return;
    }
    setIsSubmitting(true);
    e.preventDefault();
    
    setCampaignData((prevData) => ({
      ...prevData,
      status: 'Draft',
    }));
      
    const submitCampaign = await createCampaign(campaignData); 
    console.log(submitCampaign);
    if (submitCampaign) {
      navigate(`/campaigns/detail/${submitCampaign}`, { state: { jobId: submitCampaign } });
    }
  };

  const handlePublish = async (e) => {
    if (!blobUrl){
      setCanNotSubmitMessage('Missing fields: you must upload at least one image');
      setIsSubmitting(false);
      return;
    }

    const mandatoryFields = [
      'campaignName', 
      'startDate', 
      'endDate', 
      'applicationCloseDate',
      'totalBudget', 
      'brandId', 
      'advertiserName'
    ];
    const missingFields = mandatoryFields.filter(field => !campaignData[field]);

    if (missingFields.length > 0) {
      setCanNotSubmitMessage(`Please fill in the following mandatory fields: ${missingFields.join(', ')}`);
      return;
    }

    if (dateError || startDateError || applicationDateError) {
      setCanNotSubmitMessage('Please correct the date errors.');
      return;
    }
    setIsSubmitting(true);
    e.preventDefault();
    
    setCampaignData((prevData) => ({
      ...prevData,
      status: 'Live',
    }));
      
    const submitCampaign = await createCampaign(campaignData); 
    console.log(submitCampaign);
    if (submitCampaign) {
      navigate(`/campaigns/detail/${submitCampaign}`, { state: { jobId: submitCampaign } });
    }
  };

  const handleNextTab = () => {
    setActiveTab((prevTab) => Math.min(prevTab + 1, 4));
  };
  
  const handlePreviousTab = () => {
    setActiveTab((prevTab) => Math.max(prevTab - 1, 0));
  };

  const handleBrandChange = (e) => {
    const selectedBrandId = e.target.value;
    const selectedBrand = brands.find((brand) => brand._id === selectedBrandId);

    // Update both advertiserName and brandId in the state
    setCampaignData((prevData) => ({
      ...prevData,
      advertiserName: selectedBrand?.company_name || '',
      brandId: selectedBrandId,
    }));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
          <ProfileAvatar />

            {/* Title and Button */}
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Create {campaignData.jobType} Campaign</Typography>
              <Button variant="contained" onClick={handleSubmit} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Saving...' : 'Save Draft'}
                
              </Button>
              
            </Grid2>

        
 <Box sx={{ maxWidth: '80%', margin: 'auto', padding: 3 }}>

{!campaignData.jobType && (
  <>
 <Typography variant="subtitle1" marginTop="15px" fontWeight="bold">First, let's choose a job type</Typography>
 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
  {Object.values(campaignTypes).map((type) => (
    <Chip
      key={type}
      label={type}
      clickable
      onClick={() =>
        setCampaignData({
          ...campaignData,
          jobType: type,
        })
      }
      color={campaignData.jobType === type ? 'primary' : 'default'}
      sx={{ mr: 1, my: 1 }}
    />
  ))}
</Box>
            </>
)}
      {campaignData.jobType === 'Paid Influencers' && (
        <>
<ThemeProvider theme={theme}>
            <PaidInfluencersStepper 
              jobType={campaignData.jobType}
              brands={brands}
              refreshBrands={() => refreshBrands()}
        

            />
            </ThemeProvider>

      </>
      )}
      {campaignData.jobType === 'Product Seeding' && (
        <>
<ThemeProvider theme={theme}>
            <ProductSeedingStepper 
              jobType={campaignData.jobType}
              brands={brands}
              refreshBrands={() => refreshBrands()}
        

            />
            </ThemeProvider>

      </>
      )}

{campaignData.jobType === 'Event Attendance' && (
        <>
<ThemeProvider theme={theme}>
            <EventAttendanceStepper 
              jobType={campaignData.jobType}
              brands={brands}
              refreshBrands={() => refreshBrands()}
        

            />
            </ThemeProvider>

      </>
      )}

{campaignData.jobType === 'UGC Ads' && (
        <>
<ThemeProvider theme={theme}>
            <UGCAdsStepper 
              jobType={campaignData.jobType}
              brands={brands}
              refreshBrands={() => refreshBrands()}
        

            />
            </ThemeProvider>

      </>
      )}

  </Box>                  
              
          </Box>
          <Box align="right" marginTop='20px'>
          <Button variant="contained" onClick={handleSubmit} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Saving...' : 'Save Draft'}
                
              </Button>
              <Button variant="contained" onClick={handlePublish} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Publishing...' : 'Publish'}
                
              </Button>
              <Typography variant='subtitle2'>{canNotSubmitMessage}</Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children } = props;
  return (
    <Box sx={{ paddingTop: 3 }}>
      {children}
    </Box>
  );
};

export default CreateCampaign;
