import React, { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Chip,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Paper
} from '@mui/material';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const CampaignSummaryTable = ({ campaignData, favourites, updateFavourites }) => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState('All');

  // Define only the common columns
  const columns = useMemo(() => [
    {
      Header: 'Advertiser',
      accessor: 'advertiserName'
    },
    {
      Header: 'Campaign Name',
      accessor: 'campaignName'
    },
    {
      Header: 'Start Date',
      accessor: 'startDate'
    },
    {
      Header: 'End Date',
      accessor: 'endDate'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <Chip
          label={value}
          sx={{
            backgroundColor:
              statusColors[value]?.backgroundColor || statusColors.default.backgroundColor,
            color: statusColors[value]?.color || statusColors.default.color
          }}
        />
      )
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <Box>
          <IconButton onClick={() => navigate(`/campaigns/detail/${row.original._id}`)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => navigate(`/campaigns/edit/${row.original._id}`)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleToggleFavourite(row.original)}>
            {favourites.includes(row.original._id) ? (
              <StarIcon color="warning" />
            ) : (
              <StarBorderIcon />
            )}
          </IconButton>
        </Box>
      )
    }
  ], [favourites, navigate]);

  // Filter data based on the selected status
  const filteredData = useMemo(() => {
    return statusFilter === 'All'
      ? campaignData
      : campaignData.filter(campaign => campaign.status === statusFilter);
  }, [campaignData, statusFilter]);

  // Table hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state
  } = useTable({ columns, data: filteredData }, useGlobalFilter, useSortBy);
  const { globalFilter } = state;

  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleToggleFavourite = async (row) => {
    const campaignId = row._id;
    const isFavourite = favourites.includes(campaignId);
    const action = isFavourite ? 'remove' : 'add';

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const accessToken = await user.getIdToken();
        await axios.post(
          'https://ostendo.nucleotto.com/api/user/favourites',
          { campaignId, action },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );

        const updatedFavourites = isFavourite
          ? favourites.filter(id => id !== campaignId)
          : [...favourites, campaignId];
        updateFavourites(updatedFavourites);
      }
    } catch (error) {
      console.error('Error updating favourites:', error);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2
        }}
      >
        <Typography variant="h6">Campaign Summary</Typography>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Status</InputLabel>
          <Select value={statusFilter} onChange={handleFilterChange}>
            <MenuItem value="All">All</MenuItem>
            {Array.from(new Set(campaignData.map(c => c.status))).map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Paper>
        <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      textAlign: 'left',
                      padding: '8px',
                      borderBottom: '1px solid #ccc',
                      cursor: 'pointer'
                    }}
                  >
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownwardIcon fontSize="small" />
                      ) : (
                        <ArrowUpwardIcon fontSize="small" />
                      )
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        textAlign: 'left',
                        padding: '8px',
                        borderBottom: '1px solid #eee'
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Paper>
    </Box>
  );
};

export default CampaignSummaryTable;
