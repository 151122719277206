import React from 'react';
import { Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../api/userApi';

const FacebookSignInButton = ({onSignInLoading}) => {
  const navigate = useNavigate();

  const handleFacebookSignIn = async () => {
    onSignInLoading(false);
    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const userId = result.user.uid;
      localStorage.setItem('userId', userId);
      localStorage.setItem('email', result.user.email);
      localStorage.setItem('display_name', result.user.displayName);

      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      localStorage.setItem('user_type', user_type);

      navigate(user_type === 'creator' ? '/creators/dashboard' : '/campaigns');
    } catch (error) {
      console.error(error);
    } finally {
        onSignInLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleFacebookSignIn}
      startIcon={<FacebookIcon />}
      sx={{
        width: '100%',
        marginBottom: '10px',
        backgroundColor: '#3b5998',
        '&:hover': {
          backgroundColor: '#2d4373',
        },
      }}
    >
      Sign in with Facebook
    </Button>
  );
};

export default FacebookSignInButton;