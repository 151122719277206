import React from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid2,
  Box
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const CreatorFilesSummaryCards = ({ jobDetails }) => {

  
console.log(jobDetails, "jobDetails")

  const handleDownload = (fileUrl) => {
    if (fileUrl) {
      window.open(fileUrl, '_blank', 'noopener,noreferrer');
    } else {
      alert('No file available.');
    }
  };

  // Optional: Handler to view more details about a job
  const handleViewJob = (jobId) => {
    // Implement navigation to a detailed view if needed
    console.log('View job:', jobId);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
  <Grid2 container spacing={3}   sx={{  gap: '20px' }}  >
    <Grid2 item xs={12} sm={6} md={4}>
      <Card sx={{ 
          minWidth: 175, 
          maxWidth: 250, 
          boxShadow: 3, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between',
          borderRadius: 4,
          mx: 'auto' // Centers the card within the grid item
        }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h6" component="div" gutterBottom>
            Creative Brief
          </Typography>
          <InsertDriveFileIcon sx={{ fontSize: 40, mt: 2 }} />
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          {jobDetails.creativeBriefUrl ? (
            <Button
              size="small"
              variant="contained"
              onClick={() => handleDownload(jobDetails.creativeBriefUrl)}
              sx={{mb: 2}}
            >
              Open/Download
            </Button>
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
              Pending...
            </Typography>
          )}
        </CardActions>
      </Card>
      </Grid2>

      <Grid2 item xs={12} sm={6} md={4}>
      <Card sx={{ 
          minWidth: 175, 
          maxWidth: 250, 
          boxShadow: 3, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between',
          borderRadius: 4,
          mx: 'auto' // Centers the card within the grid item
        }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h6" component="div" gutterBottom>
            Content Concept
          </Typography>
          <InsertDriveFileIcon sx={{ fontSize: 40, mt: 2 }} />
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          {jobDetails.contentConceptsUrl ? (
            <Button
              size="small"
              variant="contained"
              onClick={() => handleDownload(jobDetails.creativeBriefUrl)}
              sx={{mb: 2}}
            >
              Open/Download
            </Button>
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
              Pending...
            </Typography>
          )}
        </CardActions>
      </Card>
    </Grid2>
  </Grid2>
</Box>
  );
};

export default CreatorFilesSummaryCards;
