// PaidInfluencerCampaignDetailCreator.jsx
import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Chip,
} from '@mui/material';

// A helper to render a labelled field in a clean format
const renderField = (label, value) => {
  let displayValue = value;
  if (Array.isArray(value)) {
    displayValue = value.length ? value.join(', ') : '-';
  }
  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {displayValue || '-'}
      </Typography>
    </Box>
  );
};

const CampaignSettings = ({ campaignData, placements, applications }) => {
  return (
    <Box>
      {/* Overview Section */}
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, borderBottom: 1, pb: 1 }}>
          Overview
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {renderField('Advertiser', campaignData.advertiser)}
            {renderField('Product Information', campaignData.productInformation)}
            {renderField('Campaign Summary', campaignData.campaignSummary)}
            {renderField('Campaign Objective', campaignData.campaignObjective)}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderField('Campaign Term', campaignData.campaignTerm)}
            {renderField('Total Budget', `$${campaignData.totalBudget}`)}
            {renderField('Start Date', campaignData.startDate)}
            {renderField('End Date', campaignData.endDate)}
          </Grid>
        </Grid>
      </Paper>

      {/* Target Audience Section */}
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, borderBottom: 1, pb: 1 }}>
          Target Audience
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {renderField('Age Range', campaignData.targetAudienceAge)}
            {renderField('Gender', campaignData.targetAudienceGender)}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderField('Primary Location', campaignData.targetPrimaryAudienceLocation)}
            {renderField('Secondary Locations', campaignData.targetSecondaryAudienceLocation)}
          </Grid>
        </Grid>
      </Paper>

      {/* Influencer Requirements Section */}
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, borderBottom: 1, pb: 1 }}>
          Influencer Requirements
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {renderField('Influencer Age Range', campaignData.influencerAgeRange)}
            {renderField('Influencer Gender', campaignData.influencerGender)}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderField('Influencer Location', campaignData.influencerLocation)}
            {renderField('Influencer Category', campaignData.influencerCategory)}
          </Grid>
        </Grid>
      </Paper>

      {/* Deliverables Section */}
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, borderBottom: 1, pb: 1 }}>
          Deliverables
        </Typography>
        {placements && placements.length > 0 ? (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Placements
            </Typography>
            {placements.map((p, idx) => (
              <Box key={idx} sx={{ ml: 2, mb: 1 }}>
                <Typography variant="body2">
                  {p.platform} – {p.placementType} (Fee: {p.fee || '-'}; Product Value: {p.productValue || '-'})
                </Typography>
                {p.comments && (
                  <Typography variant="caption">
                    Comments: {p.comments}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        ) : (
          renderField('Placements', 'None')
        )}
        {renderField('Placement Objective', campaignData.placementObjective)}
        {renderField('Post Date Timeline', campaignData.postDateTimeline)}
        {renderField('Content Deadlines', campaignData.contentDeadlines)}
        {renderField('Content Approval Needed', campaignData.contentApprovalNeeded)}
      </Paper>

      {/* Campaign Expectations Section */}
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, borderBottom: 1, pb: 1 }}>
          Campaign Expectations
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {renderField('Content Revisions', campaignData.contentRevisions)}
            {renderField('Performance Metrics', campaignData.performanceMetrics)}
            {renderField('Reporting Required', campaignData.reportingRequired)}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderField('Paid Disclosure', campaignData.paidDisclosure)}
            {renderField('Brand Tagging', campaignData.brandTagging)}
            {renderField('Shoppable Links', campaignData.shoppableLinks)}
            {renderField('Interactivity', campaignData.interactivity)}
          </Grid>
        </Grid>
        {renderField('Final Comments', campaignData.finalComments)}
        {renderField('Product Value', `$${campaignData.productValue}`)}
      </Paper>
    </Box>
  );
};

export default CampaignSettings;
