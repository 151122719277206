import React from 'react';
import { Box, Paper, 
    Typography,  
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
     } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DashboardCard = ({ title, count, onClick }) => {
  return (
    <Paper
      elevation={3}
      onClick={onClick}
      sx={{
        padding: 2,
        cursor: 'pointer',
        flex: '1 1 150px',
        textAlign: 'center',
        '&:hover': { backgroundColor: 'grey.100' }
      }}
    >
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="h4" sx={{ mt: 1 }}>
        {count || 0}
      </Typography>
    </Paper>
  );
};

const CampaignSummaryCards = ({ campaignData, toggleViewInvited, toggleViewAccepted, toggleViewApplications }) => {
  const navigate = useNavigate();
  const jobId = campaignData._id;

  // Assuming these numeric fields are available in campaignData
  const {
    jobsLive,
    jobsFinished,
    paid,
    totalBudget
  } = campaignData;

  const creatorsInvited = 2; // Placeholder value
  const creatorsAccepted = 2; // Placeholder value
    const applications = 2; // Placeholder value

  const influencers = [
    {
    _id: '1',
    name: 'Jason Bourne',
    status: 'Invited',
    followers: '26k'
  },
  {
    _id: '2',
    name: 'Jessica Rabbit',
    status: 'Invited',
    followers: '180k'
  },

]


  return (
    <>
    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 3 }}>
      <DashboardCard
        title="Creators Invited"
        count={creatorsInvited}
        onClick={toggleViewInvited}
        />
      <DashboardCard
        title="Creators Accepted"
        count={creatorsAccepted}
       // onClick={() => navigate(`/campaigns/detail/${jobId}/accepted`)}
        onClick={toggleViewAccepted}
      />
       <DashboardCard
        title="Total Applications"
        count={applications}
        onClick={toggleViewApplications}
      />
      <DashboardCard
        title="Jobs Live"
        count={jobsLive}
        onClick={() => navigate(`/campaigns/detail/${jobId}/live`)}
        
      />
 
      <DashboardCard
        title="Paid"
        count={paid}
        onClick={() => navigate(`/campaigns/detail/${jobId}/paid`)}
      />
      <DashboardCard
        title="Total Budget"
        count={`$${totalBudget}`}
        onClick={() => navigate(`/campaigns/detail/${jobId}//budget`)}
      />
    </Box>


</>

  );
};

export default CampaignSummaryCards;
