import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Badge,
  CssBaseline, 
  Container, 
  Typography, 
  Button, 
  Grid2, 
  Chip, 
  Skeleton,
  CircularProgress 
} from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { updateCampaign } from '../../api/campaignsApi';
import PaidInfluencerCampaignDetailCreator from '../../components/widgets/creators/campaigns/PaidInfluencers/CampaignDetails';
import e from 'cors';
import { createApplication } from '../../api/applicationApi';

const CreatorCampaignDetail = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [placementData, setPlacementData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [applicationNumber, setApplicationNumber] = useState(0);
  const user_type = localStorage.getItem('user_type');
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [jobs, setJobs] = useState([]);
  const { jobId } = useParams();
  console.log(jobId);

  useEffect(() => {
    fetchCampaignDetails();
    getApplication();
    getPlacements();
  }, []);


  const renderField = (label, value) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1">{value || '-'}</Typography>
    </Box>
  );

  useEffect(() => {
 console.log("PlacementData",placementData)
  }, [placementData]);

  const fetchCampaignDetails = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/campaign/list?jobId=${jobId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log(response.data.jobs[0]);
        setCampaignDetails(response.data.jobs[0] || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlacements = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/placement/list',
          { campaignId: jobId },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        
        setPlacementData(response.data.placements || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getApplication = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const accessToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/application/creator-applications?creatorId=${user.uid}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const applications = response.data.applications || [];
        const filteredApplications = applications.filter(application => application.campaignId === jobId);
        setApplicationNumber(filteredApplications.length);
        setApplicationData(filteredApplications); 
        console.log("FilteredApplications", filteredApplications)
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setApplicationNumber(0);
    } finally {
      setIsLoading(false);
    }
  };


  const handleSubmitApplication = async  => {
    handleCreateApplication();
  }

  const handleCreateApplication = async (e) => {
    setIsPublishLoading(true)
  
    const applicationData = {
      applications: {
        campaignId: jobId,
        campaignName: campaignDetails.campaignName,
        status: 'Pending',
      },
      }
    
    try {
      await createApplication(applicationData);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      await fetchCampaignDetails();
      setIsPublishLoading(false);
    }
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      {isLoading ? (
         <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '90%', ml: 10, mt: 20 }}>
         <CircularProgress />
       </Grid2> 
      ) : (
        <>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Container maxWidth="xxl">
              
              <Box>
                <ProfileAvatar />
           </Box>

                {/* Title and Buttons */}
  
               <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        
                              <Button
                                variant="contained"
                                onClick={handleCreateApplication}
                                sx={{ borderRadius: '10px' }}
                                disabled={isPublishLoading}
                              >
                          Apply
                              </Button>
                        

                </Box>
                {/* Campaign Name and Advertiser Name */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4 }}>
               {campaignDetails.jobType === 'Paid Influencers' ? (
               
               <PaidInfluencerCampaignDetailCreator 
               campaignData={campaignDetails}
               placements={placementData}
               applications={applicationData}
               handleSubmitApplication={handleSubmitApplication}
 />
               
               ) : ( null )}


                 
        
            
                </Grid2>

                        
            </Container>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreatorCampaignDetail;
