// CreatorProfilePage.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  CircularProgress,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Paper,
  Chip
} from '@mui/material';
import Sidebar from '../../../components/navigation/MainNav';
import ProfileAvatar from '../../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import CreatorDetailCard from '../../../components/widgets/creators/ProfileWidget';


const CreatorProfilePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout');
    } else {
      getUser();
    }
  }, [navigate]);

  const getUser = async () => {
    try {
      if (user) {
        const accessToken = await user.getIdToken();
        const userId = user.uid;
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/user/getUser?userId=${userId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUserData(response.data.result || {});
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handlers for connecting social platforms (for now they log to the console)
  const handleConnectFacebook = () => {
    // Replace this with your Facebook integration logic (e.g., open a popup or redirect)
    console.log('Connecting to Facebook...');
  };


  const handleConnectInstagram = () => {
    console.log('Connecting to Instagram...');
  };

  const handleConnectSnapchat = () => {
    console.log('Connecting to Snapchat...');
  };

  const handleConnectTikTok = () => {
    console.log('Connecting to TikTok...');
  };

  const handleConnectYouTube = () => {
    console.log('Connecting to YouTube...');
  };

  const handleFacebookData = (data) => {
    console.log('Retrieved Facebook Data:', data);
    // Here you can store the data in your state or send it to your backend
  };



  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          {/* Profile Header */}
          <Box display="flex" alignItems="center" mb={4}>
            <ProfileAvatar />
            <Box ml={2}>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {userData.fullName || userData.username || 'Your Name'}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {userData.email || 'youremail@example.com'}
              </Typography>
            </Box>
          </Box>

          {/* Basic Details Section */}
          <Box mb={4}>
          <CreatorDetailCard userData={userData} />
          </Box>

          {/* Social Connect Section */}
          <Box mb={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Connect Your Social Platforms
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4} md={2}>
                  <Button
                      variant="contained"
                      fullWidth
                      onClick={handleConnectFacebook}
                    >
                      Facebook
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleConnectInstagram}
                    >
                      Instagram
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleConnectSnapchat}
                    >
                      Snapchat
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleConnectTikTok}
                    >
                      TikTok
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleConnectYouTube}
                    >
                      YouTube
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          {/* Existing Creator Detail Card */}
          <Box mb={4}>
           
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CreatorProfilePage;
