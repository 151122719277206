import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Avatar,
  Checkbox,
  Button
} from '@mui/material';
import { StatusDot } from '../../../config/status';
import { useNavigate } from 'react-router-dom';

const dummyCreators = [
  { 
    id: '1', 
    name: 'Alice Johnson', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Active',
    followers: '26k',
    totalCost: '$500',
    eCPV: '0.04',
    eCPE: '0.24'
  },
  { 
    id: '2', 
    name: 'Bob Smith', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Active',
    followers: '45k',
    totalCost: '$750',
    eCPV: '0.05',
    eCPE: '0.20'
  },
  { 
    id: '3', 
    name: 'Carol Williams', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Inactive',
    followers: '10k',
    totalCost: '$300',
    eCPV: '0.06',
    eCPE: '0.18'
  },
  { 
    id: '4', 
    name: 'David Brown', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Active',
    followers: '32k',
    totalCost: '$650',
    eCPV: '0.03',
    eCPE: '0.25'
  },
  { 
    id: '5', 
    name: 'Evelyn Davis', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Active',
    followers: '58k',
    totalCost: '$900',
    eCPV: '0.02',
    eCPE: '0.30'
  },
  { 
    id: '6', 
    name: 'Frank Miller', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Inactive',
    followers: '14k',
    totalCost: '$200',
    eCPV: '0.07',
    eCPE: '0.22'
  },
  { 
    id: '7', 
    name: 'Grace Wilson', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Active',
    followers: '39k',
    totalCost: '$600',
    eCPV: '0.04',
    eCPE: '0.21'
  },
  { 
    id: '8', 
    name: 'Henry Moore', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Active',
    followers: '65k',
    totalCost: '$550',
    eCPV: '0.03',
    eCPE: '0.19'
  },
  { 
    id: '9', 
    name: 'Isabella Taylor', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Active',
    followers: '50k',
    totalCost: '$800',
    eCPV: '0.05',
    eCPE: '0.23'
  },
  { 
    id: '10', 
    name: 'Jack Anderson', 
    avatar: 'https://via.placeholder.com/40', 
    status: 'Active',
    followers: '29k',
    totalCost: '$1000',
    eCPV: '0.06',
    eCPE: '0.20'
  }
];

const RecommendedCreators = () => {
  const navigate = useNavigate();
  // State to store the selected creators' IDs
  const [selectedCreators, setSelectedCreators] = useState([]);

  const handleSelectCreator = (creatorId) => {
    setSelectedCreators((prev) => {
      if (prev.includes(creatorId)) {
        return prev.filter(id => id !== creatorId);
      } else {
        return [...prev, creatorId];
      }
    });
  };

  const handleInviteCreators = () => {
    // Here you can send selectedCreators along with campaignId to your API.
    console.log('Inviting creators with IDs:', selectedCreators);
    // For example:
    // inviteCreatorsAPI(campaignId, selectedCreators);
  };

  return (
    <Box>
         <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={handleInviteCreators}>
          Invite Selected Creators
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table aria-label="Recommended Creators Table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Select</strong></TableCell>
              <TableCell><strong>Avatar</strong></TableCell>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Followers</strong></TableCell>
  
              <TableCell><strong>Forecast eCPV</strong></TableCell>
              <TableCell><strong>Forecast eCPE</strong></TableCell>
              <TableCell><strong>Buying Indicator</strong></TableCell>
              <TableCell><strong>Invite</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyCreators.map((creator) => (
              <TableRow key={creator.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedCreators.includes(creator.id)}
                    onChange={() => handleSelectCreator(creator.id)}
                  />
                </TableCell>
                <TableCell>
                  <Avatar src={creator.avatar} alt={creator.name} />
                </TableCell>
                <TableCell>{creator.name}</TableCell>
                <TableCell>{creator.followers}</TableCell>
               
                <TableCell>${creator.eCPV}</TableCell>
                <TableCell>${creator.eCPE}</TableCell>
                <TableCell>
                  <StatusDot status="good" />
                </TableCell>
                <TableCell> <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={() => {
                                          // Placeholder: accept action
                                          console.log('Invite', creator);
                                        }}
                                      >
                                        Invite
                                      </Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Box>
  );
};

export default RecommendedCreators;
