import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
import { DateRangePicker } from '@mui/lab';
import Slider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import { createCampaign, updateCampaign } from '../../../api/campaignsApi';
import { createBrand } from '../../../api/brandApi';
import { getAuth } from 'firebase/auth';
import { productSeedingCampaignData } from '../../../config/campaignType';

const ProductSeedingStepper = ({ brands = [], jobType, refreshBrands }) => {
  const navigate = useNavigate();

  // Stepper state
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const steps = [
    'Overview',
    'Target Audience',
    'Influencer Type',
    'Deliverables',
    'Exclusivity',
    'Campaign Expectations'
  ];
  const [completed, setCompleted] = useState({});
  const [campaignId, setCampaignId] = useState(null);
  const [inPerpetuity, setInPerpetuity] = useState(false);
  const [brandData, setBrandData] = useState({
    company_name: '',
    company_URL: '',
    company_about: ''
  });
  const [sliderValue, setSliderValue] = useState(0);
  const [postDateTimeline, setPostDateTimeline] = useState([null, null]);
  const [newBrandModalOpen, setNewBrandModalOpen] = useState(false);

  // Initial state for all campaign fields relevant to Paid Influencers
  const [campaignData, setCampaignData] = useState(productSeedingCampaignData);
    
  // Simple helper to update state for exclusive selections
  const handleExclusiveChange = (key, option) => {
    setCampaignData(prev => ({
      ...prev,
      [key]: prev[key] === option ? '' : option
    }));
  };

  // Simulated auto-save function (replace with your API call)
  const saveStep = async (step) => {
    if (!campaignId) {
      const newCampaign = await createCampaign(campaignData);
      setCampaignId(newCampaign);
    } else {
      await updateCampaign(campaignId, campaignData);
    }
    return new Promise((resolve) => setTimeout(() => resolve(true), 500));
  };

  // Handler for Next button
  const handleNext = async () => {
    setIsLoading(true);
    const saved = await saveStep(activeStep);
    if (saved) {
      setCompleted(prev => ({ ...prev, [activeStep]: true }));
      if (activeStep === steps.length - 1) {
        navigate(`/campaigns/detail/${campaignId}`);
        return;
      }
      setIsLoading(false);
      setActiveStep(prev => prev + 1);
    }
  };

  // Handler for Back button
  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  // Handler for slider change
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  // Handler for advertiser selection
  const handleAdvertiserChange = (e) => {
    const value = e.target.value;
    if (value === 'newBrand') {
      setNewBrandModalOpen(true);
    } else {
      setCampaignData(prev => ({ ...prev, advertiser: value }));
    }
  };

  // Render content for each step
  const renderStepContent = (step) => {
    switch (step) {
      case 0: // Overview
        return (
          <Box>
            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">
              Overview
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="advertiser-label">Advertiser</InputLabel>
              <Select
                labelId="advertiser-label"
                label="Brand"
                value={campaignData.advertiser}
                onChange={handleAdvertiserChange}
              >
                <MenuItem value="">
                  <em>Select Brand</em>
                </MenuItem>
                <MenuItem value="newBrand">New Brand</MenuItem>
                {brands.map((brand) => (
                  <MenuItem key={brand._id} value={brand._id}>
                    {brand.company_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Product Information"
              value={campaignData.productInformation}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, productInformation: e.target.value }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Name"
              value={campaignData.campaignName}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, campaignName: e.target.value }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Summary"
              value={campaignData.campaignSummary}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, campaignSummary: e.target.value }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Objective"
              value={campaignData.campaignObjective}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, campaignObjective: e.target.value }))
              }
            />
            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">
              Campaign Usage Term
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={campaignData.startDate}
              disabled={inPerpetuity}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, startDate: e.target.value }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={campaignData.endDate}
              disabled={inPerpetuity}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, endDate: e.target.value }))
              }
            />
     
          </Box>
        );
      case 1: // Target Audience
        return (
          <Box>
                     <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
                       Age Range
                     </Typography>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                       {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map((age) => {
                         const isSelected =
                           campaignData.targetAudienceAge &&
                           campaignData.targetAudienceAge.includes(age);
                         return (
                           <Chip
                             key={age}
                             label={age}
                             clickable
                             onClick={() => {
                               const current =
                                 campaignData.targetAudienceAge || [];
                               const newSelection = isSelected
                                 ? current.filter((a) => a !== age)
                                 : [...current, age];
                               setCampaignData({
                                 ...campaignData,
                                 targetAudienceAge: newSelection,
                               });
                             }}
                             sx={{
                               mr: 1,
                               my: 1,
                               backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                               color: isSelected ? '#ffffff' : 'inherit',
                             }}
                           />
                         );
                       })}
                     </Box>
                   
                     <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
                       Gender
                     </Typography>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                       {['Female', 'Male', 'Non-binary'].map((gender) => {
                         const isSelected =
                           campaignData.targetAudienceGender &&
                           campaignData.targetAudienceGender.includes(gender);
                         return (
                           <Chip
                             key={gender}
                             label={gender}
                             clickable
                             onClick={() => {
                               const current =
                                 campaignData.targetAudienceGender || [];
                               const newSelection = isSelected
                                 ? current.filter((g) => g !== gender)
                                 : [...current, gender];
                               setCampaignData({
                                 ...campaignData,
                                 targetAudienceGender: newSelection,
                               });
                             }}
                             sx={{
                               mr: 1,
                               my: 1,
                               backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                               color: isSelected ? '#ffffff' : 'inherit',
                             }}
                           />
                         );
                       })}
                     </Box>
                   
                     <Typography variant="subtitle1" marginTop={2}>
                       <strong>Audience Primary Location</strong> - choose one
                     </Typography>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                       {[
                         'United States',
                         'China',
                         'India',
                         'United Kingdom',
                         'Germany',
                         'France',
                         'Canada',
                         'Australia',
                         'Italy',
                         'Brazil',
                       ].map((locA) => {
                         const isSelected =
                           campaignData.targetPrimaryAudienceLocation === locA;
                         return (
                           <Chip
                             key={locA}
                             label={locA}
                             clickable
                             onClick={() =>
                               setCampaignData({
                                 ...campaignData,
                                 targetPrimaryAudienceLocation:
                                   isSelected ? '' : locA,
                               })
                             }
                             sx={{
                               mr: 1,
                               my: 1,
                               backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                               color: isSelected ? '#ffffff' : 'inherit',
                             }}
                           />
                         );
                       })}
                     </Box>
                   
                     <Typography variant="subtitle1" marginTop={2}>
                       <strong>Audience Secondary Location</strong> - can choose multiple
                     </Typography>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                       {[
                         'United States',
                         'China',
                         'India',
                         'United Kingdom',
                         'Germany',
                         'France',
                         'Canada',
                         'Australia',
                         'Italy',
                         'Brazil',
                       ].map((locB) => {
                         const isSelected =
                           campaignData.targetSecondaryAudienceLocation &&
                           campaignData.targetSecondaryAudienceLocation.includes(locB);
                         return (
                           <Chip
                             key={locB}
                             label={locB}
                             clickable
                             onClick={() => {
                               const current =
                                 campaignData.targetSecondaryAudienceLocation || [];
                               const newSelection = isSelected
                                 ? current.filter((l) => l !== locB)
                                 : [...current, locB];
                               setCampaignData({
                                 ...campaignData,
                                 targetSecondaryAudienceLocation: newSelection,
                               });
                             }}
                             sx={{
                               mr: 1,
                               my: 1,
                               backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                               color: isSelected ? '#ffffff' : 'inherit',
                             }}
                           />
                         );
                       })}
                     </Box>
                   </Box>
        );
      case 2: // Influencer Type
        return (
          <Box>
     
          <Typography variant="subtitle1" fontWeight="bold">Audience Size = {sliderValue}</Typography>
         
         
         <Slider
           value={sliderValue}
           onChange={handleSliderChange}
           min={0}
           max={1000000}
           step={5000}
           marks={[
             { value: 0, label: '0' },
             { value: 1000000, label: '1M+' },
           ]}
           valueLabelDisplay="auto"
   />

<Typography variant="subtitle1" marginTop={2} fontWeight="bold">
 Influencer Age Range
</Typography>
<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
 {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map((age) => {
   const isSelected =
     campaignData.influencerAgeRange &&
     campaignData.influencerAgeRange.includes(age);
   return (
     <Chip
       key={age}
       label={age}
       clickable
       onClick={() => {
         const current = campaignData.influencerAgeRange || [];
         const newSelection = isSelected
           ? current.filter((a) => a !== age)
           : [...current, age];
         setCampaignData({
           ...campaignData,
           influencerAgeRange: newSelection,
         });
       }}
       sx={{
         mr: 1,
         my: 1,
         backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
         color: isSelected ? '#ffffff' : 'inherit',
       }}
     />
   );
 })}
</Box>

{/* Influencer Gender (Multi-Select) */}
<Typography variant="subtitle1" marginTop={2} fontWeight="bold">
 Influencer Gender
</Typography>
<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
 {['Female', 'Male', 'Non-binary'].map((gender) => {
   const isSelected =
     campaignData.influencerGender &&
     campaignData.influencerGender.includes(gender);
   return (
     <Chip
       key={gender}
       label={gender}
       clickable
       onClick={() => {
         const current = campaignData.influencerGender || [];
         const newSelection = isSelected
           ? current.filter((g) => g !== gender)
           : [...current, gender];
         setCampaignData({
           ...campaignData,
           influencerGender: newSelection,
         });
       }}
       sx={{
         mr: 1,
         my: 1,
         backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
         color: isSelected ? '#ffffff' : 'inherit',
       }}
     />
   );
 })}
</Box>

{/* Influencer Location (Multi-Select) */}
<Typography variant="subtitle1" marginTop={2} fontWeight="bold">
 Influencer Location
</Typography>
<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
 {['United States', 'China', 'India', 'United Kingdom', 'Germany', 'France', 'Canada', 'Australia', 'Italy', 'Brazil'].map((loc) => {
   const isSelected =
     campaignData.influencerLocation &&
     campaignData.influencerLocation.includes(loc);
   return (
     <Chip
       key={loc}
       label={loc}
       clickable
       onClick={() => {
         const current = campaignData.influencerLocation || [];
         const newSelection = isSelected
           ? current.filter((l) => l !== loc)
           : [...current, loc];
         setCampaignData({
           ...campaignData,
           influencerLocation: newSelection,
         });
       }}
       sx={{
         mr: 1,
         my: 1,
         backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
         color: isSelected ? '#ffffff' : 'inherit',
       }}
     />
   );
 })}

</Box>

{/* Influencer Category / Niche (Multi-Select) */}
<Typography variant="subtitle1" marginTop={2}>
 Influencer Category / Niche
</Typography>
<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
 {[
   'Fashion', 'Beauty', 'Tech', 'Food', 'Travel', 'Lifestyle',
   'Fitness', 'Health & Wellness', 'Gaming', 'Music', 'Comedy', 'DIY & Crafts',
   'Home Decor', 'Parenting', 'Photography', 'Art', 'Design', 'Finance',
   'Business', 'Marketing', 'Education', 'Sports', 'Automotive', 'Nature',
   'Pets', 'Sustainability', 'Environmental', 'Science', 'Books', 'Film',
   'Entertainment', 'Luxury', 'Travel & Adventure', 'Food & Beverage', 'Cooking',
   'Gardening', 'Architecture', 'Relationships', 'Spirituality', 'Mental Health',
   'Animation', 'Comedy & Memes', 'DIY Home Improvement', 'Personal Development',
   'Productivity', 'Photography & Videography', 'Social Media Tips', 'Cryptocurrency',
   'E-Sports', 'Virtual Reality'
 ].map((cat) => {
   const isSelected =
     campaignData.influencerCategory &&
     campaignData.influencerCategory.includes(cat);
   return (
     <Chip
       key={cat}
       label={cat}
       clickable
       onClick={() => {
         const current = campaignData.influencerCategory || [];
         const newSelection = isSelected
           ? current.filter((c) => c !== cat)
           : [...current, cat];
         setCampaignData({
           ...campaignData,
           influencerCategory: newSelection,
         });
       }}
       sx={{
         flex: '0 0 calc(100%/10 - 8px)',
         mr: 1,
         my: 1,
         backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
         color: isSelected ? '#ffffff' : 'inherit',
       }}
     />
   );
 })}
</Box></Box>
        );
      case 3: // Deliverables
        return (
          <Box>
          <Typography
                             variant="subtitle1"
                             marginTop="30px"
                             fontWeight="bold"
                           >
                             Gifting Type
                           </Typography>
                           <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                             {['Gifting Only', 'Gifting in Exchange for Content'].map(
                               (gift) => {
                                 const isSelected = campaignData.giftingType === gift;
                                 return (
                                   <Chip
                                     key={gift}
                                     label={gift}
                                     clickable
                                     onClick={() =>
                                       setCampaignData({
                                         ...campaignData,
                                         giftingType: gift,
                                       })
                                     }
                                     sx={{
                                       mr: 1,
                                       my: 1,
                                       backgroundColor: isSelected
                                         ? '#11ba82'
                                         : '#c7c7c7',
                                       color: isSelected ? '#ffffff' : 'inherit',
                                     }}
                                   />
                                 );
                               }
                             )}
                           </Box>
                             <Typography
                                               variant="subtitle1"
                                               marginTop="30px"
                                               fontWeight="bold"
                                             >
                                               Preferred Platforms
                                             </Typography>
                                             <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                               {['Instagram', 'Meta', 'TikTok', 'Youtube', 'Snap'].map(
                                                 (pref) => {
                                                   const isSelected =
                                                     campaignData.preferredPlatforms &&
                                                     campaignData.preferredPlatforms.includes(pref);
                                                   return (
                                                     <Chip
                                                       key={pref}
                                                       label={pref}
                                                       clickable
                                                       onClick={() => {
                                                         const current =
                                                           campaignData.preferredPlatforms || [];
                                                         const newSelection = isSelected
                                                           ? current.filter((p) => p !== pref)
                                                           : [...current, pref];
                                                         setCampaignData({
                                                           ...campaignData,
                                                           preferredPlatforms: newSelection,
                                                         });
                                                       }}
                                                       sx={{
                                                         mr: 1,
                                                         my: 1,
                                                         backgroundColor: isSelected
                                                           ? '#11ba82'
                                                           : '#c7c7c7',
                                                         color: isSelected ? '#ffffff' : 'inherit',
                                                       }}
                                                     />
                                                   );
                                                 }
                                               )}
                                             </Box>
                                   
                                             <Typography
                                               variant="subtitle1"
                                               fontWeight="bold"
                                               marginTop="30px"
                                             >
                                               Campaign Objective
                                             </Typography>
                                             <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                               {[
                                                 'Brand Awareness',
                                                 'Product Testimonial',
                                                 'Product Efficacy',
                                                 'Oranic Content',
                                                 'Social Proof',
                                                 'Traffic & Engagement',
                                                 'Create Hype',
                                               ].map((obj) => {
                                                 const isSelected =
                                                   campaignData.placementObjective &&
                                                   campaignData.placementObjective.includes(obj);
                                                 return (
                                                   <Chip
                                                     key={obj}
                                                     label={obj}
                                                     clickable
                                                     onClick={() => {
                                                       const current =
                                                         campaignData.placementObjective || [];
                                                       const newSelection = isSelected
                                                         ? current.filter((o) => o !== obj)
                                                         : [...current, obj];
                                                       setCampaignData({
                                                         ...campaignData,
                                                         placementObjective: newSelection,
                                                       });
                                                     }}
                                                     sx={{
                                                       mr: 1,
                                                       my: 1,
                                                       backgroundColor: isSelected
                                                         ? '#11ba82'
                                                         : '#c7c7c7',
                                                       color: isSelected ? '#ffffff' : 'inherit',
                                                     }}
                                                   />
                                                 );
                                               })}
                                             </Box>
                                   
                                             <Typography
                                               variant="subtitle1"
                                               fontWeight="bold"
                                               marginTop="30px"
                                             >
                                               Content Usage
                                             </Typography>
                                   
                                             <Typography variant="subtitle1" marginTop="30px">
                                               Republish on Brand Social
                                             </Typography>
                                             <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                               {['Yes', 'No'].map((social) => {
                                                 const isSelected =
                                                   campaignData.contentUsage &&
                                                   campaignData.contentUsage.republishOnBrandSocial === social;
                                                 return (
                                                   <Chip
                                                     key={social}
                                                     label={social}
                                                     clickable
                                                     onClick={() =>
                                                       setCampaignData((prevData) => ({
                                                         ...prevData,
                                                         contentUsage: {
                                                           ...prevData.contentUsage,
                                                           republishOnBrandSocial: social,
                                                         },
                                                       }))
                                                     }
                                                     sx={{
                                                       mr: 1,
                                                       my: 1,
                                                       backgroundColor: isSelected
                                                         ? '#11ba82'
                                                         : '#c7c7c7',
                                                       color: isSelected ? '#ffffff' : 'inherit',
                                                     }}
                                                   />
                                                 );
                                               })}
                                             </Box>
                                   
                                             <Typography variant="subtitle1" marginTop="30px">
                                               Republish on Brand Website
                                             </Typography>
                                             <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                               {['Yes', 'No'].map((website) => {
                                                 const isSelected =
                                                   campaignData.contentUsage &&
                                                   campaignData.contentUsage.republishOnBrandWebsite === website;
                                                 return (
                                                   <Chip
                                                     key={website}
                                                     label={website}
                                                     clickable
                                                     onClick={() =>
                                                       setCampaignData((prevData) => ({
                                                         ...prevData,
                                                         contentUsage: {
                                                           ...prevData.contentUsage,
                                                           republishOnBrandWebsite: website,
                                                         },
                                                       }))
                                                     }
                                                     sx={{
                                                       mr: 1,
                                                       my: 1,
                                                       backgroundColor: isSelected
                                                         ? '#11ba82'
                                                         : '#c7c7c7',
                                                       color: isSelected ? '#ffffff' : 'inherit',
                                                     }}
                                                   />
                                                 );
                                               })}
                                             </Box>

                       
          </Box>
        );
      case 4: 
        return (
          <Box>
            <FormControl component="fieldset" margin="normal">
              <Typography variant="subtitle1" fontWeight="bold">
                Category Exclusivity
              </Typography>
             
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Yes', 'No'].map((exlusivity) => {
      const isSelected =
        campaignData.categoryExclusivity &&
        campaignData.categoryExclusivity.includes(exlusivity);
      return (
        <Chip
        exclusive
          key={exlusivity}
          label={exlusivity}
          clickable
          onClick={() => {
            const current = campaignData.categoryExclusivity || [];
            const newSelection = isSelected
              ? current.filter((a) => a !== exlusivity)
              : [...current, exlusivity];
            setCampaignData({
              ...campaignData,
              categoryExclusivity: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Competitor Exclusivity (list specific brands)"
              value={campaignData.competitorExclusivity || ''}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  competitorExclusivity: e.target.value,
                })
              }
            />
            <FormControl fullWidth margin="normal">

            <Typography variant="subtitle1" fontWeight="bold">
                Territory Exclusivity
              </Typography>
             
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Country', 'Region', 'Global'].map((exlusivityT) => {
      const isSelected =
        campaignData.territoryExclusivity &&
        campaignData.territoryExclusivity.includes(exlusivityT);
      return (
        <Chip
        exclusive
          key={exlusivityT}
          label={exlusivityT}
          clickable
          onClick={() => {
            const current = campaignData.territoryExclusivity || [];
            const newSelection = isSelected
              ? current.filter((a) => a !== exlusivityT)
              : [...current, exlusivityT];
            setCampaignData({
              ...campaignData,
              territoryExclusivity: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>



            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              type="number"
              label="Exclusivity Period in Months"
              value={campaignData.exclusivityPeriod || ''}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  exclusivityPeriod: e.target.value,
                })
              }
            />
          </Box>
        );
      case 5: // Campaign Expectations
        return (
          <Box>
            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">
              Campaign Expectations
            </Typography>
           <Typography variant="subtitle1" marginTop="16px">
               Remuneration
             </Typography>
             <TextField
                         fullWidth
                         margin="normal"
                         label="Value of Product"
                         type="number"
                         value={campaignData.productValue}
                         onChange={(e) =>
                           setCampaignData({
                             ...campaignData,
                             productValue: e.target.value,
                           })
                         }
                       />
           
            <Box>
              <Typography variant="subtitle1">Paid Disclosure</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.paidDisclosure === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, paidDisclosure: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">Brand Tagging</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.brandTagging === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, brandTagging: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">Include Shoppable Links</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.shoppableLinks === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, shoppableLinks: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">
                Interactivity (Polls, Surveys, Q+As)
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.interactivity === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, interactivity: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <TextField
              fullWidth
              margin="normal"
              label="Other / Final Comments"
              multiline
              rows={4}
              value={campaignData.finalComments || ''}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, finalComments: e.target.value }))
              }
            />
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel>
              {completed[index] ? <span>&#10003;</span> : label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button 
        variant="contained" 
        disabled={isLoading}
        onClick={handleNext}>
          
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>

      {/* New Brand Modal */}
      <Dialog open={newBrandModalOpen} onClose={() => setNewBrandModalOpen(false)}>
        <DialogTitle>Create New Brand</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the new brand details below.</DialogContentText>
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            name="company_name"
            value={brandData.company_name}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_name: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
          <TextField
            label="Company URL"
            variant="outlined"
            fullWidth
            name="company_URL"
            value={brandData.company_URL}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_URL: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
          <TextField
            label="Company About"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="company_about"
            value={brandData.company_about}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_about: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewBrandModalOpen(false)}>Cancel</Button>
          <Button onClick={async () => {
            try {
              const auth = getAuth();
              const user = auth.currentUser;
              if (user) {
                const idToken = await user.getIdToken();
                await createBrand(brandData, idToken);
              }
            } catch (error) {
              console.error('Error creating brand:', error);
            } finally {
              await refreshBrands();
              setNewBrandModalOpen(false);
            }
          }}>Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductSeedingStepper;
