import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CssBaseline,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button, 
  TextField,
  Skeleton
} from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import Sidebar from '../../../components/navigation/MainNav';
import ProfileAvatar from '../../../components/navigation/profileAvatar';
import { contractText } from './contract/template';
import TasksOverview from '../../../components/widgets/tasks/TasksOverview';
import CreatorFilesSummaryCards from '../../../components/widgets/creators/FilesSummaryTable';

const CreatorsJobDetailPage = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState({});
  const [campaignDetails, setCampaignDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [contractModalOpen, setContractModalOpen] = useState(false); // Track modal state
  const [comment, setComment] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [taskData, setTaskData] = useState([]);
  const [acceptContractId, setAcceptContractId] = useState(null);
  const [contractSubmitLoading, setContractSubmitLoading] = useState(false);

  useEffect(() => {
    fetchJobDetails(jobId);
    fetchTasks(jobId);
  }, [jobId]);

  const fetchJobDetails = async (jobId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const accessToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/job/get?jobId=${jobId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setJobDetails(response.data.job || {});
        const campaignId = response.data.job.campaignId;
        const contractStatus = response.data.job.contractStatus;
        if (contractStatus !== 'Signed') {
          setContractModalOpen(true); // Open modal if contract is not signed
        }

        await fetchCampaignDetails(campaignId);
      }
    } catch (error) {
      console.error('Error fetching job:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCampaignDetails = async (campaignId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/campaign/list?jobId=${campaignId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setCampaignDetails(response.data.jobs[0] || {});
      }
    } catch (error) {
      console.error('Error fetching campaign:', error);
    }
  };

  const fetchTasks = async (jobId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const accessToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/tasks/get?jobId=${jobId}&type=Creator`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log('Tasks:', response.data.tasks);
        const tasks = response.data.tasks || [];
       

        const acceptContractTask = tasks.find(task => 
          task.taskName?.trim()?.toLowerCase() === 'accept contract'
        );
    
        // Get the _id if found
        const acceptContractId = acceptContractTask?._id;
        console.log('Accept Contract ID:', acceptContractId);
        
        // If you need to use this ID elsewhere, consider state:
        setAcceptContractId(acceptContractId || null);
  
      setTaskData(tasks);
    }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleAcceptContract = async () => {
    const update = {
      contractStatus: "Signed",
    }
    setContractSubmitLoading(true);
    await updateJob(update);
    await completeContract();
    console.log('Contract Accepted');
    setContractModalOpen(false);
    setContractSubmitLoading(true);
    // Optionally update backend
  };

  const handleDeclineContract = async () => {
    const update = {
      contractStatus: "Declined",
    }
    await updateJob(update);
    navigate('/creators/jobs');
    console.log('Contract Declined');
    setContractModalOpen(false);
    // Optionally update backend
  };

  const handleSubmitComment = async (comment) => {
    const update = {
      contactStatus: "Review",
      contractComment: comment,
    }
    await updateJob(update);

    console.log('Comment Submitted:', comment);
    setComment('');
    setContractModalOpen(false);
    // Optionally update backend
  };

const updateJob = async (update) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.put(
        `https://ostendo.nucleotto.com/api/job/update?jobId=${jobId}`,
        { update },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
     }}  
  catch (error) {
    console.error('Error updating Job:', error);
  }
  await fetchJobDetails(jobId);
};

const completeContract = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const taskId = acceptContractId
    const update = {
      status: 'Completed',
      taskId: taskId,
    };
   
    if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.post(
        `https://ostendo.nucleotto.com/api/tasks/update`,
        update,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      if (response.status === 200) {
      await fetchTasks(jobId);
    }
  }}  
  catch (error) {
    console.error('Error updating Job:', error);
  }
};


const openContractFromTask = () => {  
  setContractModalOpen(true);
};


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Typography variant="h4" gutterBottom>
              Job Details
            </Typography>
            <Typography variant="h6">Campaign Name: {jobDetails.campaignName}</Typography>
            <Typography variant="body1">Total Agreed Rate: ${jobDetails.totalAgreedRate}</Typography>
            <Typography variant="body1">Offer Status: {jobDetails.offerStatus}</Typography>
            <Typography variant="body1">
              Application Date: {new Date(jobDetails.applicationDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body1">Status: {jobDetails.status}</Typography>
            <Typography variant="body1">Contract: {jobDetails.contractStatus}</Typography>


            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Placements Applied To
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Placement ID</strong></TableCell>
                    <TableCell><strong>Proposed Fee</strong></TableCell>
                    <TableCell><strong>Status</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobDetails.placementsAppliedTo && jobDetails.placementsAppliedTo.map((placement) => (
                    <TableRow key={placement.placementId}>
                      <TableCell>{placement.placementId}</TableCell>
                      <TableCell>${placement.proposedFee}</TableCell>
                      <TableCell>{placement.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Tasks
            </Typography>
            <TasksOverview 
            taskData={taskData}
            openContract={() => openContractFromTask()}
            refreshTasks={() => fetchTasks(jobId)}
            jobId={jobId}
            
            
            />
              <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                        Files
                        </Typography>
                <CreatorFilesSummaryCards jobDetails={jobDetails} />

          </Box>

  <Dialog open={contractModalOpen} onClose={() => setContractModalOpen(false)} maxWidth="md" fullWidth>
  <DialogTitle>Contract Agreement</DialogTitle>
  <DialogContent dividers sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
     
    <Typography variant="body1" gutterBottom>
    {contractSubmitLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200} // Adjust the height as needed
          sx={{
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            fontSize: '14px',
            lineHeight: '1.5',
            padding: '10px',
          }}
        />
      ) : (
      <pre style={{
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        fontSize: '14px',
        lineHeight: '1.5',
        padding: '10px'
      }}>
        {contractText}
      </pre>
          )}
    </Typography>
    
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'space-between' }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Button onClick={() => handleDeclineContract()} color="error" variant="contained">
        Decline
      </Button>
      <Button onClick={handleAcceptContract} color="primary" variant="contained">
        Accept
      </Button>
    </Box>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <TextField
        label="Add a Comment"
        variant="outlined"
        size="small"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        sx={{ width: '100%' }}
      />
      <Button onClick={handleSubmitComment} variant="contained" color="secondary">
        Submit Comment
      </Button>
    </Box>
  </DialogActions>
</Dialog>        </Container>
      </Box>
    </Box>
  );
};

export default CreatorsJobDetailPage;
