import React from 'react';
import { Box, Paper, 
    Typography,  
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Avatar,
     } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const StatusDot = ({ status }) => {
    // Determine dot color based on the status or another influencer property
    let color = 'grey';
    if (status === 'good') {
      color = 'green';
    } else if (status === 'warning') {
      color = 'orange'; // amber can be represented by orange
    } else if (status === 'bad') {
      color = 'red';
    }
    return (
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: color,
        }}
      />
    );
  };

const InvitedCreatorTable = ({ influencers }) => {
 return (
<Box>
<Paper sx={{ p:2, mb: 2 }}>
 <Typography variant="h5">Creators Invited</Typography>
 <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
      <TableCell><strong></strong></TableCell>
        <TableCell><strong>Name</strong></TableCell>
        <TableCell><strong>Followers</strong></TableCell>
  
        <TableCell><strong>Forecast eCPV</strong></TableCell>
        <TableCell><strong>Forecast eCPE</strong></TableCell>
        <TableCell><strong>Total Cost</strong></TableCell>
        <TableCell><strong>Buying Indicator</strong></TableCell>
        <TableCell><strong>Status</strong></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
    {influencers
    .filter((influencer) => influencer.status === 'Invited')
    .map((influencer) => (
        <TableRow key={influencer._id}>
             <TableCell><Avatar /></TableCell>
          <TableCell>{influencer.name}</TableCell>
          <TableCell>{influencer.followers}</TableCell>
   
          <TableCell>${influencer.eCPV}</TableCell>
          <TableCell>${influencer.eCPE}</TableCell>
          <TableCell><strong>Total Cost</strong></TableCell>
          <TableCell>
                      <StatusDot status="good" />
                    </TableCell>
          <TableCell>{influencer.status}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
 </Paper>
</Box>

)};

export default InvitedCreatorTable;