// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h3: {
      fontWeight: 'bold',
      marginBottom: '24px',
    },
    body1: {
      marginTop: '16px',
    },
    body2: {
      marginTop: '16px',
    },
  },
  components: {
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: '#888888', // default icon colour
        '&.Mui-active': {
          color: '#11ba82 !important', // active icon colour
        },
        '&.Mui-completed': {
          color: '#11ba82', // completed icon colour
        },
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        color: '#11ba82', // This sets the primary colour for the slider.
      },
      thumb: {
        color: '#11ba82', // Colour of the slider thumb
      },
      track: {
        color: '#11ba82', // Colour of the slider track
      },
      rail: {
        color: '#888888', // Colour of the inactive rail
      },
      mark: {
        color: '#11ba82', // Colour for the marks on the slider
      },
      valueLabel: {
        backgroundColor: '#11ba82', // Background for the value label
        color: '#fff', // Text colour in the value label
      },
    },
  },
},
});

export default theme;
