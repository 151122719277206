import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Typography,
  Grid2,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableSortLabel,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';

const ApplicationsListPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [applicationData, setApplicationData] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '_createdDate', direction: 'desc' });
  const [filterapplicationType, setFilterapplicationType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
    const user_type = localStorage.getItem('user_type');
  if (user_type) {
    if (user_type === 'admin') {
    fetchApplications(isMounted);
  } else if (user_type === 'brandAdmin') {
    const brandId = localStorage.getItem('brand_id');
    fetchApplications(isMounted, brandId);
  } else {
    navigate('/logout');
  }
}});

return () => {
  isMounted = false;
  unsubscribe();
};
}, [navigate]);

  const fetchApplications = async (isMounted, brandId) => {
    try {
          const auth = getAuth();
          const user = auth.currentUser;

      if (user) {
        const accessToken = await user.getIdToken();
        const applications = await axios.post(
          `https://ostendo.nucleotto.com/api/application/list-all`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

      let applicationData = applications.data.applications;
      console.log('Fetched application data:', applicationData); // Debug log

      // Filter out applications with status "Accepted"
      applicationData = applicationData.filter(application => application.status !== 'Accepted');
      console.log('Filtered application data:', applicationData); // Debug log

      if (isMounted) {
        if (Array.isArray(applicationData)) {
          setApplicationData(applicationData);
          console.log('Set application state:', applicationData); // Debug log
        } else {
          console.error('Fetched data is not an array:', applicationData);
        }
        setIsLoading(false);
      }}
    } catch (error) {
      console.error('Error fetching application:', error);
      if (isMounted) {
        setError('Failed to fetch application. Please try again later.');
        setIsLoading(false);
      }
    }
  };

  const handleViewClick = (application) => {
    navigate(`/campaigns/detail/${application.campaignId}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterapplicationType(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedApplication = [...applicationData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredApplication = sortedApplication.filter((application) =>
    (application.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    application.application_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    application.displayName?.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (filterapplicationType === '' || application.application_type === filterapplicationType)
  );

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
            <CircularProgress />
          </Container>
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
            <Typography color="error">{error}</Typography>
          </Container>
        </Box>
      </Box>
    );
  }

  console.log('Rendering application:', applicationData); // Debug log

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>New and Pending Applications</Typography>
            </Grid2>
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
              <Grid2 item xs={3} minWidth={'300px'}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Filter by application Type</InputLabel>
                  <Select
                    value={filterapplicationType}
                    onChange={handleFilterChange}
                    label="status"
                  >
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Accepted">Accepted</MenuItem>
                    <MenuItem value="Declined">Declined</MenuItem>
                    {/* Add more application types as needed */}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 item xs={6} />
              <Grid2 item xs={3} container justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid2 item xs={8} minWidth={'300px'}>
                  <TextField
                    label="Search application"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Grid2>
                
              </Grid2>
            </Grid2>
            <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'creatorId'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('creatorId')}
                      >
                        <strong>Creator</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'campaignName'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('campaignName')}
                      >
                        <strong>Campaign Name</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'status'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('status')}
                      >
                        <strong>Status</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'applicationDate'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('applicationDate')}
                      >
                        <strong>Application Date</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicationData.map((application) => (
                    <TableRow key={application._id} sx={{ cursor: 'pointer' }}>
                      <TableCell>{application.creatorId}</TableCell>
                      <TableCell>{application.campaignName}</TableCell>
                      <TableCell>{application.status}</TableCell>
                      <TableCell>{new Date(application.applicationDate).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleViewClick(application)}>
                          <VisibilityIcon />
                        </IconButton>
                        
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ApplicationsListPage;
