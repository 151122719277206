export const campaignTypes = {
  paidInfluencers: 'Paid Influencers',
  productSeeding: 'Product Seeding',
  ugcAds: 'UGC Ads',
  affiliateAmbassadors: 'Affiliate Ambassadors',
  eventAttendance: 'Event Attendance',
    
  };


export const remuneration = {
  influencerPayment: 'Influencer Payment',
  gifting: 'Gifting',
  influencerPaymentAndGifting: 'Influencer Payment & Gifting',

};

export const paidInfluencersCampaignData = {
  jobType: "Paid Influencers",
  // Overview
  advertiser: "",
  productInformation: "",
  campaignName: "",
  campaignSummary: "",
  campaignObjective: "",
  campaignTerm: "",
  totalBudget: "",
  startDate: "",
  endDate: "",
  // Target Audience
  targetAudienceAge: [],
  targetAudienceGender: [],
  targetPrimaryAudienceLocation: "",
  targetSecondaryAudienceLocation: [],
  // Influencer Type
  influencerAgeRange: [],
  influencerGender: [],
  influencerLocation: [],
  influencerCategory: [],
  // Deliverables
  placements: [], // Array of objects: { platform: "", placementType: "", comments: "", fee: "", productValue: "" }
  placementObjective: [],
  postDateTimeline: "",
  contentDeadlines: "",
  contentApprovalNeeded: "",
  contentUsage: {
    republishOnBrandWebsite: "",
    republishOnBrandSocial: "",
    permissions: {
      lengthOfUse: "",
      usageRights: []
    }
  },
  resharing: {
    onBrandSocials: "",
    editable: ""
  },
  giftingType: "",
  boosting: {
    contentBoostedFromCreatorsChannels: "",
    contentBoostedFromBrandsChannels: ""
  },
  // Exclusivity
  categoryExclusivity: "",
  competitorExclusivity: "",
  territoryExclusivity: "",
  exclusivityPeriod: "",
  // Campaign Expectations
  contentRevisions: "",
  performanceMetrics: "",
  reportingRequired: "",
  paidDisclosure: "",
  brandTagging: "",
  shoppableLinks: "",
  interactivity: "",
  finalComments: "",
  productValue: "",
  status: "Draft"
};

export const eventAttendanceCampaignData = {
  jobType: "Event Attendance",
  // Overview
  advertiser: "",
  productInformation: "",
  campaignName: "",
  campaignSummary: "",
  campaignObjective: "",
  campaignTerm: "",
  totalBudget: "",
  startDate: "",
  endDate: "",
  // Target Audience
  targetAudienceAge: [],
  targetAudienceGender: [],
  targetPrimaryAudienceLocation: "",
  targetSecondaryAudienceLocation: [],
  // Influencer Type
  influencerAgeRange: [],
  influencerGender: [],
  influencerLocation: [],
  influencerCategory: [],
  // Deliverables
  placements: [],
  placementObjective: [],
  postDateTimeline: "",
  contentDeadlines: "",
  contentApprovalNeeded: "",
  contentUsage: {
    republishOnBrandWebsite: "",
    republishOnBrandSocial: "",
    permissions: {
      lengthOfUse: "",
      usageRights: []
    }
  },
  resharing: {
    onBrandSocials: "",
    editable: ""
  },
  giftingType: "",
  boosting: {
    contentBoostedFromCreatorsChannels: "",
    contentBoostedFromBrandsChannels: ""
  },
  // Exclusivity
  categoryExclusivity: "",
  competitorExclusivity: "",
  territoryExclusivity: "",
  exclusivityPeriod: "",
  // Campaign Expectations
  contentRevisions: "",
  performanceMetrics: "",
  reportingRequired: "",
  paidDisclosure: "",
  brandTagging: "",
  shoppableLinks: "",
  interactivity: "",
  finalComments: "",
  productValue: "",
  // Event Attendance–Specific Fields
  location: "",            // Venue or event location
  timeArrival: "",         // Time of arrival at the event
  timeSensitive: "",       // "Yes" or "No"
  timeFlexibility: "",     // "Flexible" or "Not Flexible"
  eventType: "",           // e.g., "Red carpet", "VIP gala", etc.
  dressCode: "",           // Description or code required for the event
  perks: [],               // Array of perks (e.g., ["Private Transport", "Food"])
  compensation: "",        // e.g., "PAID ATTENDANCE" or "FREE ATTENDANCE"
  preferredPlatforms: [],  // e.g., ["Instagram", "TikTok"]
  status: "Draft"
};

export const ugcAdsCampaignData = {
  jobType: "UGC Ads",
  // Overview
  advertiser: "",
  productInformation: "",
  campaignName: "",
  campaignSummary: "",
  campaignObjective: "",
  campaignTerm: "",
  totalBudget: "",
  startDate: "",
  endDate: "",
  // Target Audience
  targetAudienceAge: [],
  targetAudienceGender: [],
  targetPrimaryAudienceLocation: "",
  targetSecondaryAudienceLocation: [],
  // Influencer Type
  influencerAgeRange: [],
  influencerGender: [],
  influencerLocation: [],
  influencerCategory: [],
  // Deliverables (Common fields)
  placements: [],
  placementObjective: [],
  postDateTimeline: "",
  contentDeadlines: "",
  contentApprovalNeeded: "",
  contentUsage: {
    republishOnBrandWebsite: "",
    republishOnBrandSocial: "",
    permissions: {
      lengthOfUse: "",
      usageRights: []
    }
  },
  resharing: {
    onBrandSocials: "",
    editable: ""
  },
  giftingType: "",
  boosting: {
    contentBoostedFromCreatorsChannels: "",
    contentBoostedFromBrandsChannels: ""
  },
  // Exclusivity
  categoryExclusivity: "",
  competitorExclusivity: "",
  territoryExclusivity: "",
  exclusivityPeriod: "",
  // Campaign Expectations
  contentRevisions: "",
  performanceMetrics: "",
  reportingRequired: "",
  paidDisclosure: "",
  brandTagging: "",
  shoppableLinks: "",
  interactivity: "",
  finalComments: "",
  productValue: "",
  // UGC Ads–Specific Fields
  videoType: "",           // e.g., "Creator Generated Script" or "Client Supplied Script"
  videoLength: "",         // e.g., "Up to 15 seconds", "Up to 30 seconds", etc.
  videoContentUsage: [],   // Array of usage options (e.g., ["Social Media", "DOOH"])
  videoAspectRatio: "",    // e.g., "9x16", "4x5", etc.
  additionalHook: "",      // e.g., "0", "1", "2", "3"
  additionalCTA: "",       // e.g., "0", "1", "2", "3"
  captions: "",            // "With", "Without", or "Both"
  royaltyFreeMusic: "",    // "With", "Without", or "Both"
  rawAssets: "",           // "Yes" or "No"
  status: "Draft"
};

export const productSeedingCampaignData = {
  jobType: "Product Seeding",
  // Overview
  advertiser: "",
  productInformation: "",
  campaignName: "",
  campaignSummary: "",
  campaignObjective: "",
  campaignTerm: "",
  totalBudget: "",
  startDate: "",
  endDate: "",
  // Target Audience
  targetAudienceAge: [],
  targetAudienceGender: [],
  targetPrimaryAudienceLocation: "",
  targetSecondaryAudienceLocation: [],
  // Influencer Type
  influencerAgeRange: [],
  influencerGender: [],
  influencerLocation: [],
  influencerCategory: [],
  // Deliverables
  placements: [], // Each placement is an object { platform, placementType, comments, fee, productValue }
  placementObjective: [],
  postDateTimeline: "",
  contentDeadlines: "",
  contentApprovalNeeded: "",
  contentUsage: {
    republishOnBrandWebsite: "",
    republishOnBrandSocial: "",
    permissions: {
      lengthOfUse: "",
      usageRights: []
    }
  },
  resharing: {
    onBrandSocials: "",
    editable: ""
  },
  giftingType: "",
  boosting: {
    contentBoostedFromCreatorsChannels: "",
    contentBoostedFromBrandsChannels: ""
  },
  // Exclusivity
  categoryExclusivity: "",
  competitorExclusivity: "",
  territoryExclusivity: "",
  exclusivityPeriod: "",
  // Campaign Expectations
  contentRevisions: "",
  performanceMetrics: "",
  reportingRequired: "",
  paidDisclosure: "",
  brandTagging: "",
  shoppableLinks: "",
  interactivity: "",
  finalComments: "",
  productValue: "",
  status: "Draft"
};



