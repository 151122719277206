import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
import { DateRangePicker } from '@mui/lab';
import Slider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import { createCampaign, updateCampaign } from '../../../api/campaignsApi';
import { createBrand } from '../../../api/brandApi';
import { getAuth } from 'firebase/auth';
import { ugcAdsCampaignData } from '../../../config/campaignType';

const UGCAdsStepper = ({ brands = [], jobType, refreshBrands }) => {
  const navigate = useNavigate();

  // Stepper state
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
   'Overview',
    'Deliverables',
    'Ad Style',
    'Influencer Type',
  ];
  const [completed, setCompleted] = useState({});
  const [campaignId, setCampaignId] = useState(null);
  const [inPerpetuity, setInPerpetuity] = useState(false);
  const [brandData, setBrandData] = useState({
    company_name: '',
    company_URL: '',
    company_about: ''
  });
  const [sliderValue, setSliderValue] = useState(0);
  const [postDateTimeline, setPostDateTimeline] = useState([null, null]);
  const [newBrandModalOpen, setNewBrandModalOpen] = useState(false);

  // Initial state for all campaign fields relevant to Paid Influencers
  const [campaignData, setCampaignData] = useState(ugcAdsCampaignData);

  // Simple helper to update state for exclusive selections
  const handleExclusiveChange = (key, option) => {
    setCampaignData(prev => ({
      ...prev,
      [key]: prev[key] === option ? '' : option
    }));
  };

  // Simulated auto-save function (replace with your API call)
  const saveStep = async (step) => {
    if (!campaignId) {
      const newCampaign = await createCampaign(campaignData);
      setCampaignId(newCampaign);
    } else {
      await updateCampaign(campaignId, campaignData);
    }
    return new Promise((resolve) => setTimeout(() => resolve(true), 500));
  };

  // Handler for Next button
  const handleNext = async () => {
    // (Optional) add validation here before saving
    const saved = await saveStep(activeStep);
    if (saved) {
      setCompleted(prev => ({ ...prev, [activeStep]: true }));
      if (activeStep === steps.length - 1) {
        navigate(`/campaigns/detail/${campaignId}`);
        return;
      }
      setActiveStep(prev => prev + 1);
    }
  };

  // Handler for Back button
  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };



  // Handler for advertiser selection
  const handleAdvertiserChange = (e) => {
    const value = e.target.value;
    if (value === 'newBrand') {
      setNewBrandModalOpen(true);
    } else {
      setCampaignData(prev => ({ ...prev, advertiser: value }));
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setInPerpetuity(checked);
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      contentUsageRights: checked ? '' : prevData.contentUsageRights,
    }));
  };

  // Render content for each step
  const renderStepContent = (step) => {
    switch (step) {
         case 0:
              return (
                <Box>
                 <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">Overview</Typography>
    
              <FormControl fullWidth margin="normal">
                <InputLabel id="advertiser-label">Advertiser</InputLabel>
                <Select
              labelId="advertiser-label"
              label="Brand"
              value={campaignData.advertiser}
              onChange={handleAdvertiserChange}
              >
                <MenuItem value="">
                  <em>Select Brand</em>
                </MenuItem>
                <MenuItem value="newBrand">New Brand</MenuItem>
                {brands?.map((brand) => (
                  
                  <MenuItem key={brand._id} value={brand._id}>
                    {brand.company_name}
                  </MenuItem>
                  
                ))}
    
              </Select>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                label="Product Information"
                value={campaignData.productInformation}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          productInformation: e.target.value,
                        })
                      }
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Campaign Name"
                      value={campaignData.campaignName}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          campaignName: e.target.value,
                        })
                      }
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Campaign Summary"
                      value={campaignData.campaignSummary}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          campaignSummary: e.target.value,
                        })
                      }
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Campaign Objective"
                      value={campaignData.campaignObjective}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          campaignObjective: e.target.value,
                        })
                      }
                    />
    
                 
                  
                    <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">Campaign Usage Term</Typography>
                    <TextField
                      
                      margin="normal"
                      label="Start Date"
                      type="date"
                      sx={{ marginRight: 2 }}
                      InputLabelProps={{ shrink: true }}
                      value={campaignData.startDate}
                      disabled={inPerpetuity}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          startDate: e.target.value,
                        })
                      }
                    />
                    <TextField
                      
                      margin="normal"
                      label="End Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={campaignData.endDate}
                      disabled={inPerpetuity}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          endDate: e.target.value,
                        })
                      }
                    />
                    <Typography variant="subtitle1" marginLeft="10px" marginTop="30px" marginBottom="30px" fontWeight="">Or</Typography>
                   <Box>
                   <FormControlLabel
                    control={
                    <Checkbox
                    sx={{mr: 1, ml: 3}}
                    name="In Perpetuity"
                    checked={inPerpetuity}
                    onChange={handleCheckboxChange}
                    />
                      }
                    label="In Perpetuity"
                    />
                    </Box> 
    
                    <TextField
                    
                      margin="normal"
                      label="Content Deadline"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={campaignData.contentDeadlines}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          contentDeadlines: e.target.value,
                        })
                      }
                    />
                      <TextField
                      fullWidth
                      margin="normal"
                      label="Remuneration"
                      type="number"
                      InputAdornment="currency"
                      InputLabelProps={{ shrink: true }}
                      value={campaignData.contentDeadlines}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          contentDeadlines: e.target.value,
                        })
                      }
                    />
                      <TextField
                      fullWidth
                      margin="normal"
                      label="Final Comments"
                      type="text"
                      multiline
                      rows={4}
                      InputLabelProps={{ shrink: true }}
                      value={campaignData.finalComments}
                      onChange={(e) =>
                        setCampaignData({
                          ...campaignData,
                          finalComments: e.target.value,
                        })
                      }
                    />
    
    
              
                </Box>
              );
            case 1:
              return (
                <Box>
                {/* Video Type */}
                <Typography variant="subtitle1" fontWeight="bold">
                  Video Type
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {['Creator Generated Script', 'Client Supplied Script'].map((option) => {
                    const isSelected = campaignData.videoType === option;
                    return (
                      <Chip
                        key={option}
                        label={option}
                        clickable
                        onClick={() =>
                          setCampaignData((prevData) => ({
                            ...prevData,
                            videoType: isSelected ? '' : option,
                          }))
                        }
                        sx={{
                          mr: 1,
                          my: 1,
                          backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                          color: isSelected ? '#ffffff' : 'inherit',
                        }}
                      />
                    );
                  })}
                </Box>
          
                {/* Length */}
                <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                  Length
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {['Up to 15 seconds', 'Up to 30 seconds', 'Up to 60 seconds'].map(
                    (option) => {
                      const isSelected = campaignData.videoLength === option;
                      return (
                        <Chip
                          key={option}
                          label={option}
                          clickable
                          onClick={() =>
                            setCampaignData((prevData) => ({
                              ...prevData,
                              videoLength: isSelected ? '' : option,
                            }))
                          }
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    }
                  )}
                </Box>
          
                {/* Content Usage */}
                <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                  Content Usage
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {['Social Media', 'Open Web', 'DOOH', 'TVC', 'BVOD'].map(
                    (option) => {
                      const current = campaignData.videoContentUsage || [];
                      const isSelected = current.includes(option);
                      return (
                        <Chip
                          key={option}
                          label={option}
                          clickable
                          onClick={() => {
                            const newSelection = isSelected
                              ? current.filter((item) => item !== option)
                              : [...current, option];
                            setCampaignData((prevData) => ({
                              ...prevData,
                              videoContentUsage: newSelection,
                            }));
                          }}
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    }
                  )}
                </Box>
          
                {/* Aspect Ratio */}
                <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                  Aspect Ratio
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {['9x16', '4x5', '1x1', '16x9'].map((option) => {
                    const isSelected = campaignData.videoAspectRatio === option;
                    return (
                      <Chip
                        key={option}
                        label={option}
                        clickable
                        onClick={() =>
                          setCampaignData((prevData) => ({
                            ...prevData,
                            videoAspectRatio: isSelected ? '' : option,
                          }))
                        }
                        sx={{
                          mr: 1,
                          my: 1,
                          backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                          color: isSelected ? '#ffffff' : 'inherit',
                        }}
                      />
                    );
                  })}
                </Box>
                <Typography variant="subtitle1" fontWeight="bold" mt={2}>
            Additional Hook (how many)
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {["0", "1", "2", "3"].map((option) => {
              const isSelected = campaignData.additionalHook === option;
              return (
                <Chip
                  key={option}
                  label={option}
                  clickable
                  onClick={() =>
                    setCampaignData((prev) => ({
                      ...prev,
                      additionalHook: isSelected ? '' : option,
                    }))
                  }
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
          </Box>
    
          {/* Additional CTA (how many) */}
          <Typography variant="subtitle1" fontWeight="bold" mt={2}>
            Additional CTA (how many)
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {["0", "1", "2", "3"].map((option) => {
              const isSelected = campaignData.additionalCTA === option;
              return (
                <Chip
                  key={option}
                  label={option}
                  clickable
                  onClick={() =>
                    setCampaignData((prev) => ({
                      ...prev,
                      additionalCTA: isSelected ? '' : option,
                    }))
                  }
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
          </Box>
    
          {/* Captions */}
          <Typography variant="subtitle1" fontWeight="bold" mt={2}>
            Captions
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {["With", "Without", "Both"].map((option) => {
              const isSelected = campaignData.captions === option;
              return (
                <Chip
                  key={option}
                  label={option}
                  clickable
                  onClick={() =>
                    setCampaignData((prev) => ({
                      ...prev,
                      captions: isSelected ? '' : option,
                    }))
                  }
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
          </Box>
    
          {/* Royalty Free Music */}
          <Typography variant="subtitle1" fontWeight="bold" mt={2}>
            Royalty free music
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {["With", "Without", "Both"].map((option) => {
              const isSelected = campaignData.royaltyFreeMusic === option;
              return (
                <Chip
                  key={option}
                  label={option}
                  clickable
                  onClick={() =>
                    setCampaignData((prev) => ({
                      ...prev,
                      royaltyFreeMusic: isSelected ? '' : option,
                    }))
                  }
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
          </Box>
    
          {/* Raw Assets */}
          <Typography variant="subtitle1" fontWeight="bold" mt={2}>
            Raw Assets: individual shots supplied separately
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {["Yes", "No"].map((option) => {
              const isSelected = campaignData.rawAssets === option;
              return (
                <Chip
                  key={option}
                  label={option}
                  clickable
                  onClick={() =>
                    setCampaignData((prev) => ({
                      ...prev,
                      rawAssets: isSelected ? '' : option,
                    }))
                  }
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
          </Box>
    
          {/* Content Revisions */}
          <Typography variant="subtitle1" fontWeight="bold" mt={2}>
            Content Revisions
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {["1x", "2x", "3x"].map((option) => {
              const isSelected = campaignData.contentRevisions === option;
              return (
                <Chip
                  key={option}
                  label={option}
                  clickable
                  onClick={() =>
                    setCampaignData((prev) => ({
                      ...prev,
                      contentRevisions: isSelected ? '' : option,
                    }))
                  }
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
          </Box>
              </Box>
              );
              case 2:
                return (
           
                  <Box>
                  {/* Vibe Section */}
                  <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                    Vibe
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {[
                      'High Energy',
                      'Fun & Playful',
                      'Clean & Minimalist',
                      'Relatable',
                      'Luxury and Premium',
                      'Bold',
                      'Calm & Relaxing',
                      'Authentic & Organic',
                      'Trendy & Viral styles'
                    ].map((option) => {
                      const isSelected = campaignData.vibe === option;
                      return (
                        <Chip
                          key={option}
                          label={option}
                          clickable
                          onClick={() => handleExclusiveChange('vibe', option)}
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    })}
                  </Box>
            
                  {/* Style Section */}
                  {/* Testimonial Sub-Category */}
                  <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                    Style – Testimonial
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {[
                      'Authentic testimonial',
                      'Before and After transformation',
                      'Unboxing & first impressions',
                      'Side-by-side comparison (product vs unlabelled competitor)'
                    ].map((option) => {
                      const isSelected = campaignData.styleTestimonial === option;
                      return (
                        <Chip
                          key={option}
                          label={option}
                          clickable
                          onClick={() => handleExclusiveChange('styleTestimonial', option)}
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    })}
                  </Box>
            
                  {/* Demonstration & Tutorial Styles Sub-Category */}
                  <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                    Style – Demonstration & Tutorial Styles
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {[
                      'How-to / Step-by-step guide',
                      'POV',
                      'Product demonstration',
                      'Aesthetic Montage',
                      'ASMR experience'
                    ].map((option) => {
                      const isSelected = campaignData.styleDemonstration === option;
                      return (
                        <Chip
                          key={option}
                          label={option}
                          clickable
                          onClick={() => handleExclusiveChange('styleDemonstration', option)}
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    })}
                  </Box>
            
                  {/* Trending & Engagement Style Sub-Category */}
                  <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                    Style – Trending & Engagement Style
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {[
                      'Day in the life / Vlog',
                      'Challenge / trend participation',
                      'Duet / Reaction'
                    ].map((option) => {
                      const isSelected = campaignData.styleTrending === option;
                      return (
                        <Chip
                          key={option}
                          label={option}
                          clickable
                          onClick={() => handleExclusiveChange('styleTrending', option)}
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    })}
                  </Box>
            
                  {/* Lifestyle & Relatability Sub-Category */}
                  <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                    Style – Lifestyle & Relatability
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {[
                      'Casual & Conversational',
                      'Skit',
                      'Emotional Storytelling',
                      'Family-Oriented'
                    ].map((option) => {
                      const isSelected = campaignData.styleLifestyle === option;
                      return (
                        <Chip
                          key={option}
                          label={option}
                          clickable
                          onClick={() => handleExclusiveChange('styleLifestyle', option)}
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
        
                );
    
              case 3:
                return (
           
                  <Box>
             
            <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
            Influencer Age Range
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map((age) => {
              const isSelected =
                campaignData.influencerAgeRange &&
                campaignData.influencerAgeRange.includes(age);
              return (
                <Chip
                  key={age}
                  label={age}
                  clickable
                  onClick={() => {
                    const current = campaignData.influencerAgeRange || [];
                    const newSelection = isSelected
                      ? current.filter((a) => a !== age)
                      : [...current, age];
                    setCampaignData({
                      ...campaignData,
                      influencerAgeRange: newSelection,
                    });
                  }}
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
          </Box>
        
          {/* Influencer Gender (Multi-Select) */}
          <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
            Influencer Gender
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {['Female', 'Male', 'Non-binary'].map((gender) => {
              const isSelected =
                campaignData.influencerGender &&
                campaignData.influencerGender.includes(gender);
              return (
                <Chip
                  key={gender}
                  label={gender}
                  clickable
                  onClick={() => {
                    const current = campaignData.influencerGender || [];
                    const newSelection = isSelected
                      ? current.filter((g) => g !== gender)
                      : [...current, gender];
                    setCampaignData({
                      ...campaignData,
                      influencerGender: newSelection,
                    });
                  }}
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
          </Box>
        
          {/* Influencer Location (Multi-Select) */}
          <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
            Influencer Location
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {['United States', 'China', 'India', 'United Kingdom', 'Germany', 'France', 'Canada', 'Australia', 'Italy', 'Brazil'].map((loc) => {
              const isSelected =
                campaignData.influencerLocation &&
                campaignData.influencerLocation.includes(loc);
              return (
                <Chip
                  key={loc}
                  label={loc}
                  clickable
                  onClick={() => {
                    const current = campaignData.influencerLocation || [];
                    const newSelection = isSelected
                      ? current.filter((l) => l !== loc)
                      : [...current, loc];
                    setCampaignData({
                      ...campaignData,
                      influencerLocation: newSelection,
                    });
                  }}
                  sx={{
                    mr: 1,
                    my: 1,
                    backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                    color: isSelected ? '#ffffff' : 'inherit',
                  }}
                />
              );
            })}
        
          </Box>
        
    </Box>
        
          );

      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel>
              {completed[index] ? <span>&#10003;</span> : label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>

      {/* New Brand Modal */}
      <Dialog open={newBrandModalOpen} onClose={() => setNewBrandModalOpen(false)}>
        <DialogTitle>Create New Brand</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the new brand details below.</DialogContentText>
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            name="company_name"
            value={brandData.company_name}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_name: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
          <TextField
            label="Company URL"
            variant="outlined"
            fullWidth
            name="company_URL"
            value={brandData.company_URL}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_URL: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
          <TextField
            label="Company About"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="company_about"
            value={brandData.company_about}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_about: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewBrandModalOpen(false)}>Cancel</Button>
          <Button onClick={async () => {
            try {
              const auth = getAuth();
              const user = auth.currentUser;
              if (user) {
                const idToken = await user.getIdToken();
                await createBrand(brandData, idToken);
              }
            } catch (error) {
              console.error('Error creating brand:', error);
            } finally {
              await refreshBrands();
              setNewBrandModalOpen(false);
            }
          }}>Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UGCAdsStepper;
