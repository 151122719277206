import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContentText,
  Checkbox,
  Chip
    } from '@mui/material';
import { createCampaign, updateCampaign} from '../../api/campaignsApi';
import { createBrand } from '../../api/brandApi';
import { getAuth } from 'firebase/auth';
import { campaignTypes } from '../../config/campaignType';
import Slider from '@mui/material/Slider';
import { DateRangePicker } from '@mui/lab';



const CreateCampaignStepper = ({brands = [], refreshBrands, jobType}) => {
  // Stepper state and completed steps
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [completed, setCompleted] = useState({});
  const [campaignId, setCampaignId] = useState(null);
  const [newBrandModalOpen, setNewBrandModalOpen] = useState(false);
  const [newBrandName, setNewBrandName] = useState('');
  const [inPerpetuity, setInPerpetuity] = useState(false);
  const [brandData, setBrandData] = useState({
    company_name: '',
    company_URL: '',
    company_about: '',
  });
  const [sliderValue, setSliderValue] = useState(0);
  const [postDateTimeline, setPostDateTimeline] = useState([null, null]);
  const eventTypes = [
    'Red carpet',
    'VIP gala',
    'Product launch',
    'Pop-up Activation',
    'Movie',
    'Theatre Production',
    'Exhibition',
    'Fashion Show',
    'Award Ceremony',
    'PR Stunt',
    'Celebrity Meet and Greet',
    'Sponsorship Event',
    'Workshop / Conference',
    'Charity Event',
  ];

  const perksOptions = [
    'Private Transport',
    'Accomodation',
    'Food',
    'Brand Gifts',
  ];

  const compensationOptions = ['PAID ATTENDANCE', 'FREE ATTENDANCE'];

  // Campaign data state (initial values; add more as needed)
// Complete initial state for campaignData:
const [campaignData, setCampaignData] = useState({
  // Common fields
  jobType: jobType, // e.g. "Paid Influencers", "Product Seeding", "UGC Ads", "Event Attendance", etc.
  
  // Overview (Section One)
  advertiser: '',
  productInformation: '',
  campaignName: '',
  campaignSummary: '',
  campaignObjective: '',
  campaignTerm: '',
  startDate: '',
  endDate: '',
  
  // Target Audience (Section Two)
  targetAudienceAge: [],
  targetAudienceGender: [],
  targetPrimaryAudienceLocation: '', // exclusive selection
  targetSecondaryAudienceLocation: [],

  // Influencer Type (Section Three)
  influencerAgeRange: [],
  influencerGender: [],
  influencerLocation: [], // using an array for multi‐select
  influencerCategory: [],

  // Deliverables (Section Four)
  placements: [], // each placement is an object, e.g. { platform: '', placementType: '', comments: '', fee: '', productValue: '' }
  placementObjective: [],
  postDateTimeline: '',
  contentDeadlines: '',
  contentApprovalNeeded: '',
  // “Content Usage” as an object:
  contentUsage: {
    republishOnBrandWebsite: '',
    republishOnBrandSocial: '',
    permissions: {
      lengthOfUse: '',
      usageRights: [],
    },
  },
  // “Resharing” details:
  resharing: {
    onBrandSocials: '',
    editable: '',
  },
  giftingType: '',
  boosting: {
    contentBoostedFromCreatorsChannels: '',
    contentBoostedFromBrandsChannels: '',
  },

  // Exclusivity (Section Five)
  categoryExclusivity: '',
  competitorExclusivity: '',
  territoryExclusivity: '',
  exclusivityPeriod: '',

  // Campaign Expectations (Section Six)
  contentRevisions: '',
  performanceMetrics: '',
  reportingRequired: '',
  paidDisclosure: '',
  brandTagging: '',
  shoppableLinks: '',
  interactivity: '',
  finalComments: '',
  productValue: '',

  // UGC Ads–specific fields (if jobType === 'UGC Ads')
  videoType: '',
  videoLength: '',
  videoContentUsage: [],
  videoAspectRatio: '',
  additionalHook: '',
  additionalCTA: '',
  captions: '',
  royaltyFreeMusic: '',
  rawAssets: '',

  // Event Attendance–specific fields
  location: '',
  timeArrival: '',
  timeSensitive: '',
  timeFlexibility: '',
  eventType: '',
  dressCode: '',
  perks: [],
  compensation: '',
  preferredPlatforms: [],
  status: 'Draft'
});




  useEffect(() => { 
    console.log(campaignId, campaignData.jobType);
  }, [campaignId, campaignData.jobType]);

  
  let steps;

  if (campaignData.jobType === 'Paid Influencers') {
  steps = [
    'Overview',
    'Target Audience',
    'Influencer Type',
    'Deliverables',
    'Exclusivity',
    'Campaign Expectations',
  ]; 
} else if (campaignData.jobType === ' Product Seeding') {
  steps = [
    'Overview',
    'Target Audience',
    'Influencer Type',
    'Deliverables',
    'Exclusivity',
    'Campaign Expectations',
  ]; 

} else if (campaignData.jobType === 'UGC Ads') {
  steps = [
    'Overview',
    'Deliverables',
    'Ad Style',
    'Influencer Type',
  ]; 
} else if (campaignData.jobType === 'Affiliate Ambassadors') {
  steps = [
    'Overview',
    'Target Audience',
    'Influencer Type',
    'Deliverables',
    'Exclusivity',
    'Campaign Expectations',
  ]; 
} else if (campaignData.jobType === 'Event Attendance') {
  steps = [ 
    'Overview',
    'Target Audience',
    'Influencer Type',
    'Deliverables',
    'Exclusivity',
    'Campaign Expectations',
    'Event Details'
  ]; } else {
    steps = [
      'Overview',
      'Target Audience',
      'Influencer Type',
      'Deliverables',
      'Exclusivity',
      'Campaign Expectations',
    ]; 
  };



  // Validate current step; you can adjust the validations as needed
  const validateStep = (step) => {
    // If jobType is UGC Ads, use a custom validation flow:
    if (campaignData.jobType === 'UGC Ads') {
      switch (step) {
        case 0: // Overview for UGC Ads
          return (
            campaignData.advertiser &&
            campaignData.productInformation &&
            campaignData.campaignName &&
            campaignData.campaignSummary &&
            campaignData.campaignObjective &&
            campaignData.campaignTerm &&
            campaignData.startDate &&
            campaignData.endDate
          );
        case 1: // Deliverables for UGC Ads
          return (
            campaignData.videoType &&
            campaignData.videoLength &&
            campaignData.videoContentUsage.length > 0 &&
            campaignData.videoAspectRatio &&
            campaignData.additionalHook !== '' &&
            campaignData.additionalCTA !== '' &&
            campaignData.captions !== '' &&
            campaignData.royaltyFreeMusic !== '' &&
            campaignData.rawAssets !== ''
          );
        case 2: // Ad Style (combining vibe and style sub-categories)
          return (
            campaignData.vibe &&
            campaignData.styleTestimonial &&
            campaignData.styleDemonstration &&
            campaignData.styleTrending &&
            campaignData.styleLifestyle
          );
        case 3: // Influencer Type for UGC Ads
          return (
            campaignData.influencerAgeRange.length > 0 &&
            campaignData.influencerGender.length > 0 &&
            campaignData.influencerLocation.length > 0 &&
            campaignData.influencerCategory.length > 0
          );
        default:
          return false;
      }
    }
    // If jobType is Event Attendance, include event details as a final step:
    else if (campaignData.jobType === 'Event Attendance') {
      switch (step) {
        case 0: // Overview
          return (
            campaignData.advertiser &&
            campaignData.productInformation &&
            campaignData.campaignName &&
            campaignData.campaignSummary &&
            campaignData.campaignObjective &&
            campaignData.campaignTerm &&
            campaignData.startDate &&
            campaignData.endDate
          );
        case 1: // Target Audience
          return (
            campaignData.targetAudienceAge.length > 0 &&
            campaignData.targetAudienceGender.length > 0 &&
            campaignData.targetPrimaryAudienceLocation !== ''
          );
        case 2: // Influencer Type
          return (
            campaignData.influencerAgeRange.length > 0 &&
            campaignData.influencerGender.length > 0 &&
            campaignData.influencerLocation.length > 0 &&
            campaignData.influencerCategory.length > 0
          );
        case 3: // Deliverables
          return (
            campaignData.placements.length > 0 &&
            campaignData.placements.every((p) => p.platform && p.placementType)
          );
        case 4: // Exclusivity
          return (
            campaignData.categoryExclusivity !== '' &&
            campaignData.territoryExclusivity !== '' &&
            campaignData.exclusivityPeriod !== ''
          );
        case 5: // Campaign Expectations
          return (
            campaignData.contentRevisions !== '' &&
            campaignData.reportingRequired !== ''
          );
        case 6: // Event Details
          return (
            campaignData.location !== '' &&
            campaignData.timeArrival !== '' &&
            campaignData.timeSensitive !== '' &&
            campaignData.timeFlexibility !== '' &&
            campaignData.eventType !== '' &&
            campaignData.dressCode !== '' &&
            campaignData.perks.length > 0 &&
            campaignData.compensation !== ''
          );
        default:
          return false;
      }
    }
    // Default validation for other jobTypes (e.g. Paid Influencers, Product Seeding, Affiliate Ambassadors)
    else {
      switch (step) {
        case 0: // Overview
          return (
            campaignData.advertiser &&
            campaignData.productInformation &&
            campaignData.campaignName &&
            campaignData.campaignSummary &&
            campaignData.campaignObjective &&
            campaignData.campaignTerm &&
            campaignData.startDate &&
            campaignData.endDate
          );
        case 1: // Target Audience
          return (
            campaignData.targetAudienceAge.length > 0 &&
            campaignData.targetAudienceGender.length > 0 &&
            campaignData.targetPrimaryAudienceLocation !== ''
          );
        case 2: // Influencer Type
          return (
            campaignData.influencerAgeRange.length > 0 &&
            campaignData.influencerGender.length > 0 &&
            campaignData.influencerLocation.length > 0 &&
            campaignData.influencerCategory.length > 0
          );
        case 3: // Deliverable
          return (
            campaignData.placements.length > 0 &&
            campaignData.placements.every(
              (p) => p.platform && p.placementType
            )
          );
        case 4: // Exclusivity
          return (
            campaignData.categoryExclusivity !== '' &&
            campaignData.territoryExclusivity !== '' &&
            campaignData.exclusivityPeriod !== ''
          );
        case 5: // Campaign Expectations
          return (
            campaignData.contentRevisions !== '' &&
            campaignData.reportingRequired !== ''
          );
        default:
          return false;
      }
    }
  };
  
  // Simulated auto-save function (replace with your API call)
  const saveStep = async (step) => {
    if (!campaignId) {
    const newCampaign = await createCampaign(campaignData); 
    setCampaignId(newCampaign);
    } else {
      await updateCampaign(campaignId, campaignData); 
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    });
  };

  // Handler for clicking Next
  const handleNext = async () => {
    {/*
   if (!validateStep(activeStep)) {
      // Optionally display an error message here
      return;
    }

    */}
    const saved = await saveStep(activeStep);
    if (saved) {
      setCompleted((prev) => ({ ...prev, [activeStep]: true }));
      setActiveStep((prev) => prev + 1);
    }
    if (activeStep === steps.length - 1) {
    navigate(`/campaigns/detail/${campaignId}`)
      return;
    }
    setActiveStep((prev) => prev + 1);
  };



const handleSliderChange = (event, newValue) => {
  setSliderValue(newValue);
};


  // Handler for clicking Back
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

    // Handle advertiser selection
    const handleAdvertiserChange = (e) => {
      const value = e.target.value;
      if (value === 'newBrand') {
        setNewBrandModalOpen(true);
      } else {
        setCampaignData({
          ...campaignData,
          advertiser: value,
        });
      }
    };


    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setBrandData({ ...brandData, [name]: value });
    };

    
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setInPerpetuity(checked);
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      contentUsageRights: checked ? '' : prevData.contentUsageRights,
    }));
  };
  
    // Handle creation of a new brand from the modal
    const handleCreateBrandSubmit = async (event) => {
      event.preventDefault();
      
         try {
           const auth = getAuth();
           const user = auth.currentUser;
           if (user) {
             const idToken = await user.getIdToken();
             await createBrand(brandData, idToken);
             

           } else {
             throw new Error('User not authenticated');
           }
         } catch (error) {
           console.error('Error creating brand:', error);
         } finally {
          refreshBrands();
         }
      setNewBrandModalOpen(false);
      setNewBrandName('');
    };

    const handleExclusiveChange = (key, option) => {
      setCampaignData((prev) => ({
        ...prev,
        [key]: prev[key] === option ? '' : option,
      }));
    };


let renderStepContent;
    
if (campaignData.jobType === 'UGC Ads') {
  // Render the content for each step
renderStepContent = (step) => {
    
      switch (step) {
        case 0:
          return (
            <Box>
             <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">Overview</Typography>

          <FormControl fullWidth margin="normal">
            <InputLabel id="advertiser-label">Advertiser</InputLabel>
            <Select
          labelId="advertiser-label"
          label="Brand"
          value={campaignData.advertiser}
          onChange={handleAdvertiserChange}
          >
            <MenuItem value="">
              <em>Select Brand</em>
            </MenuItem>
            <MenuItem value="newBrand">New Brand</MenuItem>
            {brands?.map((brand) => (
              
              <MenuItem key={brand._id} value={brand._id}>
                {brand.company_name}
              </MenuItem>
              
            ))}

          </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Product Information"
            value={campaignData.productInformation}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      productInformation: e.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Campaign Name"
                  value={campaignData.campaignName}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      campaignName: e.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Campaign Summary"
                  value={campaignData.campaignSummary}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      campaignSummary: e.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Campaign Objective"
                  value={campaignData.campaignObjective}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      campaignObjective: e.target.value,
                    })
                  }
                />

             
              
                <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">Campaign Usage Term</Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={campaignData.startDate}
                  disabled={inPerpetuity}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      startDate: e.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={campaignData.endDate}
                  disabled={inPerpetuity}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      endDate: e.target.value,
                    })
                  }
                />
                <Typography variant="subtitle1" marginLeft="10px" marginTop="30px" marginBottom="30px" fontWeight="">Or</Typography>
                <FormControlLabel
                control={
                <Checkbox
                sx={{mr: 1, ml: 3}}
                name="In Perpetuity"
                checked={inPerpetuity}
                onChange={handleCheckboxChange}
                />
                  }
                label="In Perpetuity"
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Content Deadline"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={campaignData.contentDeadlines}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      contentDeadlines: e.target.value,
                    })
                  }
                />
                  <TextField
                  fullWidth
                  margin="normal"
                  label="Remuneration"
                  type="number"
                  InputAdornment="currency"
                  InputLabelProps={{ shrink: true }}
                  value={campaignData.contentDeadlines}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      contentDeadlines: e.target.value,
                    })
                  }
                />
                  <TextField
                  fullWidth
                  margin="normal"
                  label="Final Comments"
                  type="text"
                  multiline
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  value={campaignData.finalComments}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      finalComments: e.target.value,
                    })
                  }
                />


                        
                <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">Campaign Term</Typography>
                <TextField
                  width="100px"
                  margin="normal"
                  label="Start Date"
                  type="date"
                  sx={{ marginRight: 2 }}
                  InputLabelProps={{ shrink: true }}
                  value={campaignData.startDate}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      startDate: e.target.value,
                    })
                  }
                />
                <TextField
                  width="100px"
                  margin="normal"
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={campaignData.endDate}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      endDate: e.target.value,
                    })
                  }
                />
           
            </Box>
          );
        case 1:
          return (
            <Box>
            {/* Video Type */}
            <Typography variant="subtitle1" fontWeight="bold">
              Video Type
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['Creator Generated Script', 'Client Supplied Script'].map((option) => {
                const isSelected = campaignData.videoType === option;
                return (
                  <Chip
                    key={option}
                    label={option}
                    clickable
                    onClick={() =>
                      setCampaignData((prevData) => ({
                        ...prevData,
                        videoType: isSelected ? '' : option,
                      }))
                    }
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
      
            {/* Length */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Length
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['Up to 15 seconds', 'Up to 30 seconds', 'Up to 60 seconds'].map(
                (option) => {
                  const isSelected = campaignData.videoLength === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData((prevData) => ({
                          ...prevData,
                          videoLength: isSelected ? '' : option,
                        }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit',
                      }}
                    />
                  );
                }
              )}
            </Box>
      
            {/* Content Usage */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Content Usage
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['Social Media', 'Open Web', 'DOOH', 'TVC', 'BVOD'].map(
                (option) => {
                  const current = campaignData.videoContentUsage || [];
                  const isSelected = current.includes(option);
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() => {
                        const newSelection = isSelected
                          ? current.filter((item) => item !== option)
                          : [...current, option];
                        setCampaignData((prevData) => ({
                          ...prevData,
                          videoContentUsage: newSelection,
                        }));
                      }}
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit',
                      }}
                    />
                  );
                }
              )}
            </Box>
      
            {/* Aspect Ratio */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Aspect Ratio
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['9x16', '4x5', '1x1', '16x9'].map((option) => {
                const isSelected = campaignData.videoAspectRatio === option;
                return (
                  <Chip
                    key={option}
                    label={option}
                    clickable
                    onClick={() =>
                      setCampaignData((prevData) => ({
                        ...prevData,
                        videoAspectRatio: isSelected ? '' : option,
                      }))
                    }
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
        Additional Hook (how many)
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {["0", "1", "2", "3"].map((option) => {
          const isSelected = campaignData.additionalHook === option;
          return (
            <Chip
              key={option}
              label={option}
              clickable
              onClick={() =>
                setCampaignData((prev) => ({
                  ...prev,
                  additionalHook: isSelected ? '' : option,
                }))
              }
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
      </Box>

      {/* Additional CTA (how many) */}
      <Typography variant="subtitle1" fontWeight="bold" mt={2}>
        Additional CTA (how many)
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {["0", "1", "2", "3"].map((option) => {
          const isSelected = campaignData.additionalCTA === option;
          return (
            <Chip
              key={option}
              label={option}
              clickable
              onClick={() =>
                setCampaignData((prev) => ({
                  ...prev,
                  additionalCTA: isSelected ? '' : option,
                }))
              }
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
      </Box>

      {/* Captions */}
      <Typography variant="subtitle1" fontWeight="bold" mt={2}>
        Captions
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {["With", "Without", "Both"].map((option) => {
          const isSelected = campaignData.captions === option;
          return (
            <Chip
              key={option}
              label={option}
              clickable
              onClick={() =>
                setCampaignData((prev) => ({
                  ...prev,
                  captions: isSelected ? '' : option,
                }))
              }
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
      </Box>

      {/* Royalty Free Music */}
      <Typography variant="subtitle1" fontWeight="bold" mt={2}>
        Royalty free music
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {["With", "Without", "Both"].map((option) => {
          const isSelected = campaignData.royaltyFreeMusic === option;
          return (
            <Chip
              key={option}
              label={option}
              clickable
              onClick={() =>
                setCampaignData((prev) => ({
                  ...prev,
                  royaltyFreeMusic: isSelected ? '' : option,
                }))
              }
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
      </Box>

      {/* Raw Assets */}
      <Typography variant="subtitle1" fontWeight="bold" mt={2}>
        Raw Assets: individual shots supplied separately
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {["Yes", "No"].map((option) => {
          const isSelected = campaignData.rawAssets === option;
          return (
            <Chip
              key={option}
              label={option}
              clickable
              onClick={() =>
                setCampaignData((prev) => ({
                  ...prev,
                  rawAssets: isSelected ? '' : option,
                }))
              }
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
      </Box>

      {/* Content Revisions */}
      <Typography variant="subtitle1" fontWeight="bold" mt={2}>
        Content Revisions
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {["1x", "2x", "3x"].map((option) => {
          const isSelected = campaignData.contentRevisions === option;
          return (
            <Chip
              key={option}
              label={option}
              clickable
              onClick={() =>
                setCampaignData((prev) => ({
                  ...prev,
                  contentRevisions: isSelected ? '' : option,
                }))
              }
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
      </Box>
          </Box>
          );
          case 2:
            return (
       
              <Box>
              {/* Vibe Section */}
              <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                Vibe
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {[
                  'High Energy',
                  'Fun & Playful',
                  'Clean & Minimalist',
                  'Relatable',
                  'Luxury and Premium',
                  'Bold',
                  'Calm & Relaxing',
                  'Authentic & Organic',
                  'Trendy & Viral styles'
                ].map((option) => {
                  const isSelected = campaignData.vibe === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() => handleExclusiveChange('vibe', option)}
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit',
                      }}
                    />
                  );
                })}
              </Box>
        
              {/* Style Section */}
              {/* Testimonial Sub-Category */}
              <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                Style – Testimonial
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {[
                  'Authentic testimonial',
                  'Before and After transformation',
                  'Unboxing & first impressions',
                  'Side-by-side comparison (product vs unlabelled competitor)'
                ].map((option) => {
                  const isSelected = campaignData.styleTestimonial === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() => handleExclusiveChange('styleTestimonial', option)}
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit',
                      }}
                    />
                  );
                })}
              </Box>
        
              {/* Demonstration & Tutorial Styles Sub-Category */}
              <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                Style – Demonstration & Tutorial Styles
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {[
                  'How-to / Step-by-step guide',
                  'POV',
                  'Product demonstration',
                  'Aesthetic Montage',
                  'ASMR experience'
                ].map((option) => {
                  const isSelected = campaignData.styleDemonstration === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() => handleExclusiveChange('styleDemonstration', option)}
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit',
                      }}
                    />
                  );
                })}
              </Box>
        
              {/* Trending & Engagement Style Sub-Category */}
              <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                Style – Trending & Engagement Style
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {[
                  'Day in the life / Vlog',
                  'Challenge / trend participation',
                  'Duet / Reaction'
                ].map((option) => {
                  const isSelected = campaignData.styleTrending === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() => handleExclusiveChange('styleTrending', option)}
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit',
                      }}
                    />
                  );
                })}
              </Box>
        
              {/* Lifestyle & Relatability Sub-Category */}
              <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                Style – Lifestyle & Relatability
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {[
                  'Casual & Conversational',
                  'Skit',
                  'Emotional Storytelling',
                  'Family-Oriented'
                ].map((option) => {
                  const isSelected = campaignData.styleLifestyle === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() => handleExclusiveChange('styleLifestyle', option)}
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit',
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
    
            );

          case 3:
            return (
       
              <Box>
         
        <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
        Influencer Age Range
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map((age) => {
          const isSelected =
            campaignData.influencerAgeRange &&
            campaignData.influencerAgeRange.includes(age);
          return (
            <Chip
              key={age}
              label={age}
              clickable
              onClick={() => {
                const current = campaignData.influencerAgeRange || [];
                const newSelection = isSelected
                  ? current.filter((a) => a !== age)
                  : [...current, age];
                setCampaignData({
                  ...campaignData,
                  influencerAgeRange: newSelection,
                });
              }}
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
      </Box>
    
      {/* Influencer Gender (Multi-Select) */}
      <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
        Influencer Gender
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {['Female', 'Male', 'Non-binary'].map((gender) => {
          const isSelected =
            campaignData.influencerGender &&
            campaignData.influencerGender.includes(gender);
          return (
            <Chip
              key={gender}
              label={gender}
              clickable
              onClick={() => {
                const current = campaignData.influencerGender || [];
                const newSelection = isSelected
                  ? current.filter((g) => g !== gender)
                  : [...current, gender];
                setCampaignData({
                  ...campaignData,
                  influencerGender: newSelection,
                });
              }}
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
      </Box>
    
      {/* Influencer Location (Multi-Select) */}
      <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
        Influencer Location
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {['United States', 'China', 'India', 'United Kingdom', 'Germany', 'France', 'Canada', 'Australia', 'Italy', 'Brazil'].map((loc) => {
          const isSelected =
            campaignData.influencerLocation &&
            campaignData.influencerLocation.includes(loc);
          return (
            <Chip
              key={loc}
              label={loc}
              clickable
              onClick={() => {
                const current = campaignData.influencerLocation || [];
                const newSelection = isSelected
                  ? current.filter((l) => l !== loc)
                  : [...current, loc];
                setCampaignData({
                  ...campaignData,
                  influencerLocation: newSelection,
                });
              }}
              sx={{
                mr: 1,
                my: 1,
                backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                color: isSelected ? '#ffffff' : 'inherit',
              }}
            />
          );
        })}
    
      </Box>
    
</Box>
    
            );
    
        default:
          return 'Unknown step';
  }}}

 else {
renderStepContent = (step) => {
      switch (step) {
 
      case 0:
        return (
          <Box>
            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">Overview</Typography>

            <FormControl fullWidth margin="normal">
              <InputLabel id="advertiser-label">Advertiser</InputLabel>
              <Select
            labelId="advertiser-label"
            label="Brand"
            value={campaignData.advertiser}
            onChange={handleAdvertiserChange}
            >
              <MenuItem value="">
                <em>Select Brand</em>
              </MenuItem>
              <MenuItem value="newBrand">New Brand</MenuItem>
              {brands?.map((brand) => (
                
                <MenuItem key={brand._id} value={brand._id}>
                  {brand.company_name}
                </MenuItem>
                
              ))}
  
</Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Product Information"
              value={campaignData.productInformation}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  productInformation: e.target.value,
                })
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Name"
              value={campaignData.campaignName}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  campaignName: e.target.value,
                })
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Summary"
              value={campaignData.campaignSummary}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  campaignSummary: e.target.value,
                })
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Objective"
              value={campaignData.campaignObjective}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  campaignObjective: e.target.value,
                })
              }
            />


            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">Campaign Term</Typography>
            <TextField
              width="100px"
              margin="normal"
              label="Start Date"
              type="date"
              sx={{ marginRight: 2 }}
              InputLabelProps={{ shrink: true }}
              value={campaignData.startDate}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  startDate: e.target.value,
                })
              }
            />
            <TextField
              width="100px"
              margin="normal"
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={campaignData.endDate}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  endDate: e.target.value,
                })
              }
            />
           
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
              Age Range
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map((age) => {
                const isSelected =
                  campaignData.targetAudienceAge &&
                  campaignData.targetAudienceAge.includes(age);
                return (
                  <Chip
                    key={age}
                    label={age}
                    clickable
                    onClick={() => {
                      const current =
                        campaignData.targetAudienceAge || [];
                      const newSelection = isSelected
                        ? current.filter((a) => a !== age)
                        : [...current, age];
                      setCampaignData({
                        ...campaignData,
                        targetAudienceAge: newSelection,
                      });
                    }}
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
          
            <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
              Gender
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['Female', 'Male', 'Non-binary'].map((gender) => {
                const isSelected =
                  campaignData.targetAudienceGender &&
                  campaignData.targetAudienceGender.includes(gender);
                return (
                  <Chip
                    key={gender}
                    label={gender}
                    clickable
                    onClick={() => {
                      const current =
                        campaignData.targetAudienceGender || [];
                      const newSelection = isSelected
                        ? current.filter((g) => g !== gender)
                        : [...current, gender];
                      setCampaignData({
                        ...campaignData,
                        targetAudienceGender: newSelection,
                      });
                    }}
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
          
            <Typography variant="subtitle1" marginTop={2}>
              <strong>Audience Primary Location</strong> - choose one
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {[
                'United States',
                'China',
                'India',
                'United Kingdom',
                'Germany',
                'France',
                'Canada',
                'Australia',
                'Italy',
                'Brazil',
              ].map((locA) => {
                const isSelected =
                  campaignData.targetPrimaryAudienceLocation === locA;
                return (
                  <Chip
                    key={locA}
                    label={locA}
                    clickable
                    onClick={() =>
                      setCampaignData({
                        ...campaignData,
                        targetPrimaryAudienceLocation:
                          isSelected ? '' : locA,
                      })
                    }
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
          
            <Typography variant="subtitle1" marginTop={2}>
              <strong>Audience Secondary Location</strong> - can choose multiple
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {[
                'United States',
                'China',
                'India',
                'United Kingdom',
                'Germany',
                'France',
                'Canada',
                'Australia',
                'Italy',
                'Brazil',
              ].map((locB) => {
                const isSelected =
                  campaignData.targetSecondaryAudienceLocation &&
                  campaignData.targetSecondaryAudienceLocation.includes(locB);
                return (
                  <Chip
                    key={locB}
                    label={locB}
                    clickable
                    onClick={() => {
                      const current =
                        campaignData.targetSecondaryAudienceLocation || [];
                      const newSelection = isSelected
                        ? current.filter((l) => l !== locB)
                        : [...current, locB];
                      setCampaignData({
                        ...campaignData,
                        targetSecondaryAudienceLocation: newSelection,
                      });
                    }}
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        );
      case 2:
        return (
   
          <Box>
     
             <Typography variant="subtitle1" fontWeight="bold">Audience Size = {sliderValue}</Typography>
            
            
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              min={0}
              max={1000000}
              step={5000}
              marks={[
                { value: 0, label: '0' },
                { value: 1000000, label: '1M+' },
              ]}
              valueLabelDisplay="auto"
      />

   <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
    Influencer Age Range
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map((age) => {
      const isSelected =
        campaignData.influencerAgeRange &&
        campaignData.influencerAgeRange.includes(age);
      return (
        <Chip
          key={age}
          label={age}
          clickable
          onClick={() => {
            const current = campaignData.influencerAgeRange || [];
            const newSelection = isSelected
              ? current.filter((a) => a !== age)
              : [...current, age];
            setCampaignData({
              ...campaignData,
              influencerAgeRange: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>

  {/* Influencer Gender (Multi-Select) */}
  <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
    Influencer Gender
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Female', 'Male', 'Non-binary'].map((gender) => {
      const isSelected =
        campaignData.influencerGender &&
        campaignData.influencerGender.includes(gender);
      return (
        <Chip
          key={gender}
          label={gender}
          clickable
          onClick={() => {
            const current = campaignData.influencerGender || [];
            const newSelection = isSelected
              ? current.filter((g) => g !== gender)
              : [...current, gender];
            setCampaignData({
              ...campaignData,
              influencerGender: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>

  {/* Influencer Location (Multi-Select) */}
  <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
    Influencer Location
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['United States', 'China', 'India', 'United Kingdom', 'Germany', 'France', 'Canada', 'Australia', 'Italy', 'Brazil'].map((loc) => {
      const isSelected =
        campaignData.influencerLocation &&
        campaignData.influencerLocation.includes(loc);
      return (
        <Chip
          key={loc}
          label={loc}
          clickable
          onClick={() => {
            const current = campaignData.influencerLocation || [];
            const newSelection = isSelected
              ? current.filter((l) => l !== loc)
              : [...current, loc];
            setCampaignData({
              ...campaignData,
              influencerLocation: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}

  </Box>

  {/* Influencer Category / Niche (Multi-Select) */}
  <Typography variant="subtitle1" marginTop={2}>
    Influencer Category / Niche
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
    {[
      'Fashion', 'Beauty', 'Tech', 'Food', 'Travel', 'Lifestyle',
      'Fitness', 'Health & Wellness', 'Gaming', 'Music', 'Comedy', 'DIY & Crafts',
      'Home Decor', 'Parenting', 'Photography', 'Art', 'Design', 'Finance',
      'Business', 'Marketing', 'Education', 'Sports', 'Automotive', 'Nature',
      'Pets', 'Sustainability', 'Environmental', 'Science', 'Books', 'Film',
      'Entertainment', 'Luxury', 'Travel & Adventure', 'Food & Beverage', 'Cooking',
      'Gardening', 'Architecture', 'Relationships', 'Spirituality', 'Mental Health',
      'Animation', 'Comedy & Memes', 'DIY Home Improvement', 'Personal Development',
      'Productivity', 'Photography & Videography', 'Social Media Tips', 'Cryptocurrency',
      'E-Sports', 'Virtual Reality'
    ].map((cat) => {
      const isSelected =
        campaignData.influencerCategory &&
        campaignData.influencerCategory.includes(cat);
      return (
        <Chip
          key={cat}
          label={cat}
          clickable
          onClick={() => {
            const current = campaignData.influencerCategory || [];
            const newSelection = isSelected
              ? current.filter((c) => c !== cat)
              : [...current, cat];
            setCampaignData({
              ...campaignData,
              influencerCategory: newSelection,
            });
          }}
          sx={{
            flex: '0 0 calc(100%/10 - 8px)',
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box></Box>

        );
        
        // Inside your switch-case (or component render function) for case 3:
        case 3: // Deliverables
          return (
            <Box>
  
  



              {campaignData.jobType === 'Paid Influencers' ? (
                <>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Add Placements
                  </Typography>
                  {campaignData.placements.map((placement, index) => (
                    <Box
                      key={index}
                      sx={{
                        border: '1px solid #ccc',
                        padding: 2,
                        marginBottom: 2,
                      }}
                    >
                      <FormControl fullWidth margin="normal">
                        <InputLabel id={`platform-label-${index}`}>
                          Platform
                        </InputLabel>
                        <Select
                          labelId={`platform-label-${index}`}
                          label="Platform"
                          value={placement.platform || ''}
                          onChange={(e) => {
                            const newPlacements = [...campaignData.placements];
                            newPlacements[index].platform = e.target.value;
                            // Reset placement type if platform changes
                            newPlacements[index].placementType = '';
                            setCampaignData({
                              ...campaignData,
                              placements: newPlacements,
                            });
                          }}
                        >
                          <MenuItem value="">
                            <em>Select Platform</em>
                          </MenuItem>
                          {['Instagram', 'TikTok', 'Youtube', 'Facebook', 'Snap'].map(
                            (platform) => (
                              <MenuItem key={platform} value={platform}>
                                {platform}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id={`placement-type-label-${index}`}>
                          Placement Type
                        </InputLabel>
                        <Select
                          labelId={`placement-type-label-${index}`}
                          label="Placement Type"
                          value={placement.placementType || ''}
                          onChange={(e) => {
                            const newPlacements = [...campaignData.placements];
                            newPlacements[index].placementType = e.target.value;
                            setCampaignData({
                              ...campaignData,
                              placements: newPlacements,
                            });
                          }}
                        >
                          <MenuItem value="">
                            <em>Select Placement Type</em>
                          </MenuItem>
                          {placement.platform === 'Instagram'
                            ? [
                                <MenuItem key="InstagramFeed" value="Feed Placement">
                                  Feed Placement
                                </MenuItem>,
                                <MenuItem
                                  key="InstagramSingleImage"
                                  value="Single Image Post"
                                >
                                  Single Image Post
                                </MenuItem>,
                                <MenuItem key="InstagramCarousel" value="Carousel Post">
                                  Carousel Post
                                </MenuItem>,
                                <MenuItem key="InstagramVideo" value="Video Post">
                                  Video Post
                                </MenuItem>,
                                <MenuItem key="InstagramReels" value="Reels">
                                  Reels
                                </MenuItem>,
                                <MenuItem key="InstagramStories" value="Stories">
                                  Stories
                                </MenuItem>,
                              ]
                            : null}
                          {placement.platform === 'TikTok'
                            ? [
                                <MenuItem key="TikTokFeed" value="Feed Placement">
                                  Feed Placement
                                </MenuItem>,
                                <MenuItem key="TikTokVideo" value="Video Post">
                                  Video Post
                                </MenuItem>,
                                <MenuItem key="TikTokStitch" value="Stitch">
                                  Stitch
                                </MenuItem>,
                                <MenuItem key="TikTokDuet" value="Duet">
                                  Duet
                                </MenuItem>,
                                <MenuItem key="TikTokStories" value="Stories">
                                  Stories
                                </MenuItem>,
                              ]
                            : null}
                          {placement.platform === 'Youtube'
                            ? [
                                <MenuItem key="YoutubeLong" value="Long form">
                                  Long form
                                </MenuItem>,
                                <MenuItem key="YoutubeShort" value="Short">
                                  Short
                                </MenuItem>,
                                <MenuItem key="YoutubeLive" value="Live">
                                  Live
                                </MenuItem>,
                                <MenuItem key="YoutubeStories" value="Stories">
                                  Stories
                                </MenuItem>,
                              ]
                            : null}
                          {placement.platform === 'Facebook'
                            ? [
                                <MenuItem key="FacebookFeed" value="Feed Placement">
                                  Feed Placement
                                </MenuItem>,
                                <MenuItem
                                  key="FacebookSingle"
                                  value="Single Image Post"
                                >
                                  Single Image Post
                                </MenuItem>,
                                <MenuItem
                                  key="FacebookCarousel"
                                  value="Carousel Post"
                                >
                                  Carousel Post
                                </MenuItem>,
                                <MenuItem key="FacebookVideo" value="Video Post">
                                  Video Post
                                </MenuItem>,
                                <MenuItem key="FacebookReels" value="Reels">
                                  Reels
                                </MenuItem>,
                                <MenuItem key="FacebookStories" value="Stories">
                                  Stories
                                </MenuItem>,
                              ]
                            : null}
                          {placement.platform === 'Snap'
                            ? [
                                <MenuItem key="SnapFeed" value="Feed Placement">
                                  Feed Placement
                                </MenuItem>,
                                <MenuItem
                                  key="SnapSingle"
                                  value="Single Image Post"
                                >
                                  Single Image Post
                                </MenuItem>,
                                <MenuItem
                                  key="SnapCarousel"
                                  value="Carousel Post"
                                >
                                  Carousel Post
                                </MenuItem>,
                                <MenuItem key="SnapVideo" value="Video Post">
                                  Video Post
                                </MenuItem>,
                                <MenuItem key="SnapStories" value="Stories">
                                  Stories
                                </MenuItem>,
                              ]
                            : null}
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Comments"
                        value={placement.comments || ''}
                        onChange={(e) => {
                          const newPlacements = [...campaignData.placements];
                          newPlacements[index].comments = e.target.value;
                          setCampaignData({
                            ...campaignData,
                            placements: newPlacements,
                          });
                        }}
                      />
        
                      {/* Placement Objective using Chips */}
                      <Typography variant="subtitle1">
                        Placement Objective
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {['Awareness', 'Engagement', 'Traffic', 'Leads', 'Sales'].map(
                          (obj) => {
                            const isSelected =
                              campaignData.placementObjective &&
                              campaignData.placementObjective.includes(obj);
                            return (
                              <Chip
                                key={obj}
                                label={obj}
                                clickable
                                onClick={() => {
                                  const current =
                                    campaignData.placementObjective || [];
                                  const newSelection = isSelected
                                    ? current.filter((val) => val !== obj)
                                    : [...current, obj];
                                  setCampaignData({
                                    ...campaignData,
                                    placementObjective: newSelection,
                                  });
                                }}
                                sx={{
                                  mr: 1,
                                  my: 1,
                                  backgroundColor: isSelected
                                    ? '#11ba82'
                                    : '#c7c7c7',
                                  color: isSelected ? '#ffffff' : 'inherit',
                                }}
                              />
                            );
                          }
                        )}
                      </Box>
        
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Post Date Timeline"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={campaignData.postDateTimeline || ''}
                        onChange={(e) =>
                          setCampaignData({
                            ...campaignData,
                            postDateTimeline: e.target.value,
                          })
                        }
                      />
                      <DateRangePicker
                        startText="From"
                        endText="To"
                        value={postDateTimeline}
                        onChange={(newValue) => {
                          setPostDateTimeline(newValue);
                          // Optionally update campaignData as well
                          setCampaignData((prevData) => ({
                            ...prevData,
                            postDateTimeline: newValue,
                          }));
                        }}
                        renderInput={(startProps, endProps) => (
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <TextField {...startProps} fullWidth margin="normal" />
                            <TextField {...endProps} fullWidth margin="normal" />
                          </Box>
                        )}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Content Deadlines (days prior)"
                        type="number"
                        value={campaignData.contentDeadlines || ''}
                        onChange={(e) =>
                          setCampaignData({
                            ...campaignData,
                            contentDeadlines: e.target.value,
                          })
                        }
                      />
        
                      {/* Content Approval Needed using Chips (Exclusive) */}
                      <Typography variant="subtitle1">
                        Content Approval Needed?
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {['Yes', 'No'].map((cont) => {
                          const isSelected =
                            campaignData.contentApprovalNeeded === cont;
                          return (
                            <Chip
                              key={cont}
                              label={cont}
                              clickable
                              onClick={() =>
                                setCampaignData({
                                  ...campaignData,
                                  contentApprovalNeeded: cont,
                                })
                              }
                              sx={{
                                mr: 1,
                                my: 1,
                                backgroundColor: isSelected
                                  ? '#11ba82'
                                  : '#c7c7c7',
                                color: isSelected ? '#ffffff' : 'inherit',
                              }}
                            />
                          );
                        })}
                      </Box>
        
                      {/* Branded Content Ads */}
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        marginTop="30px"
                      >
                        Branded Content Ads
                      </Typography>
        
                      {/* Content boosted from Creator’s channels */}
                      <Typography variant="subtitle1">
                        Content boosted from Creator’s channels
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {['Yes', 'No', 'TBC'].map((boost1) => {
                          const isSelected =
                            campaignData.boosting &&
                            campaignData.boosting.contentBoostedFromCreatorsChannels ===
                              boost1;
                          return (
                            <Chip
                              key={boost1}
                              label={boost1}
                              clickable
                              onClick={() =>
                                setCampaignData((prevData) => ({
                                  ...prevData,
                                  boosting: {
                                    ...prevData.boosting,
                                    contentBoostedFromCreatorsChannels: boost1,
                                  },
                                }))
                              }
                              sx={{
                                mr: 1,
                                my: 1,
                                backgroundColor: isSelected
                                  ? '#11ba82'
                                  : '#c7c7c7',
                                color: isSelected ? '#ffffff' : 'inherit',
                              }}
                            />
                          );
                        })}
                      </Box>
        
                      {/* Content boosted from Brand's channels */}
                      <Typography variant="subtitle1">
                        Content boosted from Brand's channels
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {['Yes', 'No', 'TBC'].map((boost2) => {
                          const isSelected =
                            campaignData.boosting &&
                            campaignData.boosting.contentBoostedFromBrandsChannels ===
                              boost2;
                          return (
                            <Chip
                              key={boost2}
                              label={boost2}
                              clickable
                              onClick={() =>
                                setCampaignData((prevData) => ({
                                  ...prevData,
                                  boosting: {
                                    ...prevData.boosting,
                                    contentBoostedFromBrandsChannels: boost2,
                                  },
                                }))
                              }
                              sx={{
                                mr: 1,
                                my: 1,
                                backgroundColor: isSelected
                                  ? '#11ba82'
                                  : '#c7c7c7',
                                color: isSelected ? '#ffffff' : 'inherit',
                              }}
                            />
                          );
                        })}
                      </Box>
        
                      {/* Brand to License Content */}
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        marginTop="30px"
                      >
                        Brand to License Content
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {['Yes', 'No'].map((license) => {
                          const isSelected =
                            campaignData.contentUsage &&
                            campaignData.contentUsage.brandToLicense === license;
                          return (
                            <Chip
                              key={license}
                              label={license}
                              clickable
                              onClick={() =>
                                setCampaignData((prevData) => ({
                                  ...prevData,
                                  contentUsage: {
                                    ...prevData.contentUsage,
                                    brandToLicense: license,
                                  },
                                }))
                              }
                              sx={{
                                mr: 1,
                                my: 1,
                                backgroundColor: isSelected
                                  ? '#11ba82'
                                  : '#c7c7c7',
                                color: isSelected ? '#ffffff' : 'inherit',
                              }}
                            />
                          );
                        })}
                      </Box>
        
                      {campaignData.contentUsage?.brandToLicense === 'Yes' && (
                        <>
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            marginTop="30px"
                          >
                            Usage Permissions
                          </Typography>
                          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {[
                              'Social Media',
                              'Open Web',
                              'DOOH',
                              'OOH',
                              'TV',
                              'BVOD',
                              'Brand Website',
                            ].map((usage) => {
                              const isSelected =
                                campaignData.contentUsage?.permissions?.usageRights?.includes(
                                  usage
                                );
                              return (
                                <Chip
                                  key={usage}
                                  label={usage}
                                  clickable
                                  onClick={() => {
                                    const current =
                                      (campaignData.contentUsage?.permissions?.usageRights) ||
                                      [];
                                    const newSelection = isSelected
                                      ? current.filter((u) => u !== usage)
                                      : [...current, usage];
                                    setCampaignData((prevData) => ({
                                      ...prevData,
                                      contentUsage: {
                                        ...prevData.contentUsage,
                                        permissions: {
                                          ...prevData.contentUsage.permissions,
                                          usageRights: newSelection,
                                        },
                                      },
                                    }));
                                  }}
                                  sx={{
                                    mr: 1,
                                    my: 1,
                                    backgroundColor: isSelected
                                      ? '#11ba82'
                                      : '#c7c7c7',
                                    color: isSelected ? '#ffffff' : 'inherit',
                                  }}
                                />
                              );
                            })}
                          </Box>
                        </>
                      )}
        
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        marginTop="30px"
                      >
                        Brand Permissions
                      </Typography>
                      <Typography variant="subtitle1">
                        Reshare content on Brand Socials
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {['Yes', 'No'].map((reshare1) => {
                          const isSelected =
                            campaignData.resharing?.onBrandSocials === reshare1;
                          return (
                            <Chip
                              key={reshare1}
                              label={reshare1}
                              clickable
                              onClick={() =>
                                setCampaignData((prevData) => ({
                                  ...prevData,
                                  resharing: {
                                    ...prevData.resharing,
                                    onBrandSocials: reshare1,
                                  },
                                }))
                              }
                              sx={{
                                mr: 1,
                                my: 1,
                                backgroundColor: isSelected
                                  ? '#11ba82'
                                  : '#c7c7c7',
                                color: isSelected ? '#ffffff' : 'inherit',
                              }}
                            />
                          );
                        })}
                      </Box>
                      <Typography variant="subtitle1">
                        Repurposing - brand can make edits
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {['Yes', 'No'].map((editable) => {
                          const isSelected =
                            campaignData.resharing?.editable === editable;
                          return (
                            <Chip
                              key={editable}
                              label={editable}
                              clickable
                              onClick={() =>
                                setCampaignData((prevData) => ({
                                  ...prevData,
                                  resharing: {
                                    ...prevData.resharing,
                                    editable: editable,
                                  },
                                }))
                              }
                              sx={{
                                mr: 1,
                                my: 1,
                                backgroundColor: isSelected
                                  ? '#11ba82'
                                  : '#c7c7c7',
                                color: isSelected ? '#ffffff' : 'inherit',
                              }}
                            />
                          );
                        })}
                      </Box>

                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        marginTop="30px"
                      >
                        Remuneration
                      </Typography>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Fee"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        value={placement.fee || ''}
                        onChange={(e) => {
                            const newPlacements = [...campaignData.placements];
                            newPlacements[index] = {
                              ...newPlacements[index],
                              fee: e.target.value,
                            };
                            setCampaignData({
                              ...campaignData,
                              placements: newPlacements,
                            });
                          }}
                        />
                         <TextField
                        fullWidth
                        margin="normal"
                        label="Product value"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        value={placement.productValue || ''}
                        onChange={(e) => {
                            const newPlacements = [...campaignData.placements];
                            newPlacements[index] = {
                              ...newPlacements[index],
                              productValue: e.target.value,
                            };
                            setCampaignData({
                              ...campaignData,
                              placements: newPlacements,
                            });
                          }}
                        />
        
                      <Box mt={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            const newPlacements = [...campaignData.placements];
                            newPlacements.splice(index, 1);
                            setCampaignData({
                              ...campaignData,
                              placements: newPlacements,
                            });
                          }}
                        >
                          Remove Placement
                        </Button>
                      </Box>
                    </Box>
                  ))}
                  <Button
                    variant="contained"
                    onClick={() =>
                      setCampaignData({
                        ...campaignData,
                        placements: [...campaignData.placements, {}],
                      })
                    }
                  >
                    Add Placement
                  </Button>
                </>
              ) : null}
        
              {campaignData.jobType === 'Product Seeding' ? (
                <>
                  <Typography
                    variant="subtitle1"
                    marginTop="30px"
                    fontWeight="bold"
                  >
                    Gifting Type
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {['Gifting Only', 'Gifting in Exchange for Content'].map(
                      (gift) => {
                        const isSelected = campaignData.giftingType === gift;
                        return (
                          <Chip
                            key={gift}
                            label={gift}
                            clickable
                            onClick={() =>
                              setCampaignData({
                                ...campaignData,
                                giftingType: gift,
                              })
                            }
                            sx={{
                              mr: 1,
                              my: 1,
                              backgroundColor: isSelected
                                ? '#11ba82'
                                : '#c7c7c7',
                              color: isSelected ? '#ffffff' : 'inherit',
                            }}
                          />
                        );
                      }
                    )}
                  </Box>
                  </>
        ) : null}

 {campaignData.jobType === 'Product Seeding' || campaignData.jobType === 'Event Attendance'? (
  <>
                  <Typography
                    variant="subtitle1"
                    marginTop="30px"
                    fontWeight="bold"
                  >
                    Preferred Platforms
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {['Instagram', 'Meta', 'TikTok', 'Youtube', 'Snap'].map(
                      (pref) => {
                        const isSelected =
                          campaignData.preferredPlatforms &&
                          campaignData.preferredPlatforms.includes(pref);
                        return (
                          <Chip
                            key={pref}
                            label={pref}
                            clickable
                            onClick={() => {
                              const current =
                                campaignData.preferredPlatforms || [];
                              const newSelection = isSelected
                                ? current.filter((p) => p !== pref)
                                : [...current, pref];
                              setCampaignData({
                                ...campaignData,
                                preferredPlatforms: newSelection,
                              });
                            }}
                            sx={{
                              mr: 1,
                              my: 1,
                              backgroundColor: isSelected
                                ? '#11ba82'
                                : '#c7c7c7',
                              color: isSelected ? '#ffffff' : 'inherit',
                            }}
                          />
                        );
                      }
                    )}
                  </Box>
        
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    marginTop="30px"
                  >
                    Campaign Objective
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {[
                      'Brand Awareness',
                      'Product Testimonial',
                      'Product Efficacy',
                      'Oranic Content',
                      'Social Proof',
                      'Traffic & Engagement',
                      'Create Hype',
                    ].map((obj) => {
                      const isSelected =
                        campaignData.placementObjective &&
                        campaignData.placementObjective.includes(obj);
                      return (
                        <Chip
                          key={obj}
                          label={obj}
                          clickable
                          onClick={() => {
                            const current =
                              campaignData.placementObjective || [];
                            const newSelection = isSelected
                              ? current.filter((o) => o !== obj)
                              : [...current, obj];
                            setCampaignData({
                              ...campaignData,
                              placementObjective: newSelection,
                            });
                          }}
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected
                              ? '#11ba82'
                              : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    })}
                  </Box>
        
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    marginTop="30px"
                  >
                    Content Usage
                  </Typography>
        
                  <Typography variant="subtitle1" marginTop="30px">
                    Republish on Brand Social
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {['Yes', 'No'].map((social) => {
                      const isSelected =
                        campaignData.contentUsage &&
                        campaignData.contentUsage.republishOnBrandSocial === social;
                      return (
                        <Chip
                          key={social}
                          label={social}
                          clickable
                          onClick={() =>
                            setCampaignData((prevData) => ({
                              ...prevData,
                              contentUsage: {
                                ...prevData.contentUsage,
                                republishOnBrandSocial: social,
                              },
                            }))
                          }
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected
                              ? '#11ba82'
                              : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    })}
                  </Box>
        
                  <Typography variant="subtitle1" marginTop="30px">
                    Republish on Brand Website
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {['Yes', 'No'].map((website) => {
                      const isSelected =
                        campaignData.contentUsage &&
                        campaignData.contentUsage.republishOnBrandWebsite === website;
                      return (
                        <Chip
                          key={website}
                          label={website}
                          clickable
                          onClick={() =>
                            setCampaignData((prevData) => ({
                              ...prevData,
                              contentUsage: {
                                ...prevData.contentUsage,
                                republishOnBrandWebsite: website,
                              },
                            }))
                          }
                          sx={{
                            mr: 1,
                            my: 1,
                            backgroundColor: isSelected
                              ? '#11ba82'
                              : '#c7c7c7',
                            color: isSelected ? '#ffffff' : 'inherit',
                          }}
                        />
                      );
                    })}
                  </Box>
                </>
              ) : null}
            </Box>
          );
        
      case 4: 
        return (
          <Box>
            <FormControl component="fieldset" margin="normal">
              <Typography variant="subtitle1" fontWeight="bold">
                Category Exclusivity
              </Typography>
             
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Yes', 'No'].map((exlusivity) => {
      const isSelected =
        campaignData.categoryExclusivity &&
        campaignData.categoryExclusivity.includes(exlusivity);
      return (
        <Chip
        exclusive
          key={exlusivity}
          label={exlusivity}
          clickable
          onClick={() => {
            const current = campaignData.categoryExclusivity || [];
            const newSelection = isSelected
              ? current.filter((a) => a !== exlusivity)
              : [...current, exlusivity];
            setCampaignData({
              ...campaignData,
              categoryExclusivity: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Competitor Exclusivity (list specific brands)"
              value={campaignData.competitorExclusivity || ''}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  competitorExclusivity: e.target.value,
                })
              }
            />
            <FormControl fullWidth margin="normal">

            <Typography variant="subtitle1" fontWeight="bold">
                Territory Exclusivity
              </Typography>
             
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Country', 'Region', 'Global'].map((exlusivityT) => {
      const isSelected =
        campaignData.territoryExclusivity &&
        campaignData.territoryExclusivity.includes(exlusivityT);
      return (
        <Chip
        exclusive
          key={exlusivityT}
          label={exlusivityT}
          clickable
          onClick={() => {
            const current = campaignData.territoryExclusivity || [];
            const newSelection = isSelected
              ? current.filter((a) => a !== exlusivityT)
              : [...current, exlusivityT];
            setCampaignData({
              ...campaignData,
              territoryExclusivity: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>



            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              type="number"
              label="Exclusivity Period in Months"
              value={campaignData.exclusivityPeriod || ''}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  exclusivityPeriod: e.target.value,
                })
              }
            />
          </Box>
        );
      case 5:
        return (
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">Campaign Expectations</Typography>
            {campaignData.jobType === 'Paid Influencers' ? (
              <>
            <TextField
              fullWidth
              margin="normal"
              label="Content Revisions"
              type="number"
              value={campaignData.contentRevisions}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  contentRevisions: e.target.value,
                })
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Performance Metrics (e.g. eCPM, CPC, CPA, etc.)"
              value={campaignData.performanceMetrics || ''}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  performanceMetrics: e.target.value,
                })
              }
            />
<Box>
  {/* Reporting Required */}
  <Typography variant="subtitle1">Reporting Required</Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Yes', 'No'].map((option) => {
      const isSelected = campaignData.reportingRequired === option;
      return (
        <Chip
          key={option}
          label={option}
          clickable
          onClick={() =>
            setCampaignData({ ...campaignData, reportingRequired: option })
          }
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box></Box>
  </> 
  ) : null}
<Box>


  <Typography variant="subtitle1" marginTop="16px">
    Remuneration
  </Typography>
  <TextField
              fullWidth
              margin="normal"
              label="Value of Product"
              type="number"
              value={campaignData.productValue}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  productValue: e.target.value,
                })
              }
            />

  <Typography variant="subtitle1" marginTop="16px">
    Paid Disclosure
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Yes', 'No'].map((option) => {
      const isSelected = campaignData.paidDisclosure === option;
      return (
        <Chip
          key={option}
          label={option}
          clickable
          onClick={() =>
            setCampaignData({ ...campaignData, paidDisclosure: option })
          }
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>

  {/* Brand Tagging */}
  <Typography variant="subtitle1" marginTop="16px">
    Brand Tagging
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Yes', 'No'].map((option) => {
      const isSelected = campaignData.brandTagging === option;
      return (
        <Chip
          key={option}
          label={option}
          clickable
          onClick={() =>
            setCampaignData({ ...campaignData, brandTagging: option })
          }
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>

  {/* Include Shoppable Links */}
  <Typography variant="subtitle1" marginTop="16px">
    Include Shoppable Links
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Yes', 'No'].map((option) => {
      const isSelected = campaignData.shoppableLinks === option;
      return (
        <Chip
          key={option}
          label={option}
          clickable
          onClick={() =>
            setCampaignData({ ...campaignData, shoppableLinks: option })
          }
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>

  {/* Interactivity */}
  <Typography variant="subtitle1" marginTop="16px">
    Interactivity (Polls, Surveys, Q+As)
  </Typography>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Yes', 'No'].map((option) => {
      const isSelected = campaignData.interactivity === option;
      return (
        <Chip
          key={option}
          label={option}
          clickable
          onClick={() =>
            setCampaignData({ ...campaignData, interactivity: option })
          }
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>

  {/* Other / Final Comments */}
  <TextField
    fullWidth
    margin="normal"
    label="Other / Final Comments"
    multiline
    rows={4}
    value={campaignData.finalComments || ''}
    onChange={(e) =>
      setCampaignData({
        ...campaignData,
        finalComments: e.target.value,
      })
    }
  />
</Box>

          </Box>
        );
        case 6: 
          return (
            <Box>
            {/* Location */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Location
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Location"
              value={campaignData.location || ''}
              onChange={(e) =>
                setCampaignData((prev) => ({
                  ...prev,
                  location: e.target.value,
                }))
              }
            />
      
            {/* Time Arrival */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Time Arrival
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Time Arrival"
              value={campaignData.timeArrival || ''}
              onChange={(e) =>
                setCampaignData((prev) => ({
                  ...prev,
                  timeArrival: e.target.value,
                }))
              }
            />
      
            {/* Time Sensitive */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Time Sensitive
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['Yes', 'No'].map((option) => {
                const isSelected = campaignData.timeSensitive === option;
                return (
                  <Chip
                    key={option}
                    label={option}
                    clickable
                    onClick={() =>
                      setCampaignData((prev) => ({
                        ...prev,
                        timeSensitive: isSelected ? '' : option,
                      }))
                    }
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
      
            {/* Time Flexibility */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Time Flexibility
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['Flexible', 'Not Flexible'].map((option) => {
                const isSelected = campaignData.timeFlexibility === option;
                return (
                  <Chip
                    key={option}
                    label={option}
                    clickable
                    onClick={() =>
                      setCampaignData((prev) => ({
                        ...prev,
                        timeFlexibility: isSelected ? '' : option,
                      }))
                    }
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
      
            {/* Event Type */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Event Type
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {eventTypes.map((option) => {
                const isSelected = campaignData.eventType === option;
                return (
                  <Chip
                    key={option}
                    label={option}
                    clickable
                    onClick={() =>
                      setCampaignData((prev) => ({
                        ...prev,
                        eventType: isSelected ? '' : option,
                      }))
                    }
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
      
            {/* Dress Code */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Dress Code
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Dress Code"
              value={campaignData.dressCode || ''}
              onChange={(e) =>
                setCampaignData((prev) => ({
                  ...prev,
                  dressCode: e.target.value,
                }))
              }
            />
      
            {/* Perks */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Perks
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {perksOptions.map((option) => {
                const current = campaignData.perks || [];
                const isSelected = current.includes(option);
                return (
                  <Chip
                    key={option}
                    label={option}
                    clickable
                    onClick={() => {
                      const newSelection = isSelected
                        ? current.filter((item) => item !== option)
                        : [...current, option];
                      setCampaignData((prev) => ({
                        ...prev,
                        perks: newSelection,
                      }));
                    }}
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
      
            {/* Compensation */}
            <Typography variant="subtitle1" fontWeight="bold" mt={2}>
              Compensation
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {compensationOptions.map((option) => {
                const isSelected = campaignData.compensation === option;
                return (
                  <Chip
                    key={option}
                    label={option}
                    clickable
                    onClick={() =>
                      setCampaignData((prev) => ({
                        ...prev,
                        compensation: isSelected ? '' : option,
                      }))
                    }
                    sx={{
                      mr: 1,
                      my: 1,
                      backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                      color: isSelected ? '#ffffff' : 'inherit',
                    }}
                  />
                );
              })}
            </Box>
          </Box>
           

    
          );
   
      default:
        return 'Unknown step';
    }
  }};

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel>
              {completed[index] ? <span>&#10003;</span> : label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
        >
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>

      <Dialog
        open={newBrandModalOpen}
        onClose={() => setNewBrandModalOpen(false)}
      >
        <DialogTitle>Create New Brand</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the new brand details below.
          </DialogContentText>
          <TextField
                          label="Company Name"
                          variant="outlined"
                          fullWidth
                          name="company_name"
                          value={brandData.company_name}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      
                        <TextField
                          label="Company URL"
                          variant="outlined"
                          fullWidth
                          name="company_URL"
                          value={brandData.company_URL}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                     
                        <TextField
                          label="Company About"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          name="company_about"
                          value={brandData.company_about}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
            
                        
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewBrandModalOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateBrandSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    </Box>



  );
};

export default CreateCampaignStepper;
