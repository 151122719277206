export const eventTypes = [
    'Red carpet',
    'VIP gala',
    'Product launch',
    'Pop-up Activation',
    'Movie',
    'Theatre Production',
    'Exhibition',
    'Fashion Show',
    'Award Ceremony',
    'PR Stunt',
    'Celebrity Meet and Greet',
    'Sponsorship Event',
    'Workshop / Conference',
    'Charity Event',
  ];

 export const perksOptions = [
    'Private Transport',
    'Accomodation',
    'Food',
    'Brand Gifts',
  ];