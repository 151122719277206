import React from 'react';
import { Box, Typography, Avatar, Grid, Chip, Card, CardContent } from '@mui/material';

const CreatorDetailCard = ({ userData }) => {
  if (!userData) return null;

  return (
    <Card sx={{ p: 2, borderRadius: 4, boxShadow: 2 }}>
      <CardContent>
        <Grid container spacing={2}>
          {/* Left Side: Avatar and Basic Info */}
          <Grid item xs={12} sm={3}>
            <Avatar
              src={userData.photoURL}
              alt={userData.displayName}
              sx={{ width: 120, height: 120, mb: 2 }}
            />
            <Typography variant="h6" fontWeight="bold">
              {userData.displayName || 'Full Name'}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {userData.address || 'Address'}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              Link to Media Kit
            </Typography>
          </Grid>

          {/* Right Side: Metrics and Content */}
          <Grid item xs={12} sm={9}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              {userData.socialAccounts?.map((account, index) => (
                <Box key={index} textAlign="center">
                  <Typography variant="h6">{account.platform}</Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {account.followers} Followers
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    +{account.weeklyGrowth} likes/week
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle2">Avg Views</Typography>
                <Typography>100,000</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">ENR%</Typography>
                <Typography>2.3%</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">Viewability</Typography>
                <Typography>52%</Typography>
              </Box>
            </Box>

            {/* Charts Section */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <Typography variant="h6">Follower Growth</Typography>
                {/* Replace with actual chart */}
                <Box sx={{ height: 200, backgroundColor: '#f0f0f0', borderRadius: 2 }}>
                  Follower Growth Chart Placeholder
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Gender Split</Typography>
                {/* Replace with actual chart */}
                <Box sx={{ height: 200, backgroundColor: '#f0f0f0', borderRadius: 2 }}>
                  Gender Split Chart Placeholder
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreatorDetailCard;
