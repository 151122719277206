// YourAIAssessment.jsx
import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';

const YourAIAssessment = ({ followers = 0 }) => {
  // Dummy logic to calculate recommended fee and success likelihood based on followers.
  const recommendedFee = Math.round(followers * 0.005);

  let successLikelihood = 60;
  if (followers < 10000) {
    successLikelihood = 60;
  } else if (followers < 50000) {
    successLikelihood = 75;
  } else {
    successLikelihood = 90;
  }

  // Determine background color based on success likelihood
  let backgroundColor;
  if (successLikelihood > 75) {
    backgroundColor = '#C8E6C9'; // Light Green
  } else if (successLikelihood >= 50) {
    backgroundColor = '#FFECB3'; // Amber
  } else {
    backgroundColor = '#FFCDD2'; // Red
  }

  const handleRecalculate = () => {
    // Integrate API call or advanced logic later.
    alert('Recalculating based on latest data...');
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 3,
        borderRadius: 2,
        backgroundColor: backgroundColor,
      }}
    >
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Your AI Assessment
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="body1">
            <strong>Recommended Fee:</strong> ${recommendedFee}
          </Typography>
          <Typography variant="body1">
            <strong>Success Likelihood:</strong> {successLikelihood}%
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" 
          onClick={handleRecalculate}
          sx={{
            mr: 2,
          }}>
                  Recalculate
          </Button>
          <Button variant="contained" onClick={handleRecalculate}>
            Apply With Recommended Fee
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default YourAIAssessment;
