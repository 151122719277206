import React, { useState, useEffect, useRef } from 'react';
import { 
  Button,
  CircularProgress,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import TaskActions from './TaskActions';


const TasksOverview = ({ taskData, jobId, refreshTasks, openContract }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentTaskId, setCurrentTaskId] = useState('');
  const fileInputRef = useRef(null);
  const [taskActionLoading, setTaskActionLoading] = useState(false);
  const [currentUploadField, setCurrentUploadField] = useState('');
  const allowedTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'image/gif',
    'video/mp4',
    'video/quicktime'
  ];



  useEffect(() => {
    if (taskData) {
      setIsLoading(false);
    }
  }, [taskData]);



  const handleTaskAction = async (task, actionType) => {
    let taskUpdate;
    switch (actionType) {
      case 'signContract':
        setCurrentTaskId(task._id);
        if (openContract) openContract();
        break;
      case 'markDone':
        setTaskActionLoading(true);
        setCurrentTaskId(task._id);
        taskUpdate = { status: 'Completed', taskId: task._id };
        await updateTask(taskUpdate);
        setTaskActionLoading(false);
        break;
      case 'uploadContentConcepts':
        console.log("case is uploadContentConcepts", jobId);
        setCurrentTaskId(task._id);
        setCurrentUploadField("contentConceptsUrls");
        fileInputRef.current.click();
        break;
      case 'uploadCreative':
        console.log("case is uploadCreative", jobId);
        setCurrentTaskId(task._id);
        setCurrentUploadField("creativeUrls");
        fileInputRef.current.click();
        break;
      case 'send':
        console.log("case is mark sent");
        setCurrentTaskId(task._id);
        setTaskActionLoading(true);
        taskUpdate = { status: 'Completed', taskId: task._id };
        await updateTask(taskUpdate);
        setTaskActionLoading(false);
        break;
      default:
        console.warn(`No action defined for: ${actionType}`);
        break;
    }
  };
  
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file || !currentTaskId) return;
  
    if (!allowedTypes.includes(file.type)) {
      alert('Please upload a document in DOC, DOCX, or PDF format.');
      return;
    }
  
    await handleFileUpload(currentTaskId, file, currentUploadField);
  };


  
  const handleFileUpload = async (taskId, file, jobField) => {
    console.log(jobField);
     if (!file) return;
    console.log('Uploading file:', file);
    try {
      const response = await axios.post(
        'https://crowdswell.nucleotto.com/api/blob/uploadFile',
        file,
        {
          headers: {
            'Content-Type': file.type || 'application/octet-stream',
            'X-Filename': file.name,
          },
        }
      );

      if (response.data.url) {
        const blobUrl = response.data.url;
        console.log('File uploaded:', blobUrl);
        const update = { [jobField]: blobUrl };
        const auth = getAuth();
        const user = auth.currentUser;
      if (user) {
        const accessToken = await user.getIdToken();
        await axios.put(
          `https://ostendo.nucleotto.com/api/job/update?jobId=${jobId}`,  // Ensure you have jobId available
          { update },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            },
          }
        );
  
        console.log('Job updated with creativeBriefUrl');

        const taskUpdate = {
          status: 'Completed',
          taskId: currentTaskId,
        };

        await updateTask(taskUpdate);
       

      }}
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };


const updateTask = async (taskUpdate) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const update = taskUpdate  
    if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.post(
        `https://ostendo.nucleotto.com/api/tasks/update`,
        update,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (refreshTasks) refreshTasks();
      }
  }}  
  catch (error) {
    console.error('Error updating Job:', error);
  }
};

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return { bgcolor: '#e8f5e9', color: '#2e7d32' };
      case 'In Progress':
        return { bgcolor: '#fff3e0', color: '#ef6c00' };
      case 'Pending':
        return { bgcolor: '#f5f5f5', color: '#757575' };
      default:
        return { bgcolor: '#f5f5f5', color: '#757575' };
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple
      />
      
      <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 3 }}>
        <Table aria-label="tasks table">
          <TableHead sx={{ bgcolor: 'background.paper' }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Task Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Assignee</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskData?.length > 0 ? (
              taskData.map((task, index) => (
                <TableRow 
                  key={index}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{task.taskName}</TableCell>
                  <TableCell>{task.type}</TableCell>
                  <TableCell>
                    <Chip
                      label={task.status}
                      sx={{ 
                        ...getStatusColor(task.status),
                        borderRadius: 1,
                        fontWeight: 500,
                        px: 1.5,
                        py: 0.5
                      }}
                    />
    
                  </TableCell>
                  <TableCell>
                    {/* Use the TaskActions component to render custom actions for the task */}
                    <TaskActions task={task} 
                    setCurrentTaskId={setCurrentTaskId}
                    customActionHandler={handleTaskAction}
                    fileInputRef={fileInputRef}
                    taskActionLoading={taskActionLoading} />
                  </TableCell>
                </TableRow>
                
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} sx={{ textAlign: 'center', py: 4 }}>
                  <Typography variant="body2" color="textSecondary">
                    No tasks available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TasksOverview;