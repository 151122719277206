export const contractText = `
Goulash Enterprises PTY LTD, t/a Looksee International (“Looksee”)

And

Influencer Name/Handle (collectively the “Influencer”).

1. Parties: This Agreement is made between the campaign Advertiser (the ‘Advertiser’) and Looksee, appointing the Influencer for marketing services in accordance with the campaign requirements and deliverables outlined in the approved media plan.

2. Contract Term: The appointment period is outlined in the Campaign Date.

3. Campaign Content: The Influencer will deliver Marketing Services based on the requirements and inclusions, instructions, creative guidelines, content specifications, and timeframes outlined in the campaign Job.

4. Variations: Looksee can change campaign requirements via email, the platform, or any messaging platform, and these changes are valid.

5. Transparency: All Campaign Content should be truthful and labelled with the appropriate disclosures in accordance with relevant legislative or self-regulating governing body guidelines.

6. Approval Process: Looksee must approve Campaign Content before publication, and the influencer agrees not to publicly publish any Campaign Content without receipt of Looksee’s approval. Rejected content requires Influencers to resubmit new Campaign Content in a timely manner based on Looksee feedback and instructions.

7. Termination: Looksee can terminate the Agreement without notice and without payment of the Campaign Fee if:
   a. The Influencer fails to fulfil any of the Campaign Content commitments.
   b. Looksee determines that the Influencer’s conduct is becoming detrimental to the campaign performance.
   c. The Influencer is negatively impacting the relationship between Looksee and the Advertiser.

8. Campaign Fee: The Influencer is paid after delivering Campaign Content as per Looksee's expectations, and in accordance with the Campaign Job. Invoicing and payment are 30 business days, pending payment from the Advertiser is received by Looksee.

9. Campaign Conduct: The Influencer agrees to deliver the Marketing Services to the best of their ability, while also adhering to the following conduct guidelines. Failure to comply with these guidelines may result in the Termination of this Agreement, and Looksee reserves the right to take legal action or seek compensation for any damages arising from the Influencer's actions:
   a. Positive Representation: The Influencer agrees not to make any comments, acts, behaviours, or actions that may bring harm or draw negativity to the reputation and public standing of Looksee or the Advertiser.
   b. Avoidance of Discrimination: The Influencer must refrain from publishing any social media content, blog posts, statuses, tweets, and/or comments that contain bigotry, racism, or discrimination based on race, gender, religion, nationality, disability, sexual orientation, or age.
   c. Legal and Moral Standards: The Influencer must not commit an offence involving moral turpitude under Federal, state, or local laws or regulations. Furthermore, the Influencer should refrain from any act or thing that could incite public hatred, public disrepute, contempt, scorn, or ridicule, or that could shock, insult, or offend the community or public morals or decency.
   d. Truthful Representation: Misrepresentation of the size of the audience or artificial manipulation of engagements in your social content, or in relation to your Campaign Content, is strictly prohibited.
   e. Non-Harm to Looksee and Affiliates: The Influencer must not engage in any activities that would prejudice or cause harm to Looksee and the Advertiser, its employees, officers, directors, affiliates, and/or subsidiaries.
   f. Campaign Delivery: The Influencer’s conduct relating to the marketing services and campaign delivery must not negatively impact the relationship between Looksee and the Advertiser.

10. Copyright Compliance: Influencers should not use unapproved references to third parties or third-party property, including music, in their Campaign Content. They will not hold Looksee or the Advertiser liable for any breaches of the Copyright Act.

11. Independent Contractor: The Influencer is a self-employed individual or company retained as an independent contractor of Looksee and agrees;
    a. They are solely responsible for the manner and form by which the Influencer performs under this Agreement; and
    b. They are responsible for the withholding and payment of all taxes and other assessments arising out of this Agreement.

12. Intellectual Property: Ownership of the Intellectual Property created in the Campaign Content will remain the property of the Influencer. The Influencer grants Looksee and the Advertiser a royalty-free, worldwide, irrevocable and unconditional licence to use the Campaign Content for any marketing and promotional purposes in any manner, without further notification to, or consent of, the Influencer, or any further compensation payable to the Influencer.

13. The Influencer warrants that they are the owner of, or otherwise have legal rights to, the Intellectual Property licenced to Looksee pursuant to Clause 11, and herein indemnify Looksee and the Advertiser in relation to any 3rd party infringement lawsuit, action, loss or damages claim arising in connection with this Agreement.

14. Campaign Breach: Looksee can suspend or terminate the Agreement if the Influencer engages in actions that breach the Agreement. Looksee reserves the right to determine the breach based on the severity of the actions, and such determination is final. In case of a breach, Looksee may take immediate action to suspend or terminate the Agreement, in addition to any other rights or remedies available under the Agreement or applicable law. Acts or behaviour that constitutes a breach include, but are not limited to:
    a. Immoral, Deceptive, Scandalous, or Obscene Behaviour: In the course of fulfilling this Agreement, if the Influencer commits any act or engages in behaviour that is immoral, deceptive, scandalous, or obscene.
    b. Lack of Capacity: If the Influencer lacks the mental or physical capacity to deliver the Campaign Content as expected by Looksee.
    c. Damage to Reputation: If the Influencer tarnishes, damages, or adversely affects the reputation and goodwill of Looksee and the Advertiser, including its employees, officers, directors, affiliates, or subsidiaries.
    d. Unsatisfactory work: If the Influencer delivers work determined by Looksee that fails to meet Campaign Content and/or Campaign Job requirements.

15. Termination Process: Either party can terminate with 10 days' notice for a breach unless the relevant Party does not remedy such breach within a reasonable time period. In addition to any right or remedy that may be available to Looksee under this Agreement or applicable law, Looksee may instruct the Influencer to cease all promotional activities or make clarifying statements, and the Influencer shall immediately comply with such instructions.

16. Force Majeure: Either party is excused from performance due to uncontrollable events.

17. Communication: During the term of the contract, or for at least six months after the contract terms ends, unless granted express permission from Looksee, the influencer agrees not to directly communicate with the Advertiser, or their advertising affiliates, in relation to the Campaign Content or Marketing Services.

18. Indemnity: The Influencer agrees to indemnify Looksee against any and all 3rd Party claims as a result of a breach of this Agreement, except to the extent that such claims have resulted from gross negligence, fraud, bad faith or wilful misconduct of Looksee.

19. Choice of Law: The Agreement is governed by New South Wales law.

20. Legal Advice: Parties acknowledge the approved Campaign Job requirements, and have entered into the Agreement willingly.
`;
