import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid2,
  Divider,
  Container,
  CircularProgress,
} from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../api/userApi';
import GoogleSignIn from '../../components/auth/GoogleSignInButton';
import FacebookSignIn from '../../components/auth/FacebookSignInButton';


const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [token, setToken] = useState('');
  const [signInLoading, setSignInLoading] = useState(false);
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Please fill in all fields.');
      return;
    }
    setSignInLoading(true);
    try {
      const signIn = await signInWithEmailAndPassword(auth, email, password);
      const userId = signIn.user.uid;
      const storage = rememberMe ? localStorage : sessionStorage;
      storage.setItem('userId', userId);
      storage.setItem('email', email);

      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      const brand_id = userRecord.result.brandId;
      if (brand_id) {
        localStorage.setItem('brand_id', brand_id);
      }
      localStorage.setItem('user_type', user_type);

      navigate(user_type === 'creator' ? '/creators/dashboard' : '/campaigns');
    } catch (err) {
      console.error(err);
      setSignInLoading(false);
      setError(getErrorMessage(err.code));
    }
  };

  const handleSignUpClick = () => {
    navigate('/register');
  };

  const handleSignInLoading = (loading) => {
    setSignInLoading(true);
  };

  const getErrorMessage = (code) => {
    switch (code) {
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/user-not-found':
        return 'No account found with this email.';
      default:
        return 'An error occurred. Please try again.';
    }
  };

if (signInLoading) {
    return  (
      <Container align="center">
         <Grid2 container sx={{ height: '100vh', width: { xs: '98%', md: '75%', xl: '55%' }, display: 'flex', alignItems: 'center', justifyContent: 'center', px: { xs: 2, md: 5 } }}>
        <Grid2 item align='center' xs={12} md={6} sx={{ p: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ mb: 5 }}>
      <CircularProgress />
      </Box></Grid2></Grid2>
      </Container>

    );
  }

  return (
    <Container align="center">
      <Grid2 container sx={{ height: '100vh', width: { xs: '98%', md: '75%', xl: '55%' }, display: 'flex', alignItems: 'center', justifyContent: 'center', px: { xs: 2, md: 5 } }}>
        <Grid2 item align='center' xs={12} md={6} sx={{ p: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 3 }}>CrowdSwell</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Developer Version 2.1
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <GoogleSignIn onSignInLoading={handleSignInLoading}/>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <FacebookSignIn onSignInLoading={handleSignInLoading} />
          </Box>

          <Divider sx={{ my: 2 }}>OR</Divider>

          <Box component="form" onSubmit={handleSignIn}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              slotProps={{
                input: { 'aria-label': 'Email Address' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                    borderRadius: '8px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#333',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'black',
                },
                '& .MuiInputLabel-root': {
                  color: 'black',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'black',
                },
              }}
            />
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              slotProps={{
                input: { 'aria-label': 'Password' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                    borderRadius: '8px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#333',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'black',
                },
                '& .MuiInputLabel-root': {
                  color: 'black',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'black',
                },
              }}
            />

            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
                label="Remember Me"
              />
              <Button href="#" color="primary">
                Forget Password?
              </Button>
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 3,
                py: 1.5,
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#333', // Darker shade on hover
                },
              }}
            >
              Sign in
            </Button>
          </Box>

          <Typography variant="body2" sx={{ mt: 2 }}>
            Don’t have an account? <Button onClick={handleSignUpClick}>Sign Up</Button>
          </Typography>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default LoginPage;