import React from 'react';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../api/userApi';

const GoogleSignInButton = ({onSignInLoading}) => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    onSignInLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const userId = result.user.uid;
      localStorage.setItem('userId', userId);
      localStorage.setItem('email', result.user.email);
      localStorage.setItem('display_name', result.user.displayName);

      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      localStorage.setItem('user_type', user_type);

      navigate(user_type === 'creator' ? '/creators/dashboard' : '/campaigns');
    } catch (error) {
      console.error(error);
    } finally {
        onSignInLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleGoogleSignIn}
      startIcon={<GoogleIcon />}
      sx={{
        width: '100%',
        marginBottom: '10px',
        backgroundColor: '#4285F4',
        '&:hover': {
          backgroundColor: '#3578E5',
        },
      }}
    >
      Sign in with Google
    </Button>
  );
};

export default GoogleSignInButton;