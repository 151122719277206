import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CssBaseline,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button, 
  TextField,
} from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { contractText } from './contract/template';
import AdminTasksOverview from '../../components/widgets/tasks/AdminTasksOveriew';
import CreatorFilesSummaryCards from '../../components/widgets/creators/FilesSummaryTable';


const AdminJobDetailPage = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState({});
  const [campaignDetails, setCampaignDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [comment, setComment] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [taskData, setTaskData] = useState([]);

  useEffect(() => {
    fetchJobDetails(jobId);
    fetchTasks(jobId);
  }, [jobId]);

  const fetchJobDetails = async (jobId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const accessToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/job/get?jobId=${jobId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setJobDetails(response.data.job || {});
        const campaignId = response.data.job.campaignId;
        await fetchCampaignDetails(campaignId);
      }
    } catch (error) {
      console.error('Error fetching job:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCampaignDetails = async (campaignId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/campaign/list?jobId=${campaignId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setCampaignDetails(response.data.jobs[0] || {});
      }
    } catch (error) {
      console.error('Error fetching campaign:', error);
    }
  };

  const fetchTasks = async (jobId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const accessToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/tasks/get?jobId=${jobId}&type=Admin`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log('Tasks:', response.data.tasks);
        const tasks = response.data.tasks || [];
       
      setTaskData(tasks);
    }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };




const updateJob = async (update) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.put(
        `https://ostendo.nucleotto.com/api/job/update?jobId=${jobId}`,
        update,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
     }}  
  catch (error) {
    console.error('Error updating Job:', error);
  }
};

const completeContract = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const update = {
      status: 'Completed',
    };

    if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.put(
        `https://ostendo.nucleotto.com/api/tasks/update`,
        update,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      if (response.status === 200) {
      await fetchTasks(jobId);
    }
  }}  
  catch (error) {
    console.error('Error updating Job:', error);
  }
};





  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Typography variant="h4" gutterBottom>
              Job Details
            </Typography>
            <Typography variant="h6">Campaign Name: {jobDetails.campaignName}</Typography>
            <Typography variant="body1">Total Agreed Rate: ${jobDetails.totalAgreedRate}</Typography>
            <Typography variant="body1">Offer Status: {jobDetails.offerStatus}</Typography>
            <Typography variant="body1">
              Application Date: {new Date(jobDetails.applicationDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body1">Status: {jobDetails.status}</Typography>
            <Typography variant="body1">Contract: {jobDetails.contractStatus}</Typography>


            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Placements Applied To
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Placement ID</strong></TableCell>
                    <TableCell><strong>Proposed Fee</strong></TableCell>
                    <TableCell><strong>Status</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobDetails.placementsAppliedTo && jobDetails.placementsAppliedTo.map((placement) => (
                    <TableRow key={placement.placementId}>
                      <TableCell>{placement.placementId}</TableCell>
                      <TableCell>${placement.proposedFee}</TableCell>
                      <TableCell>{placement.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Tasks
            </Typography>
            <AdminTasksOverview 
            taskData={taskData}
            jobId={jobId}
            refreshTasks={() => fetchTasks(jobId)}
            refreshJobDetails={() => fetchJobDetails(jobId)}/>
            
           <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Files
            </Typography>
                  <CreatorFilesSummaryCards 
                  jobDetails={jobDetails}
                   />
          </Box>

       </Container>
      </Box>
    </Box>
  );
};

export default AdminJobDetailPage;
