import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Button,
  IconButton,
  Collapse,
  Avatar,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateUserAdmin from '../../components/widgets/buttons/createUserAdmin';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getAllUsers } from '../../api/creatorApi';
import CreatorDetailCard from '../../components/widgets/creators/ProfileWidget';


const CreatorsListPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filterUserType, setFilterUserType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const userType = localStorage.getItem('user_type');
      if (user && userType === 'admin') {
        fetchUsers();
      } else {
        navigate('/logout');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchUsers = async () => {
    try {
      const users = await getAllUsers();
      setUsersData(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterUserType(event.target.value);
  };

  const toggleRow = (userId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  const filteredUsers = usersData.filter((user) =>
    (user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.user_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.displayName?.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (filterUserType === '' || user.user_type === filterUserType)
  );

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3, textAlign: 'center' }}>
          <Typography variant="h6">Loading...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
          Creators
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
           
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Search Users"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Grid>
            </Grid>
       
            <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2, mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Name</strong></TableCell>
                    <TableCell><strong>Followers</strong></TableCell>
                    <TableCell><strong>Platforms</strong></TableCell>
                    <TableCell><strong>Category</strong></TableCell>
                    <TableCell><strong>Actions</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <React.Fragment key={user.userId}>
                      <TableRow>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar src={user.photoURL} sx={{ mr: 2 }} />
                            {user.displayName}
                          </Box>
                        </TableCell>
                        <TableCell>{user.totalFollowers || 'N/A'}</TableCell>
                        <TableCell>
                          {user.platforms?.map((platform) => (
                            <Chip key={platform} label={platform} sx={{ mr: 1, mb: 1 }} />
                          ))}
                        </TableCell>
                        <TableCell>
                          {user.categories?.map((category) => (
                            <Chip key={category} label={category} sx={{ mr: 1, mb: 1 }} />
                          ))}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => toggleRow(user.userId)}
                          >
                            {expandedRows[user.userId] ? 'Hide Details' : 'View Profile'}
                          </Button>
                        </TableCell>
                      </TableRow>
                      {expandedRows[user.userId] && (
  <TableRow>
    <TableCell colSpan={5}>
      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
        <CreatorDetailCard userData={user} />
      </Box>
    </TableCell>
  </TableRow>
)}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CreatorsListPage;
