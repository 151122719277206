// BudgetOverview.jsx
import React from 'react';
import { Box, Typography, LinearProgress, Paper } from '@mui/material';

const BudgetOverview = ({ totalBudget, influencers }) => {
  // Calculate the percentage of budget spent (ensuring totalBudget > 0)

  const acceptedBudget = influencers
  .filter((influencer) => influencer.status === 'Accepted')
  .reduce(
    (acc, influencer) => acc + (Number(influencer.totalCost) || 0),
    0
  );


  const progress = totalBudget ? Math.min((acceptedBudget / totalBudget) * 100, 100) : 0;

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Budget Overview
      </Typography>
      <Typography variant="body1">
        Total Budget: ${totalBudget || 0}
      </Typography>
      <Typography variant="body1">
        Accepted Budget: ${acceptedBudget || 0}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <LinearProgress variant="determinate" value={progress} />
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          {Math.round(progress)}% of budget spent
        </Typography>
      </Box>
    </Paper>
  );
};

export default BudgetOverview;
