import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Badge,
  CssBaseline, 
  Container, 
  Typography, 
  Button, 
  Grid2, 
  Chip, 
  Skeleton,
  CircularProgress 
} from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { updateCampaign } from '../../api/campaignsApi';
import { statusColors } from '../../config/status';

import PaidInfluencerCampaignDetail from '../../components/widgets/campaigns/PaidInfluencers/CampaignDetails';
import ProductSeedingCampaignDetail from '../../components/widgets/campaigns/ProductSeeding/CampaignDetails';

const CampaignDetail = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [placementData, setPlacementData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [applicationNumber, setApplicationNumber] = useState(0);
  const user_type = localStorage.getItem('user_type');
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [jobs, setJobs] = useState([]);
  const { jobId } = useParams();
  console.log(jobId);

  useEffect(() => {
    fetchCampaignDetails();
    getPlacements();
    getApplications();
    getJobs();
  }, []);


  const renderField = (label, value) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1">{value || '-'}</Typography>
    </Box>
  );

  useEffect(() => {
 console.log("PlacementData",placementData)
  }, [placementData]);

  const fetchCampaignDetails = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/campaign/list?jobId=${jobId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log(response.data.jobs[0]);
        setCampaignDetails(response.data.jobs[0] || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlacements = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/placement/list',
          { campaignId: jobId },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        
        setPlacementData(response.data.placements || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getApplications = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/application/list',
          { campaignId: jobId },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const applications = response.data.applications || [];
        const filteredApplications = applications.filter(application => application.status !== 'Accepted');
        setApplicationNumber(filteredApplications.length);
        setApplicationData(filteredApplications); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setApplicationNumber(0);
    } finally {
      setIsLoading(false);
    }
  };

  const getJobs = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/job/list?campaignId=${jobId}`, 
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setJobs(response.data.jobs || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePublish = async (e) => {
    setIsPublishLoading(true);
    e.preventDefault();

    const nextStatus = campaignDetails.status === 'Live' ? 'Paused' : 'Live';

    const updatedCampaignData = {
      status: nextStatus,
    };

    const submitCampaign = await updateCampaign(jobId, updatedCampaignData);

    console.log(submitCampaign);
    if (submitCampaign) {
      window.location.reload(); // Refresh the page after successful submission
    }

    setIsPublishLoading(false); // Stop loading
  };

  const handleEditCampaign = async () => {
    navigate(`/campaigns/edit/${jobId}`, { state: { campaignDetails } });
  };

  const handleViewCampaignDetails = async () => {
    if(campaignDetails.jobType === 'Paid Influencers') {
    navigate(`/campaigns/detail/${jobId}/settings`, { state: { campaignDetails } });
    }
  };

  const handleDuplicateCampaign = async () => {
    const duplicateCampaignData = campaignDetails;
    navigate(`/campaigns/create`, { state: { duplicateCampaignData } });
  };

  const viewApplications = async () => {
    navigate('/campaigns/applications', { state: { campaignDetails, placementData, applicationData } });
  };

  const influencers = [
    {
    _id: '1',
    name: 'Jason Bourne',
    status: 'Invited',
    followers: '26k',
    eCPV: '0.04',
    eCPE: '0.24'
  },
  {
    _id: '2',
    name: 'Jessica Rabbit',
    status: 'Invited',
    followers: '180k',
    eCPV: '0.005',
    eCPE: '0.03'
  },
  {
    _id: '1',
    name: 'Steve Wozniak',
    status: 'Accepted',
    totalCost: '1000',
    followers: '12k',
    eCPV: '0.04',
    eCPE: '0.24'
  },
  {
    _id: '2',
    name: 'Mr Robot',
    status: 'Accepted',
    totalCost: '1000',
    followers: '200k',
    eCPV: '0.005',
    eCPE: '0.03'
  },
  {
    _id: '1',
    name: 'Eva Strong',
    status: 'Pending',
    totalCost: '500',
    followers: '6k',
    eCPV: '0.04',
    eCPE: '0.24'
  },
  {
    _id: '2',
    name: 'Luke Norwich',
    status: 'Pending',
    totalCost: '2500',
    followers: '500k',
    eCPV: '0.005',
    eCPE: '0.03'
  },

]

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      {isLoading ? (
         <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '90%', ml: 10, mt: 20 }}>
         <CircularProgress />
       </Grid2> 
      ) : (
        <>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Container maxWidth="xxl">
              
              <Box>
                <ProfileAvatar />
           </Box>

                {/* Title and Buttons */}
  
               <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        

                           

                              <Button
                                variant="contained"
                                onClick={handleViewCampaignDetails}
                                sx={{ borderRadius: '10px', mr: 2 }}
                              >
                                Settings
                              </Button>

                              <Button
                                variant="contained"
                                onClick={handleDuplicateCampaign}
                                sx={{ borderRadius: '10px', mr: 2 }}
                              >
                                Duplicate Campaign
                              </Button>

                              <Button
                                variant="contained"
                                onClick={handlePublish}
                                sx={{ borderRadius: '10px' }}
                                disabled={isPublishLoading}
                              >
                                {isPublishLoading
                                  ? 'Processing...'
                                  : campaignDetails.status === 'Live'
                                  ? 'Pause'
                                  : 'Publish'}
                              </Button>
                        

                </Box>
                {/* Campaign Name and Advertiser Name */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4 }}>
               {campaignDetails.jobType === 'Paid Influencers' ? (
               
               <PaidInfluencerCampaignDetail 
               campaignData={campaignDetails}
               placements={placementData}
               applications={applicationData}
               influencers={influencers} />
               
               ) : ( null )}
                 
                 {campaignDetails.jobType === 'Product Seeding' ? (
               
               <ProductSeedingCampaignDetail 
               campaignData={campaignDetails}
               applications={applicationData}
               influencers={influencers} />
               
               ) : ( null )}

            
                </Grid2>

                        
            </Container>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CampaignDetail;
