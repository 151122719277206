// CreatorApplicationsBulkTabs.jsx
import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Typography,
  Avatar,
  Button
} from '@mui/material';

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
}

const CreatorApplicationsBulkTabs = ({ influencers, applicationData }) => {
  // Dummy data: filter influencers by status for the initial state
  const initialApplications = applicationData.filter(application => application.status === 'Pending');
  const initialShortlist = []; // Initially empty

  const [currentTab, setCurrentTab] = useState(0);
  const [applications, setApplications] = useState(applicationData);
  const [shortlist, setShortlist] = useState(initialShortlist);
  const [selected, setSelected] = useState([]); // store selected influencer _id's

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setSelected([]); // reset selection when switching tabs
  };

  
  const StatusDot = ({ status }) => {
      // Determine dot color based on the status or another influencer property
      let color = 'grey';
      if (status === 'good') {
        color = 'green';
      } else if (status === 'warning') {
        color = 'orange'; // amber can be represented by orange
      } else if (status === 'bad') {
        color = 'red';
      }
      return (
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: color,
          }}
        />
      );
    };

  // Return the active list depending on the current tab:
  const activeList = currentTab === 0 ? applications : shortlist;

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(item => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  // Bulk action: for this example, if on Applications tab, move selected to Shortlist,
  // and if on Shortlist tab, move selected back to Applications.
  const handleBulkAction = () => {
    if (currentTab === 0) {
      // Move selected items from Applications to Shortlist
      const newApplications = applications.filter(item => !selected.includes(item._id));
      const movedItems = applications.filter(item => selected.includes(item._id));
      setApplications(newApplications);
      setShortlist([...shortlist, ...movedItems]);
    } else {
      // Move selected items from Shortlist back to Applications
      const newShortlist = shortlist.filter(item => !selected.includes(item._id));
      const movedItems = shortlist.filter(item => selected.includes(item._id));
      setShortlist(newShortlist);
      setApplications([...applications, ...movedItems]);
    }
    setSelected([]);
  };
  const handleShortlistSingle = (influencer) => {
    if (currentTab === 0) {
      // Move influencer from Applications to Shortlist
      setApplications(applications.filter(item => item._id !== influencer._id));
      setShortlist([...shortlist, influencer]);
    } else {
      // Move influencer from Shortlist back to Applications
      setShortlist(shortlist.filter(item => item._id !== influencer._id));
      setApplications([...applications, influencer]);
    }
    // Optionally remove the influencer's id from selected if it's there:
    setSelected(selected.filter(id => id !== influencer._id));
  };

  const getTotalProposedFee = (placements) => {
    return placements?.reduce((acc, p) => acc + (Number(p.proposedFee) || 0), 0) || 0;
  };


 
  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange} aria-label="Creator Applications Tabs">
        <Tab label="Applications" {...a11yProps(0)} />
        <Tab label="Shortlist" {...a11yProps(1)} />
      </Tabs>

      <Box sx={{ mt: 2 }}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.length === activeList.length && activeList.length > 0}
                    indeterminate={selected.length > 0 && selected.length < activeList.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelected(activeList.map(item => item._id));
                      } else {
                        setSelected([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell><strong></strong></TableCell>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Followers</strong></TableCell>
                <TableCell><strong>Forecast eCPV</strong></TableCell>
                <TableCell><strong>Forecast eCPE</strong></TableCell>
                <TableCell><strong>Proposed Fee</strong></TableCell>
                <TableCell><strong>Buying Indicator</strong></TableCell>
                <TableCell><strong>Actions</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeList.map((application) => (
                <TableRow key={application._id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.includes(application._id)}
                      onChange={() => handleSelect(application._id)}
                    />
                  </TableCell>
                  <TableCell><Avatar /></TableCell>
                  <TableCell>{application.creatorDetails[0].displayName}</TableCell>
                  <TableCell>{(Number(application.creatorDetails[0].followers)).toLocaleString()}</TableCell>
                  <TableCell>{`$${(getTotalProposedFee(application.placementsAppliedTo) / (Number(application.creatorDetails[0].followers)/10)).toFixed(2)}`}</TableCell>
                  <TableCell>{`$${(getTotalProposedFee(application.placementsAppliedTo) / (Number(application.creatorDetails[0].followers)/200)).toFixed(2)}`}</TableCell>
                  <TableCell>${getTotalProposedFee(application.placementsAppliedTo)}</TableCell>
                  <TableCell>
                    <StatusDot status="good" />
                  </TableCell>
                  {/* New Actions cell */}
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {handleShortlistSingle(application)
                          // Placeholder: shortlist action
                          console.log('Shortlist', application);
                        }}
                      >
                        {currentTab === 0 ? 'Shortlist' : 'Remove'}
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          // Placeholder: accept action
                          console.log('Accept', application);
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          // Placeholder: decline action
                          console.log('Decline', application);
                        }}
                      >
                        Decline
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          // Placeholder: view action
                          console.log('More', application);
                        }}
                      >
                        More
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell>{application.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>

      <Box sx={{ mt: 2, mb: 5 }}>
        <Button
          variant="contained"
          onClick={handleBulkAction}
          disabled={selected.length === 0}
        >
          {currentTab === 0 ? "Send To Shortlist" : "Remove From Shortlist"}
        </Button>
      </Box>
    </Box>
  );
};

export default CreatorApplicationsBulkTabs;
